import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import toastBuilder from "src/utils/toastBuilder";
import { ValidationCompany } from "src/validation/profile";
import { selectUser } from "src/container/auth/auth.selector";

const CompanyTab: React.FC<{}> = (props) => {
  const currentUser = useAppSelector(selectUser);
  const { getProfileData, AddrecuriterCompanyInfo } = useActions();

  useEffect(() => {
    getProfileData();
  }, []);

  const handleSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any
  ) => {
    var bodyFormData = new FormData();
    bodyFormData.append("fileToBeUploaded", formValues.logo);
    bodyFormData.append("company_name", formValues.company_name);
    bodyFormData.append("company_logo_url", currentUser?.company_logo);

    const results: any = await AddrecuriterCompanyInfo(bodyFormData);
    if (results?.payload?.data) {
      setSubmitting(false);
      await getProfileData();
      toastBuilder("success", "Updated Successfully");
    }
  };

  const [initaialvalues, setinitalvalues] = useState({
    logo: "",
    company_name: "",
  });

  const [logoImage, setLogoImage] = useState(currentUser?.company_logo);

  useEffect(() => {
    setinitalvalues({
      logo: currentUser?.company_logo,
      company_name: currentUser?.company_name,
    });
  }, [currentUser]);

  return (
    <>
      <div className="right-tabs company-tab">
        <div className="input-fields-area">
          <Formik
            validationSchema={ValidationCompany}
            onSubmit={handleSubmit}
            initialValues={initaialvalues}
            enableReinitialize={true}
          >
            {({ handleSubmit, setFieldValue, touched, errors }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="profilepic mb-md-3">
                    <img
                      className="avatar rounded-circle"
                      src={logoImage}
                      alt=""
                    />
                    <label htmlFor="file-upload" className="fileuplaod">
                      <i className="fas fa-camera camera pointer"></i>
                      <input
                        id="file-upload"
                        type="file"
                        accept="image/png, image/jpeg, image/gif, image/heic, .heic"
                        onChange={(event: any) => {
                          const previewImg = URL.createObjectURL(
                            event.target.files[0]
                          );
                          setLogoImage(previewImg);
                          setFieldValue("logo", event.target.files[0]);
                        }}
                        name="logo"
                        style={{ opacity: 0 }}
                      />
                    </label>
                  </div>

                  <div className="fields-wrapper">
                    {/* Company Name Field */}
                    <div className="form-group">
                      <div className="form-field">
                        <Field
                          type="text"
                          name="company_name"
                          placeholder="Company Name"
                        />
                      </div>
                      <span className="errorMsg">
                        {touched.company_name && errors.company_name}
                      </span>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-medium btn-purple mt-4"
                  >
                    Update
                  </button>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CompanyTab;
