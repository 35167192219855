import toastBuilder from "./toastBuilder";

export class ErrorHanlder extends Error {
  constructor(error: any) {
    super(error);

    console.log("error?.response?.data ", error?.response?.data);

    toastBuilder(
      "error",
      error?.response?.data?.msg ||
        error?.response?.data?.message ||
        error.message ||
        "error shap changes form server"
    );
  }
}
