import { Formik, Field, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useActions } from "src/actions";
import logo from "src/assets/images/logo.png";
import { forgetDataTypes } from "src/types/auth";
import toastBuilder from "src/utils/toastBuilder";
import { forgetschema } from "src/validation/singnup";
import emailIcon from "src/assets/images/icons/message-icon.svg";

const ForgotPassword: React.FC<{}> = () => {
  const { forgetuserpassword } = useActions();
  const navigation = useNavigate();
  const handleSubmit = async (
    formValues: forgetDataTypes,
    { resetForm }: any
  ) => {
    const action: any = await forgetuserpassword(formValues);
    if (action?.payload?.status) {
      navigation({ pathname: "/login" });
      toastBuilder(
        "success",
        "Reset password link sent successfully. Please check spam folder also"
      );
      resetForm();
    }
  };

  return (
    <section className="signIn-section forgot-password">
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <div className="shadow-box">
        <h2>Forgot Password ?</h2>
        <Formik
          validationSchema={forgetschema}
          onSubmit={handleSubmit}
          initialValues={{ email: "" }}
          enableReinitialize={true}
        >
          {({ touched, errors }) => {
            return (
              <Form>
                <div className="form-group">
                  <label>Email</label>
                  <div className="form-field">
                    <img src={emailIcon} alt="" />
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email Address"
                    />
                  </div>
                  <span className="errorMsg">
                    {touched.email && errors.email}
                  </span>
                </div>

                <button
                  type="submit"
                  className="btn w-100 btn-medium btn-purple"
                >
                  Send Reset Link
                </button>
              </Form>
            );
          }}
        </Formik>

        <div className="account-signUp">
          <p>Remember Password?</p>
          <Link to="/login" className="signUp-link">
            LogIn
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
