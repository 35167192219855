import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiClient, socketClient } from "src/index";
import { team_lead_add_callback, notify_count_callback, get_messages_callback } from "src/socket/callbackFunctions";


export const getChatHeader = createAsyncThunk("/message/getChatBoxes", (payload, thunkApi) => {
  const result = ApiClient.get(`/message/getChatBoxes`);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const getAllMessage = createAsyncThunk(
  "/message/getAllMessage",
  (payload: any) => ApiClient.post("/message/getAllMessage", payload)
);

export const readAllMessages = createAsyncThunk(
  "/message/readAllMessages",
  (payload: any) => ApiClient.post("/message/readAllMessages", payload)
);

export const sendMessage = createAsyncThunk(
  "/message/sendMessage",
  (payload: any) => ApiClient.post("/message/sendMessage", payload)
);

//here is all actions realted to socket
enum SocketActionTypes {
    ADD_USER = "addUser action type",
    REMOVE_USER = "removeUser action type",
    MESSAGE_COUNT = "listenMessageCount action type",
    NOTIFY_COUNT = "listenNotificationCount action type",
    JOIN_CHAT = "joinChatScreen action type",
    LEAVE_CHAT = "leaveChatScreen action type",
    GET_MESSAGES = "message action type",
}

enum EmitActions {
    ADD_USER = "addUser",
    REMOVE_USER = "removeUser",
    MESSAGE_COUNT = "listenMessageCount",
    NOTIFY_COUNT = "listenNotificationCount",
    JOIN_CHAT = "joinChatScreen",
    LEAVE_CHAT = "leaveChatScreen",
    GET_MESSAGES = "message",
}

export const registercurrentuserwithsocket = createAsyncThunk(
  SocketActionTypes.ADD_USER,
  async (id: { userId: string }, store) => {
    return socketClient.emit(EmitActions.ADD_USER, id);
  }
);

export const removeUserFromSocket = createAsyncThunk(
  SocketActionTypes.REMOVE_USER,
  async (id: { userId: string }, store) => {
    return socketClient.emit(EmitActions.REMOVE_USER, id);
  }
);

export const messages_count = createAsyncThunk(
  SocketActionTypes.MESSAGE_COUNT,
  async (payload, store) => {
    return socketClient.on(EmitActions.MESSAGE_COUNT, team_lead_add_callback);
  }
);

export const notify_count = createAsyncThunk(
  SocketActionTypes.NOTIFY_COUNT,
  async (payload, store) => {
    return socketClient.on(EmitActions.NOTIFY_COUNT, notify_count_callback);
  }
);

export const join_chat_screen = createAsyncThunk(
  SocketActionTypes.JOIN_CHAT,
  async (payload: { firstUser: string, secondUser: string }, store) => {
    return socketClient.emit(EmitActions.JOIN_CHAT, payload);
  }
);

export const leave_chat_screen = createAsyncThunk(
  SocketActionTypes.LEAVE_CHAT,
  async (id: { userId: string }, store) => {
    return socketClient.emit(EmitActions.LEAVE_CHAT, id);
  }
);

export const get_messages = createAsyncThunk(
  SocketActionTypes.GET_MESSAGES,
  async (payload, store) => {
    return socketClient.on(EmitActions.GET_MESSAGES, get_messages_callback);
  }
);