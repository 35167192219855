import React, { useState } from "react";
import { models, Roles } from "src/enums";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import ChatPopUp from "./ChatPopup";
import FilterForm from "./FilterForm";
import HeaderBadge from "./HeaderBadge";
import MessagePopup from "./MessagePopup";
import JObSearchForm from "./JobSearchForm";
import NotifiPopUp from "./NotificationPopup";
import BankInfoPopup from "./AddBankInfoPopup";
import UserProfile from "../Shared/UserProfile";
import logo from "../../assets/images/logo.png";
import DeleteTribe from "./TribePopups/DeleteTribe";
import EditBroadCastModel from "./EditBroadCastModel";
import TribeEditMsg from "./TribePopups/TribeEditMsg";
import TribeCreateMsg from "./TribePopups/TribeCreateMsg";
import CreateBroadCastModel from "./CreateBroadCastModel";
import JoinTribePopup from "./TribePopups/JoinTribePopup";
import EditTribePopup from "./TribePopups/EditTribePopup";
import GetTribeList from "./TribePopups/getTribeListPopup";
import GroupInfoPopUp from "./TribePopups/TribeInfoSidebar";
import CreateTribePopup from "./TribePopups/CreateTribePopup";
import Descriptionpopup from "./TribePopups/DescriptionPopup";
import { selectUser } from "src/container/auth/auth.selector";
import searchIcon from "src/assets/images/icons/search-icon.svg";
import BroadCastMessageModel from "../UserPost/BroadCastMessageModel";
import { use_active_model } from "src/container/model/model.selector";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { allHiredDrivers } from "src/container/recruiter/recruiter.selector";
import { getFilterJobs } from "../../container/PostJob/PostJob.selector";

const Header: React.FC<{}> = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const { set_active_model, getDriverDetails } = useActions();
  const selectCurrentuser = useAppSelector(selectUser);
  const active_model = useAppSelector(use_active_model);
  const hiredDriversDetail = useAppSelector(allHiredDrivers);
  const filterJobs = useAppSelector(getFilterJobs);
  const [suggestions, setsuggestions] = useState<string[]>([]);
  const [search, setsearch] = useState<string>("");

  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setsearch(event.target.value);
    const regex = new RegExp(`${event.target.value}`, "gi");
    const suggestion: Array<string> = hiredDriversDetail.filter(
      (customer: any) => regex.test(customer?.users_details_info?.first_name)
    );
    setsuggestions(suggestion);
  };

  const viewDriverDetails = async (driverId: number) => {
    const results: any = await getDriverDetails({
      user_id: driverId,
    });
    if (results?.payload?.status) {
      navigation({
        pathname: `/viewUserDetails/${driverId}`,
        search: `?hired=${true}`,
      });
    }
  };

  return (
    <header>
      <div className="navbar">
         <div className="logo-search-area">
          <div className="logo">
            <img src={logo} alt="" />
          </div>

          {location.pathname === "/engaged-applicants" && (
            <div className="searchBar">
              <img className="searchIcon" src={searchIcon} alt="" />
              <input
                type="text"
                placeholder="Search"
                value={search}
                onChange={searchHandler}
              />

              <ul className="fiilterDropdown">
                {suggestions.map((curElem: any, index: number) => {
                  return (
                    <li
                      key={index}
                      onClick={() => viewDriverDetails(curElem.driver_id)}
                    >
                      {curElem?.users_details_info?.first_name}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

{/* Search Icon */}
          {(location.pathname === "/talent" ||
            location.pathname === "/home") && (
            <div className="filter-btn">
              <ul className="list-unstyled mb-0">
                {selectCurrentuser.role !== Roles.recruiter &&
                filterJobs &&
                location.pathname === "/home" ? (
                  <li className="dropdown active">
                    {/* <Link
                      to=""
                      className="filter-hamburger"
                      onClick={() => set_active_model(models.JobSearch_MODEL)}
                    >
                      <img src={filterIcon} alt="" />
                      <span>Search Jobs</span>
                    </Link> */}
                    {active_model === models.JobSearch_MODEL ? (
                      <JObSearchForm />
                    ) : null}
                  </li>
                ) : location.pathname === "/talent" ? (
                  <li className="dropdown active">
                    {/* <Link
                      to=""
                      className="filter-hamburger"
                      onClick={() => set_active_model(models.FILTER_MODEL)}
                    >
                      <img src={filterIcon} alt="" />
                      <span>Search Talent</span>
                    </Link> */}
                    {active_model === models.FILTER_MODEL ? (
                      <FilterForm />
                    ) : null}
                  </li>
                ) : null}
              </ul>
            </div>
          )}
        </div>

{/* Navbar Tabs */}
        <div>
          <ul className="list-unstyled nav-item">
            <NavLink to="/profile" className="nav-link">
              Profile
            </NavLink>

            <NavLink to="/home" className="nav-link">
              Home
            </NavLink>

            {selectCurrentuser.role === Roles.recruiter && (
              <NavLink to="/talent" className="nav-link">
                Talent
              </NavLink>
            )}

            {selectCurrentuser.role !== Roles.recruiter && (
              <NavLink to="/jobs" className="nav-link">
                Jobs
              </NavLink>
            )}

            <NavLink to="/feed" className="nav-link">
              Feed
            </NavLink>
          </ul>
        </div>

        <div className="badge-profile-side">
          <HeaderBadge
            on_notification_icon_click={() =>
              set_active_model(models.NOTIFICATION_MODEL)
            }
            on_message_icon_click={() => set_active_model(models.MESSAGE_MODEL)}
          />
          <UserProfile />
        </div>
      </div>
      {active_model === models.CHAT_MODEL && <ChatPopUp />}
      {active_model === models.DELETE_TRIBE && <DeleteTribe />}
      {active_model === models.EDIT_MESSAGE && <TribeEditMsg />}
      {active_model === models.GROUP_INFO && <GroupInfoPopUp />}
      {active_model === models.MESSAGE_MODEL && <MessagePopup />}
      {active_model === models.TRIBE_MESSAGE && <TribeCreateMsg />}
      {active_model === models.BANK_INFO_MODEL && <BankInfoPopup />}
      {active_model === models.BROADCAST && <CreateBroadCastModel />}
      {active_model === models.NOTIFICATION_MODEL && <NotifiPopUp />}
      {active_model === models.JOIN_TRIBE_POPUP && <JoinTribePopup />}
      {active_model === models.EDIT_TRIBE_POPUP && <EditTribePopup />}
      {active_model === models.EDIT_BROADCAST && <EditBroadCastModel />}
      {active_model === models.GET_TRIBE_LIST_POPUP && <GetTribeList />}
      {active_model === models.CREATE_TRIBE_POPUP && <CreateTribePopup />}
      {active_model === models.BROADCAST_MESSAGE && <BroadCastMessageModel />}
      {active_model === models.EDIT_DESCRIPTION_POPUP && <Descriptionpopup />}
      {active_model === models.JobSearch_MODEL && <JObSearchForm />}
    </header>
  );
};

export default Header;
