import React, { useEffect } from "react";
import { DateTime } from "luxon";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import Header from "src/components/Header";
import { allDriverEarnings, totalEarnings } from "../driver/driver.selector";

const EarningsPage: React.FC<{}> = (props) => {
  const { getDriverEarnings } = useActions();
  const getTotalEarnings = useAppSelector(totalEarnings);
  const driverEarnings = useAppSelector(allDriverEarnings);
  const convertDate = DateTime.local(driverEarnings?.assigned_date);
  const engageDate = convertDate.toFormat("dd-MM-yy");

  useEffect(() => {
    getDriverEarnings("all");
  }, []);

  const handleChange = (e: any) => {
    getDriverEarnings(e.target.value);
  };

  return (
    <>
      <Header />
      <section className="earnings-page">
        <div className="earn-container">
          <div className="top-header">
            <h4>History</h4>
            <select className="exp-dropdown" onChange={(e) => handleChange(e)}>
              <option value="all">All</option>
              <option value="1">One Year</option>
              <option value="2">Two Year</option>
            </select>
          </div>

          <table className="table earn-table">
            <thead>
              <tr>
                <th scope="col">Date</th>
                {/* <th scope="col">Purpose</th> */}
                <th scope="col">Amount</th>
              </tr>
            </thead>

            {driverEarnings?.map((curElem: any) => {
              return (
                <tbody key={curElem?._id}>
                  <tr>
                    <td>{engageDate}</td>
                    {/* <td>Driving</td> */}
                    <td>{`$${curElem?.amount_transfer}`}</td>
                  </tr>
                </tbody>
              );
            })}
          </table>

          <div className="top-header total-pricing">
            <h4>Total Earnings</h4>
            <h4>{`$${getTotalEarnings || "0"}`}</h4>
          </div>
        </div>
      </section>
    </>
  );
};

export default EarningsPage;
