import Header from "src/components/Header";
import ViewRequest from "src/components/UserPost/ViewRequest";

const ViewRequestPage: React.FC<{}> = (props) => (
  <>
    <Header />
    <section className="home-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-10 col-12">
            <ViewRequest />
          </div>
        </div>
      </div>
    </section>
  </>
);

export default ViewRequestPage;
