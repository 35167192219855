import React, { useEffect, useRef, useState } from "react";
import { models } from "src/enums";
import { useActions } from "src/actions";
import toastBuilder from "src/utils/toastBuilder";
import { IoCloseCircleOutline } from "react-icons/io5";

const JoinTribePopup: React.FC<{}> = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [joinTribeInput, setJoinTribeInput] = useState("");
  const { set_active_model, joinTribe, getJoinedTribes } = useActions();

  useEffect(() => {
    const div = ref.current; // corresponding DOM node
    div?.classList.add("active");
    document.body.style.overflow = "hidden";
    return () => {
      div?.classList.remove("active");
      document.body.style.overflow = "scroll";
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const results: any = await joinTribe({ tribe_url: joinTribeInput });
    if (results.payload.status) {
      toastBuilder("success", "Tribe joined successfully");
      set_active_model(models.CLEAR);
      getJoinedTribes();
    }
  };

  return (
    <>
      <div ref={ref} className="sidebar center-popup tribe-popup">
        <div className="model-info justify-content-center">
          <div
            className="close-icon"
            onClick={() => set_active_model(models.CLEAR)}
          >
            <IoCloseCircleOutline />
          </div>
          <h5 className="mb-0">Enter Link to Join a Tribe</h5>
          <form onSubmit={handleSubmit}>
            <div className="custom-input">
              <input
                type="text"
                id="tribeUrl"
                autoComplete="off"
                onChange={(e) => setJoinTribeInput(e.target.value)}
                required
              />
              <label htmlFor="tribeUrl">Enter Link</label>
            </div>

            <button type="submit" className="btn btn-medium btn-purple">
              Join Tribe
            </button>
          </form>
        </div>
      </div>
      <div className="bg-overlay bg-fixed"></div>
    </>
  );
};

export default JoinTribePopup;
