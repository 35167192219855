import { createSlice } from "@reduxjs/toolkit";
import { createReducerBuilder } from "src/utils/actions";
import { getAllPaymentCards, getNumberOfEngagedUserCount, getEngagedUserDetails, getAllApplicantsList, getHiredApplicantsList, getAllApplicants} from "./recruiter.actions";

const initialState: any = {
  paymentCads: [],
  defaultCard: "",
  hiredDriversCount: "",
  allHiredDriverDetails: [],
  alldriversList: [],
  allHiredApplicantsDetail: [],
  allApplicantsDetail: [],
  filtersData: { filters: null},
};

const reducerBuilder = createReducerBuilder<any>();

/* @ts-ignore */
const AllPaymentCards = reducerBuilder(getAllPaymentCards, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.data) {
      state.paymentCads = payload.data.cards;
      state.defaultCard = payload.data.defaultCard;
    }
  },
});

/* @ts-ignore */
const numberOfHiredDrivers = reducerBuilder(getNumberOfEngagedUserCount, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.data) {
      state.hiredDriversCount = payload.data.user_count;
    }
  },
});

/* @ts-ignore */
const hiredDriverDetails = reducerBuilder(getEngagedUserDetails, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.data) {
      state.allHiredDriverDetails = payload.data.details;
    }
  },
});

/* @ts-ignore */
const driversList = reducerBuilder(getAllApplicantsList, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.data) {
      state.alldriversList = payload.data.applicants;
    }
  },
});

/* @ts-ignore */
const applicantsList = reducerBuilder(getHiredApplicantsList, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.data) {
      state.allHiredApplicantsDetail = payload.data.applicants;
    }
  },
});

/* @ts-ignore */
const allapplicantsList = reducerBuilder(getAllApplicants, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.data) {
      state.allApplicantsDetail = payload.data.applicants;
    }
  },
});

const recruiterSlice = createSlice({
  name: "authslice",
  initialState,
  reducers: {
    getFiltersData: (state, action) => {
      state.filtersData = action.payload;
    },
  },

  extraReducers: (builder) => {
    AllPaymentCards(builder);
    numberOfHiredDrivers(builder);
    hiredDriverDetails(builder);
    driversList(builder);
    applicantsList(builder);
    allapplicantsList(builder);
  },
});

export const { getFiltersData } = recruiterSlice.actions;

export default recruiterSlice.reducer;
