import React from "react";
import Select from "react-select";
import { models } from "src/enums";
import { store } from "../../store";
import { useActions } from "src/actions";
import { State } from "country-state-city";
import { Formik, Field, Form } from "formik";
import toastBuilder from "src/utils/toastBuilder";
import { getFiltersData } from "src/container/recruiter/recruiter.slice";
import SharedData from "../SharedData/SharedApi";

const DriverSearchFiter: React.FC<{}> = (props) => {
  const { set_active_model, getAllDriverJobs } = useActions();
  const States = State.getStatesOfCountry("US").map((curElem) => {
    return curElem.name;
  });

  const initialValues = {
    years_experience: "",
    endorsements: [],
    haul_type: "",
    state: [],
  };

  const handleSubmit = async (formValues: any, { setSubmitting }: any) => {
    formValues.endorsements = formValues.endorsements.flat();

    const objToReturn: any = {};
    for (const prop in formValues) {
      if (
        formValues[prop] !== undefined &&
        formValues[prop] !== null &&
        formValues[prop] !== ""
      ) {
        const isAnArray = Array.isArray(formValues[prop]);
        if (isAnArray) {
          const newArray = formValues[prop].filter((passedElement: unknown) => {
            return passedElement !== null && passedElement !== undefined;
          });
          formValues[prop] = newArray;
        }
        objToReturn[prop] = formValues[prop];
        if (typeof (formValues[prop] === "object")) {
          if (Object.keys(formValues[prop]).length === 0) {
            delete objToReturn[prop];
          }
        }
      }
    }
    store.dispatch(getFiltersData({ filters: objToReturn }));
    //@ts-ignore
    const results: any = await getAllDriverJobs({ filters: objToReturn });
    if (results.payload.status) {
      toastBuilder("success", "Filters Applied");
      setSubmitting(false);
      set_active_model(models.CLEAR);
    }
  };

  return (
    <>
      <div className="mega-menu active">
        <div className="input-fields-area driver-detail">
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
          >
            {({ handleSubmit, handleChange, setFieldValue, values }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="detail-field-row">
                    <h4>Years Experience: </h4>
                    <select
                      name="years_experience"
                      onChange={handleChange}
                      value={values.years_experience}
                      className="exp-dropdown ml-0 sales-selection"
                    >
                      <option value="">Select</option>
                      <option value="1-3 Year">1-3 years</option>
                      <option value="3-5 Year">3-5 years</option>
                      <option value="5-10 Year">5-10 years</option>
                      <option value="10+ Years">10+ years</option>
                    </select>
                  </div>

                  <div className="detail-field-row">
                    <h4>Endorsements: </h4>
                    <div className="detail-info four-col-grid">
                      {SharedData.Endorsement.map((endorsement: any, index) => {
                        return (
                          <span className="p-0 mr-3" key={endorsement.value}>
                            <div>
                              <Field
                                id={`endorsements[${index}]`}
                                name={`endorsements[${index}]`}
                                value={endorsement.value}
                                type="checkbox"
                                className="checkbox-custom"
                              />
                              <label
                                htmlFor={`endorsements[${index}]`}
                                className="checkbox-custom-label"
                              >
                                {endorsement.label}
                              </label>
                            </div>
                          </span>
                        );
                      })}
                    </div>
                  </div>

                  <div className="detail-field-row">
                    <h4>Haul Type: </h4>
                    <div className="detail-info">
                      {SharedData.Haul_Type.map((hualType) => {
                        return (
                          <span className="p-0 mr-3" key={hualType.value}>
                            <label>
                              <Field
                                type="radio"
                                value={hualType.value}
                                name="haul_type"
                                id={hualType.value}
                              />
                              <label htmlFor={hualType.value}>
                                {hualType.label}
                              </label>
                            </label>
                          </span>
                        );
                      })}
                    </div>
                  </div>

                  <div className="detail-field-row">
                    <h4>States: </h4>
                    <div className="detail-info states">
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(value) =>
                          setFieldValue(
                            "state",
                            value.map((curElem) => {
                              return curElem.value;
                            })
                          )
                        }
                        className="multiSelect-dropdown"
                        options={States.map((state: any) => ({
                          value: state,
                          label: state,
                        }))}
                      />
                    </div>
                  </div>

                  <div className="text-center mt-md-4">
                    <button type="submit" className="btn btn-medium btn-purple">
                      Search Jobs
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>

        <div className="close-menu-btn">
          <i
            className="fa-solid fa-x"
            onClick={() => set_active_model(false)}
          ></i>
        </div>
      </div>
      <div className="bg-overlay-fixed"></div>
    </>
  );
};

export default DriverSearchFiter;
