import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "src/store";

const selectedreducer = (state: RootState) => state.tribeSlice;

export const tribeDriversSelector = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.getDrivers
);

export const joinedTribesSelector = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.joinedTribes
);

export const getAllDiscussionSelector = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.getDiscussion
);

export const getTribeDataSelector = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.getTribeData
);

export const getTribeDetailSelector = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.tribesInfo
);

export const getTribeDetailMemberSelector = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.members
);

export const getDiscussionId = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.discussion_id
);

export const getTribeId = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.tribe_id
);

// export const getTextSelector = createDraftSafeSelector(
//   selectedreducer,
//   (userState) => userState.textStore
// );