import React, { useEffect } from "react";
import { useActions } from "src/actions";
import { store } from "../../store";
import { useAppSelector } from "src/store";
import { models } from "src/enums";
import { allBroadCasts } from "src/container/PostJob/PostJob.selector";
import { GrDocumentDownload } from "react-icons/gr";
import { getMessageData } from "src/container/message/message.slice";
/* @ts-ignore */
import Linkify from "react-linkify";

const JobBroadCast: React.FC<{}> = (props) => {
  const { set_active_model, getAllBroadCast } = useActions();
  const broadCastsData = useAppSelector(allBroadCasts);

  useEffect(() => {
    getAllBroadCast();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const viewApplicantDetails = async (driverId: any, allData: any) => {
    store.dispatch(
      getMessageData({
        msgData: {
          id: driverId,
          name: `${allData?.first_name} ${allData?.last_name}`,
          avatar: allData?.avatar,
        },
      })
    );
    set_active_model(models.CHAT_MODEL);
  };

  return (
    <>
      {broadCastsData.length === 0 ? (
        <div className="no-story-text">
          <h2>No BroadCast found...</h2>
        </div>
      ) : (
        broadCastsData?.map((broadcast: any) => {
          return (
            <div className="news-item">
              {/* <div className="news-text-box p-0"> */}
              <div className="user-jobs-box mb-0 border-0 d-block">
                <div className="d-flex">
                  <img
                    src={broadcast?.company_info?.company_logo?.file_url}
                    alt=""
                  />
                  <div className="user_name ">
                    <div>
                      <h5>{broadcast?.company_info?.company_name}</h5>
                      <p>{broadcast?.job_title}</p>
                    </div>
                  </div>
                </div>

                <div className="w-100">
                  <div className="broadcast-preview">
                    <div className=" mt-3 d-flex justify-content-between">
                      <h5 className="user--name">
                        {`${broadcast.first_name} ${broadcast.last_name}`}
                      </h5>
                      <p>{broadcast.state}</p>
                    </div>
                    <p className="user--country mb-3">
                      <Linkify
                        componentDecorator={(
                          decoratedHref: any,
                          decoratedText: any,
                          key: any
                        ) => (
                          <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                          </a>
                        )}
                      >
                        {broadcast.broadCasts?.message}
                      </Linkify>
                    </p>
                    {broadcast.broadCasts?.link_data ? (
                      <>
                        {broadcast?.broadCasts?.link_data.map((elem: any) => {
                          return (
                            <div>
                              <a
                                href={elem?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="preview-image"
                                  alt=""
                                  src={elem?.image}
                                />
                              </a>
                              <span>{elem?.title}</span>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )}

                    {broadcast.broadCasts?.images?.length > 0 && (
                      <div className="message-images mt-3">
                        {broadcast?.broadCasts?.images.map((elem: any) => {
                          return (
                            <a href={elem} target="_blank" rel="noreferrer">
                              <img src={elem} alt="" />
                            </a>
                          );
                        })}
                      </div>
                    )}

                    {broadcast?.broadCasts?.documents.length > 0 && (
                      <div className="documents-wrapper">
                        {broadcast?.broadCasts?.documents.map((elem: any) => {
                          return (
                            <a href={elem?.file_url} download>
                              <div>
                                <i>
                                  <GrDocumentDownload />
                                </i>
                                <label>{elem?.file_name}</label>
                              </div>
                            </a>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="text-right mt-4">
                  <button
                    className="btn btn-medium btn-purple "
                    onClick={() =>
                      viewApplicantDetails(broadcast.user_data, broadcast)
                    }
                  >
                    Message
                  </button>
                </div>
              </div>
              {/* </div> */}
            </div>
          );
        })
      )}
    </>
  );
};

export default JobBroadCast;
