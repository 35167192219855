import React, { useState } from "react";
import { models } from "src/enums";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { selectUser } from "src/container/auth/auth.selector";
import userAvatar from "../../../assets/images/user-avatar.png";
import { getTribeId } from "src/container/Tribe/Tribe.selector";
import RichTextEditor from "src/General/TextEditor/RichTextEditor";

const TribeCreateMsg: React.FC<{}> = (props) => {
  const tribe_id = useAppSelector(getTribeId);
  const selectCurrentuser = useAppSelector(selectUser);
  const [editorValue, setEditorValue] = useState("");
  const [editorfiles, setEditorFiles] = useState([]);
  const { set_active_model, addDiscussion, getAllDiscussion } = useActions();

  const createDiscussion = async () => {
    const res: any = await addDiscussion({
      tribe_id: tribe_id,
      discussion: editorValue || editorfiles,
    });
    if (res.payload.status) {
      set_active_model(models.CLEAR);
      getAllDiscussion(tribe_id);
    }
  };

  return (
    <>
      <div className="create-broadcast tribe-editor">
        <div className="broadcast-header">
          <h5>Publish a Message</h5>
          <i
            onClick={() => set_active_model(models.CLEAR)}
            className="fa-solid fa-x"
          ></i>
        </div>

        <div className="broadcast-body">
          <div className="cast-user">
            <img
              src={selectCurrentuser?.avatar || userAvatar}
              alt="Not_Found"
            />
            <h4>{`${selectCurrentuser?.first_name} ${selectCurrentuser?.last_name}`}</h4>
          </div>

          <div className="rich-text-editor">
            <RichTextEditor
              setEditorValue={setEditorValue}
              setEditorFiles={setEditorFiles}
            />
          </div>

          <div className="broadcast-footer">
            <button
              className="btn btn-medium btn-purple"
              onClick={() => createDiscussion()}
            >
              Publish Message
            </button>
          </div>
        </div>
      </div>
      <div className="bg-overlay-fixed" />
    </>
  );
};

export default TribeCreateMsg;
