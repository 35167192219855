import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { models } from "src/enums";
import { Formik, Field } from "formik";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { IoCloseCircleOutline } from "react-icons/io5";
import userAvatar from "../../../assets/images/user-avatar.png";
import { tribeDriversSelector } from "src/container/Tribe/Tribe.selector";
import { joinedTribesSelector } from "src/container/Tribe/Tribe.selector";

const EditTribePopup: React.FC<{}> = () => {
  const [logoImage, setLogoImage] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const getTribeDrivers = useAppSelector(tribeDriversSelector);
  const getJoinedTribess = useAppSelector(joinedTribesSelector);
  const { set_active_model, getAllDrivers } = useActions();

  useEffect(() => {
    getAllDrivers();

    const div = ref.current; // corresponding DOM node
    div?.classList.add("active");
    document.body.style.overflow = "hidden";
    return () => {
      div?.classList.remove("active");
      document.body.style.overflow = "scroll";
    };
  }, []);

  const initialValues = {
    name: "",
    description: "",
    isPrivate: "",
    members: [],
    image: "",
  };

  const [state, setState] = useState(initialValues);

  const handleSubmit = async (formValues: any) => {
    var formData = new FormData();
    formData.append("image", formValues.image);
    formData.append("name", formValues.name);
    formData.append("description", formValues.description);
    formData.append("isPrivate", formValues.isPrivate);
    for (let elem of formValues.members) {
      formData.append("members[]", elem);
    }
    // const results: any = await editTribe(formData);
    // if (results.payload.status) {
    //   toastBuilder("success", "Tribe created successfully");
    //   getJoinedTribes();
    //   setSubmitting(false);
    //   resetForm();
    //   set_active_model(models.CLEAR);
    // }
  };

  useEffect(() => {
    setState(getJoinedTribess);
  });

  return (
    <>
      <div
        ref={ref}
        style={{ height: "530px" }}
        className="sidebar center-popup tribe-popup"
      >
        <div className="model-info">
          <div
            className="close-icon"
            onClick={() => set_active_model(models.CLEAR)}
          >
            <IoCloseCircleOutline />
          </div>
          <h5 className="mb-0">Create a New Tribe</h5>

          <Formik
            onSubmit={handleSubmit}
            initialValues={state}
            enableReinitialize={true}
          >
            {({ handleSubmit, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="custom-input">
                    <Field name="name" id="tribeName" type="text" required />
                    <label htmlFor="tribeName">Enter Name</label>
                  </div>

                  <div className="custom-input">
                    <Field
                      name="description"
                      id="tribeDesc"
                      type="text"
                      required
                    />
                    <label htmlFor="tribeDesc">Enter Description</label>
                  </div>

                  <div className="detail-field-row">
                    <div className="detail-info">
                      <Select
                        isMulti
                        placeholder="Select Members..."
                        closeMenuOnSelect={true}
                        onChange={(value) =>
                          setFieldValue(
                            "members",
                            value.map((curElem: any) => {
                              return curElem.value;
                            })
                          )
                        }
                        className="multiSelect-dropdown"
                        options={getTribeDrivers.map((member: any) => ({
                          value: member._id,
                          label: `${member.first_name} ${member.last_name}`,
                        }))}
                      />
                    </div>
                  </div>

                  <div className="detail-field-row">
                    <h4>Private: </h4>
                    <div className="detail-info">
                      {[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ].map((data) => {
                        return (
                          <span key={data.label}>
                            <label>
                              <Field
                                type="radio"
                                value={data.value}
                                id={data.label}
                                onChange={(event: any) => {
                                  const value = event.target.value;
                                  if (value === "true")
                                    setFieldValue("isPrivate", true);
                                  if (value === "false")
                                    setFieldValue("isPrivate", false);
                                }}
                                name="isPrivate"
                              />
                              <label htmlFor={data.label}>{data.label}</label>
                            </label>
                          </span>
                        );
                      })}
                    </div>
                  </div>

                  <div className="upload-logo">
                    <div className="logo-box">
                      <div className="upload-wrapper">
                        <img
                          className="avatar rounded-circle"
                          src={logoImage || userAvatar}
                          alt="Tribe Logo"
                        />
                        <input
                          type="file"
                          accept="image/png, image/jpeg, image/gif, image/heic, .heic"
                          onChange={(event: any) => {
                            const previewImg = URL.createObjectURL(
                              event.target.files[0]
                            );
                            setLogoImage(previewImg);
                            setFieldValue("image", event.target.files[0]);
                          }}
                          name="image"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <button type="submit" className="btn btn-medium btn-purple">
                    Create Tribe
                  </button>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className="bg-overlay bg-fixed"></div>
    </>
  );
};

export default EditTribePopup;
