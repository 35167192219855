import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "src/store";

const selectedreducer = (state: RootState) => state.AddpostJob;

export const postJobDesc = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.description
);

export const postJobImg = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.jobImage
);

export const allRecruiterJobs = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.allJobs
);

export const allBroadCasts = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.allBroadCast
);

export const allAvailDriverJobs = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.allDriverJobs
);

export const allBidsOnJob = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.allJobBidsDetails
);

export const allJobDetails = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.jobDetails
);

export const getAllOfJobsSelector = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.allOfJobs
);

export const getJobId = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.jobId
);
export const getFilterJobs = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.jobsShow
);

export const getJobBroadcasts = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.jobBroadcasts
);