import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiClient } from "src/index";

export const addSkills = createAsyncThunk(
  "/skill/addSkills",
  (payload: any) => ApiClient.post("/skill/addSkills", payload)
);

export const addExperience = createAsyncThunk(
  "/experience/addExperienceDetails",
  (payload: any) => ApiClient.post("/experience/addExperienceDetails", payload)
);