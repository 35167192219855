import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import toastBuilder from "src/utils/toastBuilder";
import { selectUser } from "src/container/auth/auth.selector";
import SharedData from "../SharedData/SharedApi";

const DrivingDetailTab: React.FC<{}> = (props) => {
  const currentUser = useAppSelector(selectUser);
  const { getProfileData, update_user_driver_details } = useActions();

  const handleSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any
  ) => {
    formValues.endorsements = formValues.endorsements.flat();

    const objToReturn: any = {};
    for (const prop in formValues) {
      if (
        formValues[prop] !== undefined &&
        formValues[prop] !== null &&
        formValues[prop] !== ""
      ) {
        const isAnArray = Array.isArray(formValues[prop]);
        if (isAnArray) {
          const newArray = formValues[prop].filter((passedElement: any) => {
            return passedElement !== null && passedElement !== undefined;
          });
          formValues[prop] = newArray;
        }
        objToReturn[prop] = formValues[prop];
      }
    }

    const results: any = await update_user_driver_details(objToReturn);
    if (results.payload.status) {
      toastBuilder("success", "Updated Successfully");
      setSubmitting(false);
      getProfileData();
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const initialValues = {
    ideal_company: "",
    experience: "",
    endorsements: ["one"],
    haul_type: "",
    twic_card: "",
    what_you_haul: "",
    accidents: "",
    checked: [],
  };

  const [formvalues, setformvalues] = useState(initialValues);
  useEffect(() => {
    setformvalues(currentUser);
  }, [currentUser]);

  return (
    <div className="right-tabs driving-tab">
      <div className="input-fields-area driver-detail">
        <Formik
          onSubmit={handleSubmit}
          initialValues={formvalues}
          enableReinitialize={true}
        >
          {({
            setFieldValue,
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="detail-field-row">
                  <h4>Ideal Company for you: </h4>
                  <div className="detail-info">
                    <span>
                      <label>
                        <Field
                          type="radio"
                          name="ideal_company"
                          value="Carrier"
                          id="Carrier"
                        />
                        <label htmlFor="Carrier">Carrier</label>
                      </label>
                    </span>
                    <span>
                      <label>
                        <Field
                          type="radio"
                          name="ideal_company"
                          value="Private fleet"
                          id="Private"
                        />
                        <label htmlFor="Private">Private Fleet</label>
                      </label>
                    </span>
                    <span>
                      <label>
                        <Field
                          type="radio"
                          name="ideal_company"
                          value="both"
                          id="both"
                        />
                        <label htmlFor="both">Both</label>
                      </label>
                    </span>
                    <span className="errorMsg">
                      {touched.ideal_company && errors.ideal_company}
                    </span>
                  </div>
                </div>

                <div className="detail-field-row">
                  <h4>Years Experience: </h4>
                  <select
                    name="experience"
                    onChange={handleChange}
                    value={values?.experience}
                    className="exp-dropdown"
                  >
                    <option value="">Select</option>
                    <option value="1-3 Year">1-3 years</option>
                    <option value="2-5 Year">2-5 years</option>
                    <option value="5-10 Year">5-10 years</option>
                    <option value="10+">10+ years</option>
                  </select>
                  <span className="errorMsg">
                    {touched.experience && errors.experience}
                  </span>
                </div>

                <div className="detail-field-row">
                  <h4>Endorsements: </h4>
                  <div className="detail-info">
                    {SharedData.Endorsements.map(
                      (endorsement: string, index) => {
                        return (
                          <span>
                            <div>
                              <Field
                                type="checkbox"
                                className="checkbox-custom"
                                id={`endorsements[${index}]`}
                                name="endorsements"
                                value={endorsement}
                              />
                              <label
                                htmlFor={`endorsements[${index}]`}
                                className="checkbox-custom-label"
                              >
                                {endorsement}
                              </label>
                            </div>
                          </span>
                        );
                      }
                    )}
                    <span className="errorMsg">
                      {touched.endorsements && errors.endorsements}
                    </span>
                  </div>
                </div>

                <div className="detail-field-row">
                  <h4>TWIC Card: </h4>
                  <div className="detail-info">
                    {[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ].map((data) => {
                      return (
                        <span key={data.label}>
                          <label>
                            <Field
                              type="radio"
                              value={data.value}
                              onChange={(event: any) => {
                                const value = event.target.value;
                                if (value === "true")
                                  setFieldValue("twic_card", true);
                                if (value === "false")
                                  setFieldValue("twic_card", false);
                              }}
                              name="twic_card"
                              id={data.label}
                            />
                            <label htmlFor={data.label}>{data.label}</label>
                          </label>
                        </span>
                      );
                    })}
                    <span className="errorMsg">
                      {touched.twic_card && errors.twic_card}
                    </span>
                  </div>
                </div>

                <div className="detail-field-row">
                  <h4>Haul Type: </h4>
                  <div className="detail-info">
                    {SharedData.Haul_Type.map((hualType) => {
                      return (
                        <span key={hualType.value}>
                          <label>
                            <Field
                              type="radio"
                              value={hualType.value}
                              name="haul_type"
                              id={hualType.value}
                            />
                            <label htmlFor={hualType.value}>
                              {hualType.label}
                            </label>
                          </label>
                        </span>
                      );
                    })}
                    <span className="errorMsg">
                      {touched.haul_type && errors.haul_type}
                    </span>
                  </div>
                </div>

                <div className="detail-field-row">
                  <h4>What do you Haul?</h4>
                  <div className="detail-info">
                    {SharedData.whatHult.map((hault) => {
                      return (
                        <span key={hault.value}>
                          <label>
                            <Field
                              type="radio"
                              value={hault.value}
                              name="what_you_haul"
                              id={hault.value}
                            />
                            <label htmlFor={hault.value}>{hault.label}</label>
                          </label>
                        </span>
                      );
                    })}
                    <span className="errorMsg">
                      {touched.what_you_haul && errors.what_you_haul}
                    </span>
                  </div>
                </div>

                <div className="detail-field-row">
                  <h4>Accident in 3 Years?</h4>
                  <div className="detail-info">
                    {SharedData.Accident.map((accident: any) => {
                      return (
                        <span key={accident.value}>
                          <label>
                            <Field
                              type="radio"
                              value={accident.value}
                              name="accidents"
                              id={accident.value}
                            />
                            <label htmlFor={accident.value}>
                              {accident.label}
                            </label>
                          </label>
                        </span>
                      );
                    })}
                    <span className="errorMsg">
                      {touched.accidents && errors.accidents}
                    </span>
                  </div>
                </div>

                <div className="text-center mt-md-4">
                  <button type="submit" className="btn btn-medium btn-purple">
                    Update
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default DrivingDetailTab;
