import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { store } from "src/store";
import { models } from "src/enums";
import { leaveChatScreen } from "src";
import { groupBy, mapValues } from "lodash";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { selectUser } from "src/container/auth/auth.selector";
import { join_chat_screen } from "src/container/message/message.actions";
import { GrDocumentDownload } from "react-icons/gr";
import { IoMdAttach, IoMdCloseCircle } from "react-icons/io";
/* @ts-ignore */
import Linkify from "react-linkify";
import {
  getChattingList,
  messageData,
} from "src/container/message/message.selector";

const ChatPopUp: React.FC<{}> = () => {
  const { set_active_model, getAllMessage, readAllMessages, sendMessage } =
    useActions();
  const [message, setMessages] = useState([]);
  const getMessageData = useAppSelector(messageData);
  const getChatList = useAppSelector(getChattingList);
  const selectedUser = useAppSelector(selectUser);
  const [typedMsg, setTypedMsg] = useState<string>("");
  const [uploadImages, setUploadImages] = useState<any>([]);
  const messagesRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messagesRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };
  let tempPickList = JSON.parse(JSON.stringify(getChatList));
  const today = moment(new Date()).format("MM-DD-YYYY");

  let dateObj = new Date();
  dateObj.setDate(dateObj.getDate() - 1);

  const yesterday = moment(dateObj).format("MM-DD-YYYY");

  useEffect(() => {
    // let currentDriverID: string;
    // let currentRecruiterID: string;
    // if (selectedUser?.role === "driver") {
    //   currentDriverID = selectedUser._id;
    // } else {
    //   currentDriverID = getMessageData?.msgData?.id;
    // }
    // if (selectedUser?.role === "sales") {
    //   currentDriverID = selectedUser._id;
    // } else {
    //   currentDriverID = getMessageData?.msgData?.id;
    // }
    // if (selectedUser?.role === "development") {
    //   currentDriverID = selectedUser._id;
    // } else {
    //   currentDriverID = getMessageData?.msgData?.id;
    // }
    // if (selectedUser?.role === "recruiter") {
    //   currentRecruiterID = selectedUser._id;
    // } else {
    //   currentRecruiterID = getMessageData?.msgData?.id;
    // }
    store.dispatch(
      join_chat_screen({
        firstUser: selectedUser?._id,
        secondUser: getMessageData?.msgData?.id,
      })
    );

    const getValue = async () => {
      await getAllMessage({ to_id: getMessageData?.msgData?.id });
      readAllMessages({ to_id: getMessageData?.msgData?.id });
      document.body.style.overflow = "hidden";
      if (messagesRef.current) {
        scrollToBottom();
      }
    };
    getValue();

    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  useEffect(() => {
    for (let elem of tempPickList) {
      const newMainDate = moment(elem.createdAt).format("MM-DD-YYYY");
      if (newMainDate !== "Invalid date") {
        elem.createdAt = newMainDate;
      }
    }
    setMessages(tempPickList);
  }, [getChatList]);

  const onTextSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    let formData = new FormData();

    formData.append("text", typedMsg);
    formData.append("to", getMessageData?.msgData?.id);
    for (let focusedElem of uploadImages) {
      formData.append("filesToBeUploaded", focusedElem.orgObject);
    }

    await sendMessage(formData);
    setTypedMsg("");
    await getAllMessage({ to_id: getMessageData?.msgData?.id });
    scrollToBottom();
    setUploadImages([]);
  };

  const handleFileUpload = (event: any) => {
    const previewImgs = Array.from(event.target.files);
    const previewImgsMapped = previewImgs.map((focusedImg: any) => {
      return {
        src: URL.createObjectURL(focusedImg),
        orgObject: focusedImg,
        type: focusedImg.type,
        name: focusedImg.name,
      };
    });
    setUploadImages((previous: any) => [...previous, ...previewImgsMapped]);
    event.target.value = null;
  };

  const handleRemoveImage = (elem: any) => {
    setUploadImages((prevState: any) => {
      return prevState.filter((data: any) => {
        return data.src !== elem.src;
      });
    });
  };

  const groupedMessage = mapValues(groupBy(message, "createdAt"));
  const msgToReturn = [];
  for (let focusedGroupedMsg in groupedMessage) {
    const groupMsgsArray = groupedMessage[focusedGroupedMsg];
    msgToReturn.push(
      <div className="channel__body__grouped">
        <div className="channel__body__day">
          <div className="channel__body__date">
            {today === focusedGroupedMsg ? (
              <span>Today</span>
            ) : yesterday === focusedGroupedMsg ? (
              <span>Yesterday</span>
            ) : (
              <span>{focusedGroupedMsg}</span>
            )}
          </div>
        </div>
      </div>
    );
    const relMsgs = groupMsgsArray.map((curElem: any, index: any) => {
      return (
        <div key={index}>
          {curElem.receive && (
            <div className="chat-container">
              <div className="msg-outer-div">
                <div className="chat-message">
                  <p>
                    <Linkify
                      componentDecorator={(
                        decoratedHref: any,
                        decoratedText: any,
                        key: any
                      ) => (
                        <a target="blank" href={decoratedHref} key={key}>
                          {decoratedText}
                        </a>
                      )}
                    >
                      {curElem.text}
                    </Linkify>
                  </p>
                  {curElem?.link_data ? (
                    <>
                      {curElem?.link_data.map((elem: any) => {
                        return (
                          <div>
                            <a
                              href={elem?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="w-100 rounded"
                                alt=""
                                src={elem?.image}
                              />
                            </a>
                            <span>{elem?.title}</span>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}

                  {curElem?.images.length > 0 && (
                    <div className="message-images">
                      {curElem?.images.map((elem: any) => {
                        return (
                          <a href={elem} target="_blank" rel="noreferrer">
                            <img src={elem} alt="" />
                          </a>
                        );
                      })}
                    </div>
                  )}

                  {curElem?.documents.length > 0 && (
                    <div className="documents-wrapper">
                      {curElem?.documents.map((elem: any) => {
                        return (
                          <a href={elem?.file_url} download>
                            <div>
                              <i>
                                <GrDocumentDownload />
                              </i>
                              <label>{elem?.file_name}</label>
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  )}
                </div>
                <p>{moment(curElem.message_date).format("hh:mm A")}</p>
              </div>
              <img src={selectedUser.avatar} alt="" />
            </div>
          )}

          {curElem.receive === false && (
            <div className="chat-container msg-sender">
              <div className="msg-outer-div">
                <div className="chat-message">
                  <p>
                    <Linkify
                      componentDecorator={(
                        decoratedHref: any,
                        decoratedText: any,
                        key: any
                      ) => (
                        <a target="blank" href={decoratedHref} key={key}>
                          {decoratedText}
                        </a>
                      )}
                    >
                      {curElem.text}
                    </Linkify>
                  </p>
                  {curElem?.link_data ? (
                    <>
                      {curElem?.link_data.map((elem: any) => {
                        return (
                          <div>
                            <a
                              href={elem?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                className="w-100 rounded"
                                alt=""
                                src={elem?.image}
                              />
                            </a>
                            <span>{elem?.title}</span>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}

                  <div className="message-images">
                    {curElem?.images.map((elem: any) => {
                      return (
                        <a href={elem} target="_blank" rel="noreferrer">
                          <img src={elem} alt="" />
                        </a>
                      );
                    })}
                  </div>

                  <div className="documents-wrapper">
                    {curElem?.documents.map((elem: any) => {
                      return (
                        <a href={elem?.file_url} download>
                          <div>
                            <i>
                              <GrDocumentDownload />
                            </i>
                            <label>{elem?.file_name}</label>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
                <p className="show_msg_time">
                  {moment(curElem.message_date).format("hh:mm A")}
                </p>
              </div>
              <img src={getMessageData?.msgData?.avatar} alt="" />
            </div>
          )}
        </div>
      );
    });
    msgToReturn.push(relMsgs);
  }

  return (
    <>
      <div className="sidebar active chatting-model">
        <div className="bar-header">
          <div
            onClick={() => set_active_model(models.MESSAGE_MODEL)}
            className="close-sidebarMenu go-back"
          >
            <i className="fa-solid fa-arrow-left-long"></i>
          </div>
          <div className="main-heading text-center">
            <h4>{getMessageData?.msgData?.name}</h4>
          </div>
          <div
            className="close-sidebarMenu"
            onClick={() => {
              set_active_model(models.CLEAR);
              leaveChatScreen();
            }}
          >
            <i className="fa-solid fa-x" />
          </div>
        </div>

        <div className="chat-wrapper">
          {msgToReturn?.map((curElem: any, index: number) => {
            return <div key={index}>{curElem}</div>;
          })}

          <div className="uploaded-wrapper">
            {uploadImages.map((elem: any, index: number) => {
              return (
                <div key={index}>
                  <i
                    className="close-btn"
                    onClick={() => handleRemoveImage(elem)}
                  >
                    <IoMdCloseCircle />
                  </i>
                  {elem.type === "text/csv" ||
                  elem.type === "application/pdf" ||
                  elem.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                  elem.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                    <div className="uploaded-documents">
                      <i>
                        <GrDocumentDownload />
                      </i>
                      <label>{elem.name}</label>
                    </div>
                  ) : (
                    <img src={elem.src} alt="" />
                  )}
                </div>
              );
            })}
          </div>

          <form onSubmit={onTextSubmit} className="chat-input">
            <label>
              <input
                multiple
                type="file"
                className="attach-icon"
                onChange={(event: any) => handleFileUpload(event)}
                // accept="image/png, image/jpeg, image/jpg, image/heic, image/heif, .xlsx, .csv, .pdf, .docx, doc"
              />
              <IoMdAttach />
            </label>

            <input
              type="text"
              placeholder="Type Message!"
              value={typedMsg}
              className="msg-field"
              onChange={(event) => setTypedMsg(event.target.value)}
            />
            <i className="fa-solid fa-paper-plane" onClick={onTextSubmit}></i>
          </form>

          <div ref={messagesRef} />
        </div>
      </div>
      <div className="bg-overlay-fixed"></div>
    </>
  );
};

export default ChatPopUp;
