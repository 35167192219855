import React from "react";
import App from "./App";
import socket from "./socket";
import { store } from "./store";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { config } from "src/config/constants";
import { BrowserRouter } from "react-router-dom";
import { ErrorHanlder } from "./utils/ErrorHanlder";
import { getcurrentuser } from "./utils/localstorage";
import { setloader } from "./container/Loader/loader.slice";
import { settokenAndUser } from "./container/auth/auth.slice";
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import {
  registercurrentuserwithsocket,
  removeUserFromSocket,
  leave_chat_screen,
  messages_count,
  notify_count,
  get_messages,
} from "./container/message/message.actions";
import "src/assets/css/bootstrap.min.css";
import "src/assets/css/style.scss";

const { SERVER_URL, SOCKET_URL } = config;
const socketClient = new socket(SOCKET_URL);

axios.interceptors.request.use(
  (req: AxiosRequestConfig) => {
    const token = store.getState().userstate.token;
    req.url = (SERVER_URL as string) + req.url;

    if (token) {
      req.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    if (
      // req.url === (SERVER_URL as string) + "/message/sendMessage" ||
      req.url ===
      (SERVER_URL as string) + "/message/getAllMessage"
    ) {
      store.dispatch(setloader(false));
      return req;
    }
    store.dispatch(setloader(true));
    return req;
  },
  (err) => {
    store.dispatch(setloader(false));
    return new ErrorHanlder(err);
  }
);

axios.interceptors.response.use(
  (res: AxiosResponse) => {
    store.dispatch(setloader(false));
    return res.data;
  },
  (err: AxiosError) => {
    store.dispatch(setloader(false));
    return new ErrorHanlder(err);
  }
);

function registerCurrentUser() {
  store.dispatch(
    registercurrentuserwithsocket({
      userId: store.getState().userstate.user._id,
    })
  );
}

function removeCurrentUser() {
  store.dispatch(
    removeUserFromSocket({
      userId: store.getState().userstate.user._id,
    })
  );
}

function leaveChatScreen() {
  store.dispatch(
    leave_chat_screen({
      userId: store.getState().userstate.user._id,
    })
  );
}

const token = window.localStorage.getItem("token");

if (token) {
  const user = getcurrentuser();
  store.dispatch(settokenAndUser({ token, user }));
  store.dispatch(messages_count());
  store.dispatch(notify_count());
  store.dispatch(get_messages());
  registerCurrentUser();
}

ReactDOM.render(
  // <ErrorBoundary>
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  // </ErrorBoundary>,
  document.getElementById("root")
);

export {
  axios as ApiClient,
  socketClient,
  registerCurrentUser,
  removeCurrentUser,
  leaveChatScreen,
};
// exporting axios to use it in base service
