import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { models } from "src/enums";
import { Formik } from "formik";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import toastBuilder from "src/utils/toastBuilder";
import { IoCloseCircleOutline } from "react-icons/io5";
import { getTribeId } from "src/container/Tribe/Tribe.selector";
import { tribeDriversSelector } from "src/container/Tribe/Tribe.selector";

const GetTribeList: React.FC<{}> = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [memberId, setMemberId] = useState("");
  const tribe_id = useAppSelector(getTribeId);
  const getTribeDrivers = useAppSelector(tribeDriversSelector);
  const { set_active_model, getJoinedTribes, getAllMyDrivers, addNewMember } =
    useActions();

  useEffect(() => {
    getAllMyDrivers(tribe_id);

    const div = ref.current; // corresponding DOM node
    div?.classList.add("active");
    document.body.style.overflow = "hidden";
    return () => {
      div?.classList.remove("active");
      document.body.style.overflow = "scroll";
    };
  }, []);

  const initialValues = {
    name: "",
    description: "",
    isPrivate: "",
    members: [],
    image: "",
  };

  const handleSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any
  ) => {
    var formData = new FormData();
    for (let elem of formValues.members) {
      formData.append("members[]", elem);
    }

    const results: any = await addNewMember({
      member_id: memberId,
      tribe_id: tribe_id,
    });
    if (results.payload.status) {
      toastBuilder("success", "Member added succesfully");
      getJoinedTribes();
      setSubmitting(false);
      resetForm();
      set_active_model(models.CLEAR);
    }
  };

  return (
    <>
      <div
        ref={ref}
        style={{ height: "250px" }}
        className="sidebar center-popup tribe-popup"
      >
        <div className="model-info">
          <div
            className="close-icon"
            onClick={() => set_active_model(models.CLEAR)}
          >
            <IoCloseCircleOutline />
          </div>
          <h5 className="mb-4">Add New Member</h5>

          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
          >
            {({ handleSubmit, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="detail-field-row">
                    <div className="detail-info">
                      <Select
                        isMulti
                        placeholder="Select Members..."
                        closeMenuOnSelect={true}
                        onChange={(value) =>
                          setFieldValue(
                            "members",
                            value.map((curElem: any) => {
                              return curElem.value;
                            })
                          )
                        }
                        className="multiSelect-dropdown"
                        options={getTribeDrivers.map((member: any) => ({
                          value: setMemberId(member._id),
                          label: `${member.first_name} ${member.last_name}`,
                        }))}
                      />
                    </div>
                  </div>

                  <button type="submit" className="btn btn-medium btn-purple">
                    Join
                  </button>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className="bg-overlay bg-fixed"></div>
    </>
  );
};

export default GetTribeList;
