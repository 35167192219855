import React, { useEffect, useState } from "react";
import Header from "../Header";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import AddpaymentCard from "./AddPaymnetCardModel";
import stripe_blurple from "../../../src/assets/images/stripe-blurple.svg";
import {
  usedefaultCard,
  usepaymentCads,
} from "src/container/recruiter/recruiter.selector";

const PaymentMethod: React.FC<{}> = (props) => {
  const { getAllPaymentCards, deletePaymentCard, changeDefaultCard } =
    useActions();
  const defaultCard = useAppSelector(usedefaultCard);
  const paymentCardsList = useAppSelector(usepaymentCads);
  const [showPaymentCard, setShowPaymentCard] = useState(false);

  const handleDeleteCard = async (card_id: string) => {
    await deletePaymentCard({ card_id });
    getAllPaymentCards();
  };

  const handleDefaultCardChange = async (card_id: any) => {
    await changeDefaultCard({ card_id });
    getAllPaymentCards();
  };

  useEffect(() => {
    getAllPaymentCards();
  }, []);

  return (
    <>
      <Header />
      <section className="payment-methods">
        <div className="container">
          <div className="top-area">
            <h2>Payment Methods</h2>
            <button
              onClick={() => setShowPaymentCard(true)}
              className="btn btn-medium btn-purple"
            >
              + Add Card
            </button>
          </div>

          {paymentCardsList.length === 0 ? (
            <div className="no-story-text">
              <h2>No Card found...</h2>
            </div>
          ) : (
            <div className="payment-box">
              <div className="row">
                {paymentCardsList.map((data: any) => {
                  return (
                    <div className="col-md-6" key={data.id}>
                      <div className="payment-wrapper">
                        <div className="payment-card">
                          <div className="pcard-info">
                            <p>xxxx xxxx xxxx {data?.last4}</p>
                            <p>Make this default payment option</p>
                            <p>Exp month {data?.exp_month}</p>
                            <p>Exp year {data?.exp_year}</p>
                          </div>
                          <div className="make-defaultCard">
                            <p>{data?.brand}</p>
                            <input
                              checked={data?.id === defaultCard}
                              onClick={() => handleDefaultCardChange(data?.id)}
                              type="checkbox"
                            />
                          </div>
                        </div>
                        <div className="remove-card">
                          <span onClick={() => handleDeleteCard(data?.id)}>
                            <i className="fa-solid fa-trash-can"></i>
                            Remove Card
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>
                <img
                  className="stripe-icon"
                  src={stripe_blurple}
                  alt="Stripe-icon"
                />
              </div>
            </div>
          )}
        </div>
      </section>
      <AddpaymentCard
        showPaymentCard={showPaymentCard}
        setShowPaymentCard={setShowPaymentCard}
      />
    </>
  );
};

export default PaymentMethod;
