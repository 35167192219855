import React, { useState, useEffect } from "react";
import { Field, Formik } from "formik";
import { useActions } from "src/actions";
import { State } from "country-state-city";
import { CgWebsite } from "react-icons/cg";
import { useAppSelector } from "src/store";
import moment from "moment";
import toastBuilder from "src/utils/toastBuilder";
import { DeveloperContactForUpdate } from "src/validation/singnup";
import cityIcon from "src/assets/images/icons/city-icon.svg";
import userIcon from "src/assets/images/icons/user-icon.svg";
import stateIcon from "src/assets/images/icons/flag-icon.svg";
import pinIcon from "src/assets/images/icons/location-icon.svg";
import emailIcon from "src/assets/images/icons/message-icon.svg";
import { selectUser } from "src/container/auth/auth.selector";
import mountIcon from "src/assets/images/icons/mountain-icon.svg";
import { RiLinkedinLine } from "react-icons/ri";
import { FaUniversity, FaUserGraduate } from "react-icons/fa";
import { VscGithub } from "react-icons/vsc";
import SharedData from "../SharedData/SharedApi";

const DevelopmentInfoForm: React.FC<{}> = (props) => {
  document.body.style.overflow = "scroll";
  const currentUser = useAppSelector(selectUser);
  const { updateUserData, getProfileData } = useActions();
  const dates = moment(currentUser?.graduation_date).format("YYYY-MM-DD");
  const [date, setDate] = useState(dates);
  const States = State.getStatesOfCountry("US").map((curElem) => {
    return curElem.name;
  });

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    setstate(currentUser);
  }, [currentUser]);

  const initalValues = {
    first_name: "",
    last_name: "",
    email: "",
    city: "",
    state: "Alabama",
    zip_code: "",
    website: "",
    conditions: true,
    experience: "",
    college: "",
    graduation_date: "",
    major: "",
    git_link: "",
    linkedIn_link: "",
    resume: "",
    individual_skills: [],
    stack_experiences: [],
  };

  const [inistate, setstate] = useState(initalValues);

  const handleSubmit = async (formValues: any) => {
    var bodyFormData = new FormData();
    formValues.individual_skills = formValues.individual_skills.flat();
    formValues.stack_experiences = formValues.stack_experiences.flat();
    const skillsChecks = formValues.individual_skills.filter(
      (passedElement: unknown) => {
        return passedElement !== null && passedElement !== undefined;
      }
    );
    const stackChecks = formValues.stack_experiences.filter(
      (passedElement: unknown) => {
        return passedElement !== null && passedElement !== undefined;
      }
    );
    skillsChecks.forEach((item: any) =>
      bodyFormData.append("individual_skills", item)
    );
    stackChecks.forEach((item: any) =>
      bodyFormData.append("stack_experiences", item)
    );
    bodyFormData.append("role", formValues.role);
    bodyFormData.append("city", formValues.city);
    bodyFormData.append("email", formValues.email);
    bodyFormData.append("state", formValues.state);
    bodyFormData.append("zip_code", formValues.zip_code);
    bodyFormData.append("last_name", formValues.last_name);
    bodyFormData.append("first_name", formValues.first_name);
    bodyFormData.append("website", formValues.website);
    bodyFormData.append("college", formValues.college);
    bodyFormData.append("major", formValues.major);
    bodyFormData.append("experience", formValues.experience);
    bodyFormData.append("graduation_date", formValues.graduation_date);
    bodyFormData.append("git_link", formValues.git_link);
    bodyFormData.append("linkedIn_link", formValues.linkedIn_link);
    bodyFormData.append("resume", formValues.resume);

    const action: any = await updateUserData(bodyFormData);
    if (action?.payload?.status) {
      // setSubmitting(false);
      getProfileData();
      toastBuilder("success", "profile update successfully");
    }
  };

  return (
    <div className="input-fields-area contact-form">
      <Formik
        validationSchema={DeveloperContactForUpdate}
        onSubmit={handleSubmit}
        initialValues={inistate}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          handleBlur,
          values,
          touched,
          errors,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="fields-wrapper ">
                <div className="form-group">
                  <h4>Profile Info</h4>
                </div>
              </div>
              <div className="fields-wrapper">
                <div className="form-group">
                  <label>First Name</label>
                  <div className="form-field">
                    <img src={userIcon} alt="" />
                    <Field type="text" name="first_name" placeholder="John" />
                  </div>
                  <span className="errorMsg">
                    {touched.first_name && errors.first_name}
                  </span>
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <div className="form-field">
                    <img src={userIcon} alt="" />
                    <Field type="text" name="last_name" placeholder="Doe" />
                  </div>
                  <span className="errorMsg">
                    {touched.last_name && errors.last_name}
                  </span>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Email</label>
                  <div className="form-field">
                    <img src={emailIcon} alt="" />
                    <Field
                      type="email"
                      name="email"
                      placeholder="info@email.com"
                    />
                  </div>
                  <span className="errorMsg">
                    {touched.email && errors.email}
                  </span>
                </div>
                <div className="form-group">
                  <label>State</label>
                  <div className="form-field">
                    <img src={stateIcon} alt="" />
                    <select
                      onChange={handleChange}
                      value={values.state}
                      onBlur={handleBlur}
                      name="state"
                    >
                      {States.map((curElem, index) => {
                        return (
                          <option value={curElem} key={index}>
                            {curElem}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <span className="errorMsg">
                    {touched.state && errors.state}
                  </span>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Zip Code</label>
                  <div className="form-field">
                    <img src={pinIcon} alt="" />
                    <Field type="text" name="zip_code" placeholder="99753" />
                  </div>
                  <span className="errorMsg">
                    {touched.zip_code && errors.zip_code}
                  </span>
                </div>
                <div className="form-group">
                  <label>Enter Website Url</label>
                  <div className="form-field">
                    <CgWebsite size={20} />
                    <Field
                      type="text"
                      name="website"
                      placeholder="Enter Website Url"
                    />
                  </div>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>City</label>
                  <div className="form-field">
                    <img src={cityIcon} alt="" />
                    <Field type="text" name="city" placeholder="New York" />
                  </div>
                  <span className="errorMsg">
                    {touched.city && errors.city}
                  </span>
                </div>
              </div>
              <div className="fields-wrapper mt-4">
                <div className="form-group">
                  <h4>Experience</h4>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Enter College</label>
                  <div className="form-field">
                    <FaUniversity size={18} />
                    <Field
                      type="text"
                      name="college"
                      placeholder="Enter Your College"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Major</label>
                  <div className="form-field">
                    <img src={pinIcon} alt="" />
                    <Field type="text" name="major" placeholder="Major" />
                  </div>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Years Experience</label>
                  <div className="form-field">
                    <img src={stateIcon} alt="" />
                    <select
                      onChange={handleChange}
                      value={values.experience}
                      onBlur={handleBlur}
                      name="experience"
                    >
                      {SharedData.yearsExperience.map((curElem) => {
                        return (
                          <option key={curElem.value} value={curElem.value}>
                            {curElem.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label>Graduation Date</label>
                  <div className="form-field">
                    <FaUserGraduate size={18} />
                    <Field
                      className="pb-1"
                      type="Date"
                      value={date}
                      onChange={(event: any) => setDate(event.target.value)}
                      name="graduation_date"
                      placeholder="Enter Graduation Date"
                    />
                  </div>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Git Hub Link</label>
                  <div className="form-field">
                    <VscGithub size={20} />
                    <Field
                      type="text"
                      name="git_link"
                      placeholder="Enter Your Git Hub Link"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Your LinkedIn Link</label>
                  <div className="form-field">
                    <RiLinkedinLine size={18} />
                    <Field
                      type="text"
                      name="linkedIn_link"
                      placeholder="Enter Your LinkedIn Link"
                    />
                  </div>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Upload Resume</label>
                  <div className="upload-logo flex-row">
                    <div className="logo-box">
                      <div className="upload-wrapper flex-row justify-content-start">
                        <img src={mountIcon} alt="" className="resume_icon" />
                        <input
                          type="file"
                          accept=".xlsx, .csv, .pdf, .doc, .docx, .ppt, .pptx, rtf"
                          onChange={(event: any) => {
                            setFieldValue("resume", event.target.files[0]);
                          }}
                          name="resume"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="fields-wrapper mt-4">
                <div className="form-group">
                  <h4>Skills</h4>
                </div>
              </div>

              <div className="driver-detail dev-skills">
                <div className="form-group">
                  <div className="detail-field-row">
                    <label>Stack Experience: </label>
                    <div className="detail-info four-col-grid">
                      {SharedData.StackExperiences.map(
                        (stackExperiences: any, index: number) => {
                          return (
                            <span key={stackExperiences.value} className="mb-1">
                              <div>
                                <Field
                                  id={`stackExperiences[${index}]`}
                                  name="stack_experiences"
                                  value={stackExperiences}
                                  type="checkbox"
                                  className="checkbox-custom"
                                />
                                <label
                                  htmlFor={`stackExperiences[${index}]`}
                                  className="checkbox-custom-label"
                                >
                                  {stackExperiences}
                                </label>
                              </div>
                            </span>
                          );
                        }
                      )}
                      {/* <span className="errorMsg">
                        {touched.stackExperiences && errors.stackExperiences}
                      </span> */}
                    </div>
                  </div>

                  <div className="detail-field-row">
                    <label>Individual Skills: </label>
                    <div className="detail-info four-col-grid">
                      {SharedData.IndividualSkill.map(
                        (individualSkills: any, index: number) => {
                          return (
                            <span key={individualSkills.value} className="mb-1">
                              <div>
                                <Field
                                  id={`individualSkills[${index}]`}
                                  name="individual_skills"
                                  value={individualSkills}
                                  type="checkbox"
                                  className="checkbox-custom"
                                />
                                <label
                                  htmlFor={`individualSkills[${index}]`}
                                  className="checkbox-custom-label"
                                >
                                  {individualSkills}
                                </label>
                              </div>
                            </span>
                          );
                        }
                      )}
                      {/* <span className="errorMsg">
                        {touched.individualSkills && errors.individualSkills}
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-right mt-3">
                <button type="submit" className="btn btn-medium btn-purple">
                  Update
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DevelopmentInfoForm;
