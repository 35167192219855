import React, { useState } from "react";
import Header from "../Header";
import ProfileTab from "./ProfileTab";
import CompanyTab from "./CompanyTab";
import AccountTabs from "./AccountTabs";
import DrivingDetailTab from "./DrivingDetail";
import ResetPasswordTab from "./ResetPasswordTab";

const AccountSetting: React.FC<{}> = (props) => {
  const [selectedTab, setselectedTab] = useState("ProfileTab");

  return (
    <>
      <Header />
      <section className="my-account">
        <div className="container">
          <h2>My Account</h2>
          <div className="row">
            <div className="col-xl-2 col-lg-3 col-md-3 col-12">
              <AccountTabs
                selectedTab={selectedTab}
                setselectedTab={setselectedTab}
              />
            </div>
            <div className="col-xl-10 col-lg-8 col-md-9 col-12">
              {selectedTab === "ProfileTab" ? (
                <ProfileTab />
              ) : selectedTab === "ResetPasswordTab" ? (
                <ResetPasswordTab />
              ) : selectedTab === "CompanyTab" ? (
                <CompanyTab />
              ) : selectedTab === "DrivingDetailTab" ? (
                <DrivingDetailTab />
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountSetting;
