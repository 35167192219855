import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiClient } from "src/index";

export const getAllDrivers = createAsyncThunk("/tribe/get-all-drivers", (payload, thunkApi) => {
  const result = ApiClient.get("/tribe/get-all-drivers");
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const getJoinedTribes = createAsyncThunk("/tribe/get-joined-tribes", (payload, thunkApi) => {
  const result = ApiClient.get("/tribe/get-joined-tribes");
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const createTribe = createAsyncThunk(
  "/tribe/create-tribe",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/tribe/create-tribe", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const joinTribe = createAsyncThunk(
  "/tribe/join-tribe",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/tribe/join-tribe", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const getAllDiscussion = createAsyncThunk("/tribeDiscussion/get-all-discussion", (payload : {tribeId: string}, thunkApi) => {
  const result = ApiClient.get(`/tribeDiscussion/get-all-discussion/${payload}/0/5`);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const addDiscussion = createAsyncThunk(
  "/tribeDiscussion/add-discussion",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/tribeDiscussion/add-discussion", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const deleteDiscussion = createAsyncThunk("/tribeDiscussion/delete-discussion", (payload :{tribeId : string}, thunkApi) => {
  const result = ApiClient.delete("/tribeDiscussion/delete-discussion/" + payload);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const getTribeDetail = createAsyncThunk("/tribe/tribe-details", (payload: {tribeId: string}, thunkApi) => {
  const result = ApiClient.get(`/tribe/tribe-details/${payload}`);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const getAllMyDrivers = createAsyncThunk("/tribe/get-all-drivers", (payload: {tribeId: string}, thunkApi) => {
  const result = ApiClient.get(`/tribe/get-all-drivers?tribe_id=${payload}`);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const addNewMember = createAsyncThunk(
  "/tribe/add-member",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/tribe/add-member", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const removeMember = createAsyncThunk(
  "/tribe/remove-member",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/tribe/remove-member", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const addUploadFile = createAsyncThunk(
  "/tribeDiscussion/upload",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/tribeDiscussion/upload", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const deleteTribe = createAsyncThunk("/tribe/delete-tribe", (payload :{tribeId : string}, thunkApi) => {
  const result = ApiClient.delete("/tribe/delete-tribe/" + payload);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const editDiscussion = createAsyncThunk(
  "/tribeDiscussion/edit-discussion",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.patch(`/tribeDiscussion/edit-discussion/${payload?.discussion_id}`, {discussion: payload?.discussion});      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const editTribe = createAsyncThunk("tribe/edit-tribe", (payload: any , thunkApi) => {
  const result = ApiClient.patch(`/tribe/edit-tribe/${payload?.tribe_id}`, {description: payload?.description });
  if (result) return result;
  else thunkApi.rejectWithValue("");
});