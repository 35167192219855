import React from "react";
import { useAppSelector } from "./store";
import Loader from "./General/views/Loader";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AuthenticatedApp from "./Routes/AuthenticatedApp";
import { selectToken } from "./container/auth/auth.selector";
import UnauthenticatedApp from "./Routes/UnauthenticatedApp";
import { selecloader } from "./container/Loader/loader.selector";

const App: React.FC = () => {
  const loader = useAppSelector(selecloader);
  const token = useAppSelector(selectToken);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {token ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      <Loader display={loader} />
    </div>
  );
};

export default App;
