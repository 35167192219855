import React, { useState } from "react";
import { useActions } from "src/actions";
import { State } from "country-state-city";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import toastBuilder from "src/utils/toastBuilder";
import { workHistoryForDriver } from "src/validation/singnup";
import cityIcon from "src/assets/images/icons/city-icon.svg";
import stateIcon from "src/assets/images/icons/flag-icon.svg";
import phoneIcon from "src/assets/images/icons/phone-icon.svg";
import pinIcon from "src/assets/images/icons/location-icon.svg";
import { Formik, Field, FieldArray, ErrorMessage } from "formik";
import calendarIcon from "src/assets/images/icons/calendar-icon.svg";

const EmploymentHistory: React.FC<any> = (props) => {
  const [collapseForm, setCollapseForm] = useState(0);
  const { addDriverEmploymentHistory } = useActions();
  const navigation = useNavigate();

  const handleSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any
  ) => {
    const results: any = await addDriverEmploymentHistory(formValues);
    if (results.payload.status) {
      navigation({
        pathname: "/my-account",
        search: `?tab=profile`,
      });
      toastBuilder("success", "SignUp Successful");
      setSubmitting(false);
      resetForm();
    }
  };

  const States = State.getStatesOfCountry("US").map((curElem) => {
    return curElem.name;
  });

  const initialValues = {
    driverEmpHistory: [
      {
        company_name: "",
        city: "",
        state: "Alabama",
        zip: "",
        start_date: "",
        end_date: "",
        dot: "",
        phone: "",
      },
    ],
  };

  const toggleForm = (index: number) => {
    if (collapseForm === index) {
      // @ts-ignore
      return setCollapseForm(null);
    }
    setCollapseForm(index);
  };

  const addNewEmpForm = (push: any, arrLength: any) => {
    push({
      company_name: "",
      city: "",
      state: "",
      zip: "",
      start_date: "",
      end_date: "",
      dot: "",
      phone: "",
    });
    setCollapseForm(arrLength);
  };

  return (
    <div className="input-fields-area employ-history">
      <Formik
        validationSchema={workHistoryForDriver}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, handleBlur, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldArray name="driverEmpHistory">
                {({ push, remove }) => (
                  <>
                    {values.driverEmpHistory.map((_, index) => (
                      <div className="panel-group" key={index}>
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h4
                              className={
                                collapseForm === index
                                  ? "panel-title"
                                  : "panel-title active"
                              }
                              onClick={() => {
                                toggleForm(index);
                              }}
                            >
                              History Form
                              {values.driverEmpHistory.length > 1 && (
                                <span
                                  className="deleteFormBtn"
                                  onClick={() => remove(index)}
                                >
                                  <MdDeleteForever />
                                </span>
                              )}
                            </h4>
                          </div>
                          <div
                            className={
                              collapseForm === index
                                ? "panel-collapse"
                                : "panel-collapse panel-close"
                            }
                          >
                            <div>
                              <div className="fields-wrapper">
                                <div className="form-group">
                                  <div className="form-field">
                                    <img src={cityIcon} alt="" />
                                    <Field
                                      type="text"
                                      name={`driverEmpHistory.${index}.company_name`}
                                      placeholder="Company Name"
                                    />
                                  </div>
                                  <span className="errorMsg">
                                    <ErrorMessage
                                      name={`driverEmpHistory.${index}.company_name`}
                                    />
                                  </span>
                                </div>
                                <div className="form-group">
                                  <div className="form-field">
                                    <img src={cityIcon} alt="" />
                                    <Field
                                      type="text"
                                      placeholder="City"
                                      name={`driverEmpHistory.${index}.city`}
                                    />
                                  </div>
                                  <span className="errorMsg">
                                    <ErrorMessage
                                      name={`driverEmpHistory.${index}.city`}
                                    />
                                  </span>
                                </div>
                              </div>

                              <div className="fields-wrapper">
                                <div className="form-group">
                                  <div className="form-field">
                                    <img src={stateIcon} alt="" />
                                    <select
                                      name={`driverEmpHistory.${index}.state`}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    >
                                      {States.map((curElem, index) => {
                                        return (
                                          <option value={curElem} key={index}>
                                            {curElem}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <span className="errorMsg">
                                    <ErrorMessage
                                      name={`driverEmpHistory.${index}.state`}
                                    />
                                  </span>
                                </div>
                                <div className="form-group">
                                  <div className="form-field">
                                    <img src={pinIcon} alt="" />
                                    <Field
                                      type="text"
                                      placeholder="Zip"
                                      name={`driverEmpHistory.${index}.zip`}
                                    />
                                  </div>
                                  <span className="errorMsg">
                                    <ErrorMessage
                                      name={`driverEmpHistory.${index}.zip`}
                                    />
                                  </span>
                                </div>
                              </div>

                              <div className="fields-wrapper">
                                <div className="form-group">
                                  <div className="form-field">
                                    <img src={calendarIcon} alt="" />
                                    <Field
                                      type="date"
                                      placeholder="Start Date"
                                      name={`driverEmpHistory.${index}.start_date`}
                                    />
                                  </div>
                                  <span className="errorMsg">
                                    <ErrorMessage
                                      name={`driverEmpHistory.${index}.start_date`}
                                    />
                                  </span>
                                </div>
                                <div className="form-group">
                                  <div className="form-field">
                                    <img src={calendarIcon} alt="" />
                                    <Field
                                      type="date"
                                      placeholder="End Date"
                                      name={`driverEmpHistory.${index}.end_date`}
                                    />
                                  </div>
                                  <span className="errorMsg">
                                    <ErrorMessage
                                      name={`driverEmpHistory.${index}.end_date`}
                                    />
                                  </span>
                                </div>
                              </div>

                              <div className="fields-wrapper">
                                <div className="form-group">
                                  <div className="form-field">
                                    <img src={stateIcon} alt="" />
                                    <Field
                                      type="text"
                                      placeholder="Dot#"
                                      name={`driverEmpHistory.${index}.dot`}
                                    />
                                  </div>
                                  <span className="errorMsg">
                                    <ErrorMessage
                                      name={`driverEmpHistory.${index}.dot`}
                                    />
                                  </span>
                                </div>
                                <div className="form-group">
                                  <div className="form-field">
                                    <img src={phoneIcon} alt="" />
                                    <Field
                                      type="text"
                                      placeholder="Phone"
                                      name={`driverEmpHistory.${index}.phone`}
                                    />
                                  </div>
                                  <span className="errorMsg">
                                    <ErrorMessage
                                      name={`driverEmpHistory.${index}.phone`}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      className="addFormBtn"
                      onClick={() =>
                        addNewEmpForm(push, values.driverEmpHistory.length)
                      }
                      type="submit"
                    >
                      <AiOutlinePlus /> <span>Add Form</span>
                    </button>
                  </>
                )}
              </FieldArray>
              <div className="text-right mt-5">
                <button type="submit" className="btn btn-medium btn-purple">
                  Submit
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
export default EmploymentHistory;
