import { createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "src/store";
import { ApiClient } from "src/index";
import { config } from "src/config/constants";
import toastBuilder from "src/utils/toastBuilder";
import { setloader } from "../Loader/loader.slice";

const { STRIPE_URL } = config;

export const AddrecuriterCompanyInfo = createAsyncThunk(
  "/AddrecuriterCompanyInfo/addRecruiterCompanyInfo",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/recruiter/addRecruiterCompanyInfo", payload);

    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const addpaymentInfo = createAsyncThunk(
  "/AddrecuriterCompanyInfo/addRecruiterCompanyInfo",
  async (payload: any, thunkApi) => {
    try {
      store.dispatch(setloader(true));
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${STRIPE_URL}`
      );
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      var urlencoded = new URLSearchParams();
      urlencoded.append("card[number]", payload.card_number);
      urlencoded.append("card[exp_month]", payload.exp_month);
      urlencoded.append("card[exp_year]", payload.exp_year);
      urlencoded.append("card[cvc]", payload.cvv);

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      /* @ts-ignore */
      const resuls = await fetch("https://api.stripe.com/v1/tokens", requestOptions);
      const jsonresults = await resuls.json();

      if (jsonresults.id) {
        return jsonresults;
      }

      if (jsonresults?.error) {
        thunkApi.rejectWithValue(jsonresults?.error?.message);
        store.dispatch(setloader(false));

        toastBuilder("error", jsonresults?.error?.message);
      }
    } catch (error) {
      store.dispatch(setloader(false));
    }
  }
);

export const addrecuriterpaymetninfo = createAsyncThunk(
  "/addrecuriterpaymetninfo/addrecuriterpaymetninfo",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/recruiter/addRecruiterPaymentInfo", payload);
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const getAllPaymentCards = createAsyncThunk(
  "/getAllPaymentCards/getAllPaymentCards",
  async (payload, thunkApi) => {
    const results = await ApiClient.get("/recruiter/getAllCards");
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const deletePaymentCard = createAsyncThunk(
  "/recruiter/deleteCard/",
  async (payload: { card_id: string }, thunkApi) => {
    const results = await ApiClient.post("/recruiter/deleteCard", payload);
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const changeDefaultCard = createAsyncThunk(
  "/changeDefaultCard/changeDefaultCard/",
  async (payload: { card_id: string }, thunkApi) => {
    const results = await ApiClient.post("/recruiter/changeDefaultCard", payload);
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const getNumberOfEngagedUserCount = createAsyncThunk(
  "/user/getNumberOfEngagedUserCount",
  async (payload, thunkApi) => {
    const results = await ApiClient.get("/user/getNumberOfEngagedUserCount");
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const getEngagedUserDetails = createAsyncThunk(
  "/user/getEngagedUserDetails",
  async (payload, thunkApi) => {
    const results = await ApiClient.get("/user/getEngagedUserDetails");
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const getAllApplicantsList = createAsyncThunk(
  "/recruiter/getAllApplicantsList",
  async (payload: { card_id: string }, thunkApi) => {
    const results = await ApiClient.post("/recruiter/getAllApplicantsList", payload);
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const getAllApplicants = createAsyncThunk(
  "/recruiter/getAllApplicants",
  async (payload: { card_id: string }, thunkApi) => {
    const results = await ApiClient.post("/recruiter/getAllApplicants", payload);
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const hireAnyDriver = createAsyncThunk(
  "/recruiter/hireAnyDriver",
  async (payload: { card_id: string }, thunkApi) => {
    const results = await ApiClient.post("/recruiter/hireAnyDriver", payload);
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const getHiredApplicantsList = createAsyncThunk(
  "/recruiter/getHiredApplicants",
  async (payload, thunkApi) => {
    const results = await ApiClient.get("/recruiter/getHiredApplicants");
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);