import React from "react";
import { Field, Formik } from "formik";
import { useActions } from "src/actions";
import { GiRecycle } from "react-icons/gi";
import { BiCoinStack } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import stateIcon from "src/assets/images/icons/flag-icon.svg";
import { salesSkillsValidation } from "src/validation/singnup";
import pinIcon from "src/assets/images/icons/location-icon.svg";
import SharedData from "../SharedData/SharedApi";

const SalesSkills: React.FC = (props) => {
  document.body.style.overflow = "scroll";
  const navigation = useNavigate();
  const { addSkills } = useActions();

  const initialValues = {
    salesMethodologies: [],
    likeSale: "",
    futurePlan: "",
    largestDealWon: "",
    usualSalesCycles: "",
  };

  const handleSubmit = async (formValues: any) => {
    formValues.salesMethodologies = formValues.salesMethodologies.flat();
    formValues.salesMethodologies = formValues.salesMethodologies.filter(
      (passedElement: unknown) => {
        return passedElement !== null && passedElement !== undefined;
      }
    );

    const action: any = await addSkills(formValues);
    if (action?.payload?.status) {
      navigation({
        pathname: "/my-account",
        search: `?tab=profile`,
      });
    }
  };

  return (
    <div className="input-fields-area">
      <Formik
        validationSchema={salesSkillsValidation}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Average Deal Size</label>
                  <div className="form-field">
                    <img src={stateIcon} alt="" />
                    <select
                      onChange={handleChange}
                      value={values.largestDealWon}
                      onBlur={handleBlur}
                      name="largestDealWon"
                    >
                      {SharedData.DealWon.map((curElem) => {
                        return (
                          <option key={curElem.value} value={curElem.value}>
                            {curElem.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <span className="errorMsg">
                    {touched.largestDealWon && errors.largestDealWon}
                  </span>
                </div>
                <div className="form-group">
                  <label>Usual Sales Cycles:</label>
                  <div className="form-field">
                    <GiRecycle size={20} />
                    <select
                      onChange={handleChange}
                      value={values.usualSalesCycles}
                      onBlur={handleBlur}
                      name="usualSalesCycles"
                    >
                      {SharedData.UsualSales.map((curElem) => {
                        return (
                          <option key={curElem.value} value={curElem.value}>
                            {curElem.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <span className="errorMsg">
                    {touched.usualSalesCycles && errors.usualSalesCycles}
                  </span>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group w-100">
                  <label>
                    Where are you today and where do you want to be in 2 years
                  </label>
                  <div className="form-field">
                    <img src={pinIcon} alt="" />
                    <Field
                      type="text"
                      name="futurePlan"
                      placeholder="Free Text"
                    />
                  </div>
                  <span className="errorMsg">
                    {touched.futurePlan && errors.futurePlan}
                  </span>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group w-100">
                  <label>Why Do you Like Sales</label>
                  <div className="form-field">
                    <BiCoinStack size={22} />
                    <Field
                      type="text"
                      name="likeSale"
                      placeholder="Why Do you Like Sales"
                    />
                  </div>
                  <span className="errorMsg">
                    {touched.likeSale && errors.likeSale}
                  </span>
                </div>
              </div>

              <div className="driver-detail">
                <div className="form-group">
                  <div className="detail-field-row mt-2 align-items-baseline d-block">
                    <label>Sales Methodology: </label>
                    <div className="detail-info four-col-grid w-100">
                      {SharedData.Sales_Methodologies.map(
                        (salesMethodologies: any, index: number) => {
                          return (
                            <span
                              key={salesMethodologies.value}
                              className="mb-1"
                            >
                              <div>
                                <Field
                                  id={`salesMethodologies[${index}]`}
                                  name={`salesMethodologies[${index}]`}
                                  value={salesMethodologies.value}
                                  type="checkbox"
                                  className="checkbox-custom"
                                />
                                <label
                                  htmlFor={`salesMethodologies[${index}]`}
                                  className="checkbox-custom-label"
                                >
                                  {salesMethodologies.label}
                                </label>
                              </div>
                            </span>
                          );
                        }
                      )}
                      <span className="errorMsg">
                        {touched.salesMethodologies &&
                          errors.salesMethodologies}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-right mt-5">
                <button type="submit" className="btn btn-medium btn-purple">
                  Submit
                </button>
              </div>
            </form>
          );
        }}
      </Formik>

      <div className="account-signUp">
        <p>Already have account?</p>
        <Link to="/login" className="signUp-link">
          Sign In
        </Link>
      </div>
    </div>
  );
};

export default SalesSkills;
