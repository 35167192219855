import React, { useRef, useState } from "react";
import { models } from "src/enums";
import { State } from "country-state-city";
import { useActions } from "src/actions";
import { Formik, Form, Field } from "formik";
import toastBuilder from "src/utils/toastBuilder";
import SharedData from "../SharedData/SharedApi";
import {
  SalePostJobSchema,
  DriverPostJobSchema,
  PostJobSchema,
} from "src/validation/postjob";
import { DevelopmentPostJobSchema } from "src/validation/postjob";
import mountIcon from "src/assets/images/icons/mountain-icon.svg";

const CreateBroadCastModel: React.FC<{}> = (props) => {
  const [selectDriverUser, setSelectDriverUserSelect] = useState(false);
  const [selectDevelopmentUser, setDevelopmentUserSelect] = useState(false);
  const [selectSalesUser, setSelectUserSelect] = useState(false);
  const { set_active_model, AddPostJob, getAllRecruiterJobs } = useActions();
  const [selectStateUser, setStateUserSelect] = useState(false);
  const imageRef = useRef<HTMLSpanElement>(null);
  const States = State.getStatesOfCountry("US").map((curElem) => {
    return curElem.name;
  });

  const initialValues = {
    title: "",
    description: "",
    jobImage: "",
    experience: "",
    job_location: "",
    job_type: "",
    largest_deal_won: "",
    usual_sales_cycles: "",
    stack_experiences: [],
    skills: [],
    sales_methodologies: [],
    endorsements: [],
    DriversRoles: [],
    application_link: "",
    role: [],
    developersRoles: [],
    state: "Alabama",
    haul_type: "",
  };

  const handleSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any
  ) => {
    let saleMethology = formValues.sales_methodologies.flat();
    let roles = formValues.role.flat();
    let stack_experience = formValues.stack_experiences.flat();
    let skill = formValues.skills.flat();
    let endorsment = formValues.endorsements.flat();

    const saleChecks = saleMethology.filter((passedElement: unknown) => {
      return passedElement !== null && passedElement !== undefined;
    });
    const rolesChecks = roles.filter((passedElement: unknown) => {
      return passedElement !== null && passedElement !== undefined;
    });
    const stackChecks = stack_experience.filter((passedElement: unknown) => {
      return passedElement !== null && passedElement !== undefined;
    });
    const skillChecks = skill.filter((passedElement: unknown) => {
      return passedElement !== null && passedElement !== undefined;
    });

    const endoChecks = endorsment.filter((passedElement: unknown) => {
      return passedElement !== null && passedElement !== undefined;
    });

    var bodyFormData = new FormData();
    if (formValues.job_type === "sales") {
      bodyFormData.append("usual_sale_cycles", formValues.usual_sales_cycles);
      bodyFormData.append("largest_deal_won", formValues.largest_deal_won);
      saleChecks.forEach((items: any) =>
        bodyFormData.append("sales_methodologies", items)
      );
      rolesChecks.forEach((items: any) => bodyFormData.append("role", items));
    } else if (formValues.job_type === "development") {
      stackChecks.forEach((item: any) =>
        bodyFormData.append("stack_experiences", item)
      );
      rolesChecks.forEach((items: any) => bodyFormData.append("role", items));
      skillChecks.forEach((item: any) => bodyFormData.append("skills", item));
    } else if (formValues.job_type === "driver") {
      endoChecks.forEach((item: any) =>
        bodyFormData.append("endorsements", item)
      );
      bodyFormData.append("haul_type", formValues.haul_type);
      rolesChecks.forEach((items: any) => bodyFormData.append("role", items));
    }

    bodyFormData.append("title", formValues.title);
    bodyFormData.append("jobImage", formValues.jobImage);
    bodyFormData.append("job_type", formValues.job_type);
    bodyFormData.append("experience", formValues.experience);
    bodyFormData.append("description", formValues.description);
    bodyFormData.append("job_location", formValues.job_location);
    if (formValues.job_location === "Remote") {
      delete formValues["state"];
    } else {
      bodyFormData.append("state", formValues.state);
    }
    bodyFormData.append("application_link", formValues.application_link);

    const results: any = await AddPostJob(bodyFormData);
    if (results?.payload?.status) {
      toastBuilder("success", "Job Created Successfully");
      set_active_model(models.CLEAR);
      setSubmitting(false);
      getAllRecruiterJobs();
      resetForm();
    }
  };

  const toggleStateClick = () => {
    setStateUserSelect(true);
  };
  const toggleSalesClick = () => {
    setDevelopmentUserSelect(false);
    setSelectDriverUserSelect(false);
    setSelectUserSelect(true);
  };
  const toggleDevelopmentClick = () => {
    setSelectUserSelect(false);
    setSelectDriverUserSelect(false);
    setDevelopmentUserSelect(true);
  };
  const toggleDriverClick = () => {
    setSelectUserSelect(false);
    setDevelopmentUserSelect(false);
    setSelectDriverUserSelect(true);
  };

  const imageUpload = () => {
    document
      ?.getElementById("broadcast-img")
      ?.addEventListener("change", (event: any) => {
        let name = event.target.files[0].name;
        if (imageRef.current !== null) {
          imageRef.current.innerText = name;
        }
      });
  };
  return (
    <>
      <div className="create-broadcast">
        <div className="broadcast-header">
          <h5>Create a Job</h5>
          <i
            onClick={() => set_active_model(models.CLEAR)}
            className="fa-solid fa-x"
          ></i>
        </div>
        <div className="broadcast-body">
          <Formik
            // validationSchema={
            //   selectSalesUser ? SalePostJobSchema : DevelopmentPostJobSchema
            // }
            validationSchema={
              selectDriverUser
                ? DriverPostJobSchema
                : selectSalesUser
                ? SalePostJobSchema
                : selectDevelopmentUser
                ? DevelopmentPostJobSchema
                : PostJobSchema
            }
            onSubmit={handleSubmit}
            initialValues={initialValues}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
              touched,
              errors,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="input-fields-area mt-0">
                    <div className="fields-wrapper">
                      <div className="form-group">
                        <div className="detail-field-row">
                          <label>Job Type: </label>
                          <div className="detail-info">
                            <span className="mr-3">
                              <label>
                                <Field
                                  type="radio"
                                  name="job_type"
                                  value="driver"
                                  id="driver"
                                  onClick={toggleDriverClick}
                                />
                                <label htmlFor="driver">Driver</label>
                              </label>
                            </span>
                            <span className="mr-3">
                              <label>
                                <Field
                                  type="radio"
                                  name="job_type"
                                  value="sales"
                                  id="sales"
                                  onClick={toggleSalesClick}
                                />
                                <label htmlFor="sales">Sales</label>
                              </label>
                            </span>
                            <span className="mr-3">
                              <label>
                                <Field
                                  type="radio"
                                  name="job_type"
                                  value="development"
                                  id="development"
                                  onClick={toggleDevelopmentClick}
                                />
                                <label htmlFor="development">Development</label>
                              </label>
                            </span>
                            <span className="errorMsg">
                              {touched.job_type && errors.job_type}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fields-wrapper">
                      <div className="form-group w-100">
                        <label>Job Title: </label>
                        <div className="form-field ">
                          <Field
                            type="text"
                            name="title"
                            placeholder="Job Title"
                          />
                        </div>
                        <span className="errorMsg">
                          {touched.title && errors.title}
                        </span>
                      </div>
                    </div>
                    <div className="fields-wrapper">
                      <div className="form-group w-100">
                        <label>Description: </label>
                        <div className="form-field">
                          <Field
                            className="w-100"
                            as="textarea"
                            rows="3"
                            name="description"
                            placeholder="Pleace Enter Description"
                          />
                        </div>
                        <span className="errorMsg">
                          {touched.description && errors.description}
                        </span>
                      </div>
                    </div>
                    <div className="fields-wrapper">
                      <div className="form-group">
                        <div className="detail-field-row">
                          <label>Job Location: </label>
                          <div className="detail-info">
                            <span className="mr-3">
                              <label>
                                <Field
                                  type="radio"
                                  name="job_location"
                                  value="Remote"
                                  id="remote"
                                  onClick={() => setStateUserSelect(false)}
                                />
                                <label htmlFor="remote">Remote</label>
                              </label>
                            </span>
                            <span className="mr-3">
                              <label>
                                <Field
                                  type="radio"
                                  name="job_location"
                                  value="State/City"
                                  id="state/city"
                                  onClick={toggleStateClick}
                                />
                                <label htmlFor="state/city">State/City</label>
                              </label>
                            </span>
                            <span className="mr-3">
                              <label>
                                <Field
                                  type="radio"
                                  name="job_location"
                                  value="Hybrid"
                                  id="hybrid"
                                  onClick={toggleStateClick}
                                />
                                <label htmlFor="hybrid">Hybrid</label>
                              </label>
                            </span>
                            <span className="errorMsg">
                              {touched.job_location && errors.job_location}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fields-wrapper">
                      <div className="form-group">
                        <label>Application Link: </label>
                        <div className="form-field ">
                          <Field
                            type="text"
                            name="application_link"
                            placeholder="Application Link"
                          />
                        </div>
                      </div>
                      {selectDriverUser && (
                        <div className="form-group">
                          <label>Years Experience</label>
                          <div className="form-field">
                            <select
                              onChange={handleChange}
                              value={values.experience}
                              onBlur={handleBlur}
                              name="experience"
                            >
                              {SharedData.DriveryearsExperience.map(
                                (curElem) => {
                                  return (
                                    <option
                                      key={curElem.value}
                                      value={curElem.value}
                                    >
                                      {curElem.text}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                          <span className="errorMsg">
                            {touched.experience && errors.experience}
                          </span>
                        </div>
                      )}
                      {selectDevelopmentUser && (
                        <div className="form-group">
                          <label>Years Experience</label>
                          <div className="form-field">
                            <select
                              onChange={handleChange}
                              value={values.experience}
                              onBlur={handleBlur}
                              name="experience"
                            >
                              {SharedData.DevelopmentyearsExperience.map(
                                (curElem) => {
                                  return (
                                    <option
                                      key={curElem.value}
                                      value={curElem.value}
                                    >
                                      {curElem.text}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                          <span className="errorMsg">
                            {touched.experience && errors.experience}
                          </span>
                        </div>
                      )}
                      {selectSalesUser && (
                        <div className="form-group">
                          <label>Usual Sales Cycles:</label>
                          <div className="form-field">
                            <select
                              onChange={handleChange}
                              value={values.usual_sales_cycles}
                              onBlur={handleBlur}
                              name="usual_sales_cycles"
                            >
                              {SharedData.UsualSales.map((curElem) => {
                                return (
                                  <option
                                    key={curElem.value}
                                    value={curElem.value}
                                  >
                                    {curElem.text}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <span className="errorMsg">
                            {touched.usual_sales_cycles &&
                              errors.usual_sales_cycles}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="fields-wrapper">
                      {selectStateUser && (
                        <>
                          <div className="form-group">
                            <label>State:</label>
                            <div className="form-field">
                              <select
                                onChange={handleChange}
                                value={values.state}
                                onBlur={handleBlur}
                                name="state"
                              >
                                {States.map((curElem, index) => {
                                  return (
                                    <option value={curElem} key={index}>
                                      {curElem}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <span className="errorMsg">
                              {touched.state && errors.state}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                    {selectSalesUser && (
                      <>
                        <div className="fields-wrapper">
                          <div className="form-group">
                            <label>Years Experience</label>
                            <div className="form-field">
                              <select
                                onChange={handleChange}
                                value={values.experience}
                                onBlur={handleBlur}
                                name="experience"
                              >
                                {SharedData.yearsExperience.map((curElem) => {
                                  return (
                                    <option
                                      key={curElem.value}
                                      value={curElem.value}
                                    >
                                      {curElem.text}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <span className="errorMsg">
                              {touched.experience && errors.experience}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>Average Deal Size</label>
                            <div className="form-field">
                              <select
                                onChange={handleChange}
                                value={values.largest_deal_won}
                                onBlur={handleBlur}
                                name="largest_deal_won"
                              >
                                {SharedData.DealWon.map((curElem) => {
                                  return (
                                    <option
                                      key={curElem.value}
                                      value={curElem.value}
                                    >
                                      {curElem.text}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <span className="errorMsg">
                              {touched.largest_deal_won &&
                                errors.largest_deal_won}
                            </span>
                          </div>
                        </div>

                        <div className="driver-detail">
                          <div className="detail-field-row ">
                            <label>Sales Methodology: </label>
                            <div className="detail-info w-100">
                              {SharedData.Sales_Methodologies.map(
                                (Sales_Methodology: any, index: number) => {
                                  return (
                                    <span key={Sales_Methodology.value}>
                                      <div>
                                        <Field
                                          id={`sales_methodologies[${index}]`}
                                          name={`sales_methodologies[${index}]`}
                                          value={Sales_Methodology.value}
                                          type="checkbox"
                                          className="checkbox-custom"
                                        />
                                        <label
                                          htmlFor={`sales_methodologies[${index}]`}
                                          className="checkbox-custom-label"
                                        >
                                          {Sales_Methodology.label}
                                        </label>
                                      </div>
                                    </span>
                                  );
                                }
                              )}
                              <span className="errorMsg">
                                {touched.sales_methodologies &&
                                  errors.sales_methodologies}
                              </span>
                            </div>
                          </div>
                          <div className="detail-field-row ">
                            <label>Role: </label>
                            <div className="detail-info w-100">
                              {SharedData.Roles.map(
                                (Sales_Role: any, index: number) => {
                                  return (
                                    <span key={Sales_Role.value}>
                                      <div>
                                        <Field
                                          id={`role[${index}]`}
                                          name={`role[${index}]`}
                                          value={Sales_Role.value}
                                          type="checkbox"
                                          className="checkbox-custom"
                                        />
                                        <label
                                          htmlFor={`role[${index}]`}
                                          className="checkbox-custom-label"
                                        >
                                          {Sales_Role.label}
                                        </label>
                                      </div>
                                    </span>
                                  );
                                }
                              )}
                              <span className="errorMsg">
                                {touched.role && errors.role}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {selectDevelopmentUser && (
                      <>
                        <div className="driver-detail">
                          <div className="detail-field-row">
                            <label>Stack Experience: </label>
                            <div className="detail-info w-100">
                              {SharedData.StackExperience.map(
                                (stack_experience: any, index: number) => {
                                  return (
                                    <span key={stack_experience.value}>
                                      <div>
                                        <Field
                                          id={`stack_experiences[${index}]`}
                                          name={`stack_experiences[${index}]`}
                                          value={stack_experience.value}
                                          type="checkbox"
                                          className="checkbox-custom"
                                        />
                                        <label
                                          htmlFor={`stack_experiences[${index}]`}
                                          className="checkbox-custom-label"
                                        >
                                          {stack_experience.label}
                                        </label>
                                      </div>
                                    </span>
                                  );
                                }
                              )}
                              <span className="errorMsg">
                                {touched.stack_experiences &&
                                  errors.stack_experiences}
                              </span>
                            </div>
                          </div>
                          <div className="detail-field-row">
                            <label>Individual Skills: </label>
                            <div className="detail-info w-100 ">
                              {SharedData.IndividualSkills.map(
                                (individual_skills: any, index: number) => {
                                  return (
                                    <span key={individual_skills.value}>
                                      <div>
                                        <Field
                                          id={`skills[${index}]`}
                                          name={`skills[${index}]`}
                                          value={individual_skills.value}
                                          type="checkbox"
                                          className="checkbox-custom"
                                        />
                                        <label
                                          htmlFor={`skills[${index}]`}
                                          className="checkbox-custom-label"
                                        >
                                          {individual_skills.label}
                                        </label>
                                      </div>
                                    </span>
                                  );
                                }
                              )}
                              <span className="errorMsg">
                                {touched.skills && errors.skills}
                              </span>
                            </div>
                          </div>
                          <div className="detail-field-row">
                            <label>Role: </label>
                            <div className="detail-info w-100">
                              {SharedData.DevelopersRole.map(
                                (developersRole: any, index: number) => {
                                  return (
                                    <span key={developersRole.value}>
                                      <div>
                                        <Field
                                          id={`role[${index}]`}
                                          name={`role[${index}]`}
                                          value={developersRole.value}
                                          type="checkbox"
                                          className="checkbox-custom"
                                        />
                                        <label
                                          htmlFor={`role[${index}]`}
                                          className="checkbox-custom-label"
                                        >
                                          {developersRole.label}
                                        </label>
                                      </div>
                                    </span>
                                  );
                                }
                              )}
                              <span className="errorMsg">
                                {touched.role && errors.role}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {selectDriverUser && (
                      <>
                        <div className="driver-detail">
                          <div className="detail-field-row">
                            <label>Endorsements: </label>
                            <div className="detail-info w-100">
                              {SharedData.Endorsement.map(
                                (endorsement: any, index: number) => {
                                  return (
                                    <span key={endorsement.value}>
                                      <div>
                                        <Field
                                          id={`endorsements[${index}]`}
                                          name={`endorsements[${index}]`}
                                          value={endorsement.value}
                                          type="checkbox"
                                          className="checkbox-custom"
                                        />
                                        <label
                                          htmlFor={`endorsements[${index}]`}
                                          className="checkbox-custom-label"
                                        >
                                          {endorsement.label}
                                        </label>
                                      </div>
                                    </span>
                                  );
                                }
                              )}
                              <span className="errorMsg">
                                {touched.endorsements && errors.endorsements}
                              </span>
                            </div>
                          </div>
                          <div className="detail-field-row">
                            <label>Role: </label>
                            <div className="detail-info w-100">
                              {SharedData.DriversRole.map(
                                (driverRole: any, index: number) => {
                                  return (
                                    <span key={driverRole.value}>
                                      <div>
                                        <Field
                                          id={`role[${index}]`}
                                          name={`role[${index}]`}
                                          value={driverRole.value}
                                          type="checkbox"
                                          className="checkbox-custom"
                                        />
                                        <label
                                          htmlFor={`role[${index}]`}
                                          className="checkbox-custom-label"
                                        >
                                          {driverRole.label}
                                        </label>
                                      </div>
                                    </span>
                                  );
                                }
                              )}
                              <span className="errorMsg">
                                {touched.role && errors.role}
                              </span>
                            </div>
                          </div>

                          <div className="detail-field-row">
                            <label>Haul Type: </label>
                            <div className="detail-info">
                              {SharedData.Haul_Type.map((hualType) => {
                                return (
                                  <span key={hualType.value}>
                                    <label>
                                      <Field
                                        type="radio"
                                        value={hualType.value}
                                        name="haul_type"
                                        id={hualType.value}
                                      />
                                      <label htmlFor={hualType.value}>
                                        {hualType.label}
                                      </label>
                                    </label>
                                  </span>
                                );
                              })}
                              <span className="errorMsg">
                                {touched.haul_type && errors.haul_type}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="broadcast-footer">
                    <div>
                      <input
                        onClick={() => imageUpload()}
                        type="file"
                        name="jobImage"
                        placeholder=""
                        id="broadcast-img"
                        accept="image/png, image/jpeg, image/gif, image/heic, .heic"
                        onChange={(e: any) => {
                          setFieldValue("jobImage", e.target.files[0]);
                        }}
                      />
                      <label
                        className="for-sm-input-file"
                        htmlFor="broadcast-img"
                      >
                        <img src={mountIcon} alt="" />
                      </label>
                      <span id="file-name" ref={imageRef}>
                        Choose a File
                      </span>
                    </div>
                    <span className="errorMsg">
                      {touched.jobImage && errors.jobImage}
                    </span>
                    <button type="submit" className="btn btn-medium btn-purple">
                      Create Job
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className="bg-overlay-fixed"></div>
    </>
  );
};

export default CreateBroadCastModel;
