import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { useActions } from "src/actions";
import { State } from "country-state-city";
import { useAppSelector } from "src/store";
import toastBuilder from "src/utils/toastBuilder";
import userAvatar from "../../assets/images/user-avatar.png";
import { ProfileTabValidation } from "src/validation/profile";
import { selectUser } from "src/container/auth/auth.selector";

const ProfileTab: React.FC<{}> = (props) => {
  const currentUser = useAppSelector(selectUser);
  const { updateUserData, UploadImageAction, getProfileData } = useActions();

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    setstate(currentUser);
  }, [currentUser]);

  const initalValues = {
    first_name: "",
    last_name: "",
    email: "",
    state: "",
    city: "",
    zip_code: "",
    avatar: "",
  };

  const [inistate, setstate] = useState(initalValues);

  const States = State.getStatesOfCountry("US").map((curElem) => {
    return curElem.name;
  });

  const handleSubmit = async (formValues: any, { setSubmitting }: any) => {
    const result: any = await updateUserData(formValues);
    if (result.payload.status) {
      setSubmitting(false);
      toastBuilder("success", "profile update successfully");
    }
  };

  const handleuploadprofileimage = (event: any) => {
    const file = event.target.files[0];

    if (file !== undefined && file !== null) {
      var bodyFormData = new FormData();
      bodyFormData.append("image", file);
      UploadImageAction(bodyFormData);
    }
  };


  return (
    <div className="right-tabs">
      <div className="input-fields-area">
        <div className="profilepic">
          <img
            className="avatar rounded-circle"
            src={currentUser?.avatar || userAvatar}
            alt="Profile"
          />
          <label htmlFor="file-upload" className="fileuplaod">
            <i className="fas fa-camera camera pointer"></i>
            <input
              id="file-upload"
              type="file"
              accept="image/png, image/jpeg, image/gif, image/heic, .heic"
              name="avatar"
              onChange={handleuploadprofileimage}
              style={{ opacity: 0 }}
            />
          </label>
        </div>
        <Formik
          validationSchema={ProfileTabValidation}
          onSubmit={handleSubmit}
          initialValues={inistate}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="fields-wrapper">
                  <div className="form-group">
                    <div className="form-field">
                      <Field
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                      />
                    </div>
                    <span className="errorMsg">
                      {touched.first_name && errors.first_name}
                    </span>
                  </div>
                  <div className="form-group">
                    <div className="form-field">
                      <Field
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                      />
                    </div>
                    <span className="errorMsg">
                      {touched.last_name && errors.last_name}
                    </span>
                  </div>
                </div>

                <div className="fields-wrapper">
                  <div className="form-group">
                    <div className="form-field">
                      <Field type="text" name="city" placeholder="City" />
                    </div>
                    <span className="errorMsg">
                      {touched.city && errors.city}
                    </span>
                  </div>
                  <div className="form-group">
                    <div className="form-field">
                      <select
                        className="px-0"
                        onChange={handleChange}
                        value={values.state}
                        onBlur={handleBlur}
                        name="state"
                      >
                        {States.map((curElem, index) => {
                          return (
                            <option value={curElem} key={index}>
                              {curElem}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <span className="errorMsg">
                      {touched.state && errors.state}
                    </span>
                  </div>
                </div>

                <div className="fields-wrapper">
                  <div className="form-group">
                    <div className="form-field">
                      <Field
                        type="text"
                        name="zip_code"
                        placeholder="Zip Code"
                      />
                    </div>
                    <span className="errorMsg">
                      {touched.zip_code && errors.zip_code}
                    </span>
                  </div>
                  <div className="form-group">
                    <div className="form-field">
                      <Field type="email" name="email" placeholder="Email" />
                    </div>
                    <span className="errorMsg">
                      {touched.email && errors.email}
                    </span>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-medium btn-purple mt-4"
                >
                  Update
                </button>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ProfileTab;
