import React, { useEffect } from "react";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import Header from "src/components/Header";
import { useParams } from "react-router-dom";
import { GrDocumentDownload } from "react-icons/gr";
import userAvatar from "../../assets/images/user-avatar.png";
import { allJobDetails } from "src/container/PostJob/PostJob.selector";
/* @ts-ignore */
import Linkify from "react-linkify";
const JobsDetail: React.FC<{}> = (props) => {
  const job_id = useParams();
  const { getJobDetails } = useActions();
  const JobDetail = useAppSelector(allJobDetails);
  // const object = JobDetail?.broadcasts;
  // var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  // const urls = object?.map((value: any) => {
  //   return {
  //     value: value?.message?.match(urlRegex)?.toString(),
  //     id: value._id,
  //   };
  // });
  // const endoChecks = urls?.filter((passedElement: any) => {
  //   return passedElement.value !== null || passedElement.value !== undefined;
  // });
  // const Objects = endoChecks?.map((curElem: any) => {
  //   return {
  //     url: curElem?.value?.replace("watch?v=", "embed/"),
  //     id: curElem.id,
  //   };
  // });

  useEffect(() => {
    getJobDetails(job_id);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header />
      <section className="home-page">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-7 col-12">
              <div className="right-post-side">
                <div className="job-desc">
                  <div>
                    <img
                      className="user-avatar"
                      src={JobDetail?.recruiter_avatar}
                      alt=""
                    />
                  </div>
                  <div className="d-flex justify-content-between align-content-center">
                    <div>
                      <h5 className="user--name">
                        {JobDetail?.recruiter_first_name}{" "}
                        {JobDetail?.recruiter_last_name}
                      </h5>
                      <p className="user--country">
                        {JobDetail?.recruiter_state},{" "}
                        {JobDetail?.recruiter_city}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <div className="job--details">
                    <div className="card user-card-full">
                      <div className="card-block">
                        <h5 className="mb-3 pb-2 b-b-default">Information</h5>
                        <div className="row">
                          <div className="col-sm-4">
                            <h6>Job Title</h6>
                            <p>{JobDetail?.job_title}</p>
                          </div>

                          <div className="col-sm-4">
                            <h6>Location</h6>
                            <p>{JobDetail?.job_location}</p>
                          </div>

                          <div className="col-sm-4">
                            <h6>Job Type</h6>
                            <p>{JobDetail?.job_type}</p>
                          </div>
                          {JobDetail?.desires?.state && (
                            <div className="col-sm-4">
                              <h6>State</h6>
                              <p>{JobDetail?.desires?.state}</p>
                            </div>
                          )}
                        </div>

                        <h5 className="my-3 pb-2 b-b-default">Desires</h5>
                        <div className="row emp_history">
                          {JobDetail?.desires?.largest_deal_won?.length && (
                            <div className="col-sm-6 ">
                              <h6>Average Deal Size:</h6>
                              <p>{JobDetail?.desires?.largest_deal_won}</p>
                            </div>
                          )}

                          {JobDetail?.desires?.experience && (
                            <div className="col-sm-6 ">
                              <h6>Experience:</h6>
                              <p>{JobDetail?.desires?.experience}</p>
                            </div>
                          )}

                          {JobDetail?.desires?.endorsements?.length > 0 && (
                            <div className="col-sm-6">
                              <h6>Endorsements:</h6>
                              {JobDetail?.desires?.endorsements?.map(
                                (el: any) => {
                                  return <p>{el}</p>;
                                }
                              )}
                            </div>
                          )}

                          {JobDetail?.desires?.sales_methodologies?.length >
                            0 && (
                            <div className="col-sm-6">
                              <h6>Sales Methodologies:</h6>
                              <div className="desires-grid">
                                {JobDetail?.desires?.sales_methodologies?.map(
                                  (el: any) => {
                                    return <p>{el}</p>;
                                  }
                                )}
                              </div>
                            </div>
                          )}
                          {JobDetail?.desires?.usual_sale_cycles?.length >
                            0 && (
                            <div className="col-sm-6 ">
                              <h6>Usual Sale Cycles:</h6>
                              {JobDetail?.desires?.usual_sale_cycles?.map(
                                (el: any) => {
                                  return <p> {el} </p>;
                                }
                              )}
                            </div>
                          )}
                          {JobDetail?.desires?.role?.length > 0 && (
                            <div className="col-sm-6">
                              <h6>Role:</h6>
                              <div className="desires-grid">
                                {JobDetail?.desires?.role?.map((el: any) => {
                                  return <p>{el}</p>;
                                })}
                              </div>
                            </div>
                          )}

                          {JobDetail?.desires?.stack_experiences?.length >
                            0 && (
                            <div className="col-sm-6 ">
                              <h6>Stack Experiences:</h6>
                              {JobDetail?.desires?.stack_experiences?.map(
                                (el: any) => {
                                  return <p>{el}</p>;
                                }
                              )}
                            </div>
                          )}

                          {JobDetail?.desires?.skills?.length > 0 && (
                            <div className="col-sm-6 ">
                              <h6>Skills:</h6>
                              {JobDetail?.desires?.skills?.map((el: any) => {
                                return <p>{el}</p>;
                              })}
                            </div>
                          )}
                        </div>

                        <div>
                          <h5 className="user--home mt-5">Description:</h5>
                          <p className="user--country">
                            {JobDetail?.description}
                          </p>
                        </div>
                        <img src={JobDetail?.job_image_url} alt="" />
                      </div>

                      <div className="text-right">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://${JobDetail?.application_link}`}
                          className="btn btn-medium btn-purple b-radius-10"
                        >
                          Apply
                        </a>
                      </div>

                      {JobDetail?.broadcasts?.length > 0 && (
                        <h5 className="user--home mt-3">BroadCast:</h5>
                      )}

                      {JobDetail?.broadcasts?.map((broadcast: any) => {
                        return (
                          <div className="user-jobs-box">
                            <img src={broadcast?.avatar || userAvatar} alt="" />
                            <div className="w-100">
                              <div className="user_name">
                                <h5>
                                  {`${broadcast.first_name} ${broadcast.last_name}`}
                                </h5>
                              </div>
                              <div className="broadcast-preview">
                                <p>
                                  <Linkify
                                    componentDecorator={(
                                      decoratedHref: any,
                                      decoratedText: any,
                                      key: any
                                    ) => (
                                      <a
                                        target="blank"
                                        href={decoratedHref}
                                        key={key}
                                      >
                                        {decoratedText}
                                      </a>
                                    )}
                                  >
                                    {broadcast.message}
                                  </Linkify>
                                </p>
                                {broadcast?.link_data ? (
                                  <>
                                    {broadcast?.link_data.map((elem: any) => {
                                      return (
                                        <div>
                                          <a
                                            href={elem?.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              className="preview-image"
                                              alt=""
                                              src={elem?.image}
                                            />
                                          </a>
                                          <span>{elem?.title}</span>
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  ""
                                )}

                                {/* {Objects.map((el: any) => {
                                  return (
                                    el?.id === broadcast._id &&
                                    el?.url && (
                                      <object data={el.url} type="text/html">
                                        <a
                                          href={el.url}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img
                                            className="m-0"
                                            alt=""
                                            src="https://zuzu-project.s3.amazonaws.com/static+images/preview-not-available.png"
                                          />
                                        </a>
                                      </object>
                                    )
                                  );
                                })}
                              */}

                                {broadcast?.images.length > 0 && (
                                  <div className="message-images">
                                    {broadcast?.images.map((elem: any) => {
                                      return (
                                        <a
                                          href={elem}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <img src={elem} alt="" />
                                        </a>
                                      );
                                    })}
                                  </div>
                                )}

                                {broadcast?.documents.length > 0 && (
                                  <div className="documents-wrapper">
                                    {broadcast?.documents.map((elem: any) => {
                                      return (
                                        <a href={elem?.file_url} download>
                                          <div>
                                            <i>
                                              <GrDocumentDownload />
                                            </i>
                                            <label>{elem?.file_name}</label>
                                          </div>
                                        </a>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobsDetail;
