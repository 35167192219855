import React, { useRef, useState } from "react";
import { models } from "src/enums";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { IoCloseCircleOutline } from "react-icons/io5";
import { getTribeId } from "../../../container/Tribe/Tribe.selector";
import { getTribeDataSelector } from "../../../container/Tribe/Tribe.selector";
import { getTribeDetailMemberSelector } from "../../../container/Tribe/Tribe.selector";

const Descriptionpopup: React.FC<{}> = () => {
  let user: any = window.localStorage.getItem("user");
  const parseduser = JSON.parse(user);
  const ref = useRef<HTMLDivElement>(null);
  const tribe_id = useAppSelector(getTribeId);
  const TribeData = useAppSelector(getTribeDataSelector);
  const TribeMembers = useAppSelector(getTribeDetailMemberSelector);
  const [userData, setUserData] = useState(TribeData.tribeDescription);
  const { set_active_model, editTribe, getAllDiscussion } = useActions();

  const EditDescription = () => {
    const payload = {
      description: userData,
      tribe_id: tribe_id,
    };
    editTribe(payload);
    getAllDiscussion(tribe_id);
    set_active_model(models.CLEAR);
  };

  return (
    <>
      <div
        ref={ref}
        className="sidebar center-popup tribe-popup description-popup"
      >
        <div className="model-info justify-content-center">
          <div
            className="close-icon"
            onClick={() => set_active_model(models.CLEAR)}
          >
            <IoCloseCircleOutline />
          </div>
          <h3 className="mb-3">Description</h3>
          {!parseduser ? (
            <p>{userData}</p>
          ) : (
            <div className="form-outline w-100 mb-3">
              <textarea
                className="form-control "
                rows={4}
                id="textAreaExample1"
                value={userData}
                onChange={(e) => setUserData(e.target.value)}
              ></textarea>
            </div>
          )}

          {TribeMembers.map((el: any, index: any) => {
            return (
              el.admin &&
              el._id === parseduser._id && (
                <button
                  type="submit"
                  className="btn btn-medium btn-purple mt-1"
                  onClick={() => EditDescription()}
                >
                  Edit Description
                </button>
              )
            );
          })}
        </div>
      </div>
      <div className="bg-overlay bg-fixed"></div>
    </>
  );
};

export default Descriptionpopup;
