import { createSlice } from "@reduxjs/toolkit";
import { createReducerBuilder } from "src/utils/actions";
import { getHeader, getAllNotifications } from "./notification.actions";

const initialState: any = {
  email: "",
  notification: "",
  allNotificationsMsg: [],
};
  
const reducerBuilder = createReducerBuilder<any>();

/* @ts-ignore */
const headerNotification = reducerBuilder(getHeader, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.status) {
      state.email = payload.data.unread_message_count;
      state.notification = payload.data.unread_notification_count;
    }
  },
});

/* @ts-ignore */
const notificationMessages = reducerBuilder(getAllNotifications, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.status) {
      state.allNotificationsMsg = payload.data;
    }
  },
});

const headerSlice = createSlice({
  name: "headerNotify",
  initialState,
  reducers: {
    set_socket_receive_data: (state, action) => {
      state.email = action.payload.unread_message_count;
    },

    set_socket_notify_count: (state, action) => {
      state.notification = action.payload.unread_notification_count;
    },
  },

  extraReducers: (builder) => {
    headerNotification(builder);
    notificationMessages(builder);
  },
});

export const { set_socket_receive_data, set_socket_notify_count } = headerSlice.actions;
  
export default headerSlice.reducer;
