import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import type { AppDispatch } from "./store";
import * as authActions from "./container/auth/auth.actions";
import { set_active_model } from "./container/model/model.slice";
import * as tribeActions from "./container/Tribe/Tribe.actions";
import * as driverActions from "./container/driver/driver.actions";
import * as messageActions from "./container/message/message.actions";
import * as postJobActions from "./container/PostJob/PostJob.actions";
import * as recruiterActions from "./container/recruiter/recruiter.actions";
import * as notificationActions from "./container/notification/notification.actions";
import * as salesActions from "./container/sales/sales.actions"
import * as developmentActions from "./container/development/development.actions"

export const useActions = () => {
  const dispatch = useDispatch<AppDispatch>();

  return bindActionCreators(
    { ...authActions, ...driverActions, ...recruiterActions, ...notificationActions, ...messageActions , ...postJobActions, ...tribeActions,...salesActions, ...developmentActions, set_active_model },
    dispatch
  );
};
