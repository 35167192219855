import Header from "src/components/Header";
import ViewDriverDetails from "src/components/UserPost/ViewDriverDetails";

const ViewDriverDetailsPage: React.FC<{}> = (props) => (
  <>
    <Header />
    <section className="home-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ViewDriverDetails />
          </div>
        </div>
      </div>
    </section>
  </>
);

export default ViewDriverDetailsPage;
