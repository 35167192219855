import React, { useEffect } from "react";
import { store } from "src/store";
import { Link } from "react-router-dom";
import { useActions } from "src/actions";
import { models, Roles } from "src/enums";
import { useAppSelector } from "src/store";
import { useNavigate } from "react-router-dom";
import toastBuilder from "src/utils/toastBuilder";
import { selectUser } from "src/container/auth/auth.selector";
import { storeJobId } from "src/container/PostJob/PostJob.slice";
import { showJobsFilter } from "src/container/PostJob/PostJob.slice";
import {
  allRecruiterJobs,
  allAvailDriverJobs,
} from "src/container/PostJob/PostJob.selector";
import JobBroadCast from "./JobBroadCast";

const UserPost: React.FC<{}> = (props) => {
  const {
    followJob,
    set_active_model,
    getAllDriverJobs,
    getJobBidsDetails,
    getAllRecruiterJobs,
    getProfileData,
    getAllBroadCast,
  } = useActions();
  const navigation = useNavigate();

  const selectCurrentuser = useAppSelector(selectUser);
  const recruiterAllJobs = useAppSelector(allRecruiterJobs);
  const driverAllJobs = useAppSelector(allAvailDriverJobs);
  const allPostedJob =
    selectCurrentuser.role === Roles.recruiter
      ? recruiterAllJobs
      : selectCurrentuser.role === Roles.driver ||
        selectCurrentuser.role === Roles.sales ||
        selectCurrentuser.role === Roles.development
      ? driverAllJobs
      : "";

  useEffect(() => {
    selectCurrentuser.role === Roles.recruiter && getProfileData();
    selectCurrentuser.role === Roles.driver && getProfileData();
    selectCurrentuser.role === Roles.recruiter && getAllRecruiterJobs();
    selectCurrentuser.role === Roles.driver && getAllDriverJobs();
    selectCurrentuser.role === Roles.sales && getAllDriverJobs();
    selectCurrentuser.role === Roles.development && getAllDriverJobs();
    store.dispatch(showJobsFilter(true));
    getAllBroadCast();
  }, []);

  useEffect(() => {
    return () => {
      store.dispatch(showJobsFilter(false));
    };
  }, []);

  const handleFollowJob = async (postedJobId: any) => {
    const results: any = await followJob({ job_id: postedJobId });
    if (results?.payload?.status) {
      toastBuilder("success", "Applied Sucessfully");
      getAllDriverJobs();
    }
  };

  const viewAllRequests = async (jobId: any) => {
    const results: any = await getJobBidsDetails({ job_id: jobId });
    if (results?.payload?.status) {
      navigation(`/view-request/${jobId}`);
    }
  };

  const handleJobId = (job_id: any) => {
    store.dispatch(storeJobId(job_id));
  };

  return (
    <div className="right-post-side m-0">
      <div className="d-flex justify-content-center mb-3 ">
        {selectCurrentuser.role === Roles.recruiter && (
          <button
            className="btn btn-medium btn-purple"
            onClick={() => set_active_model(models.BROADCAST)}
          >
            Create New Job
          </button>
        )}

      </div>
      <>
        {selectCurrentuser.role === Roles.recruiter ? (
          allPostedJob.length === 0 ? (
            <div className="no-story-text">
              <h2>No Jobs found...</h2>
            </div>
          ) : (
            allPostedJob.map((elem: any, index: number) => {
              return (
                <div className="news-item news-border">
                  <div className="news-text-box  ">
                    <div className="job-desc" key={index}>
                      <div>
                      </div>
                      <div className="w-100">
                        <h5 className="user--name">{elem?.job_title}</h5>
                        <div className="d-flex justify-content-between align-content-center">
                          <p className="user--country">{elem?.job_location}</p>
                          {elem?.job_type === "sales" ? (
                            <p className="user--country">Sale Job</p>
                          ) : elem?.job_type === "driver" ? (
                            <p className="user--country">Driver Job</p>
                          ) : elem?.job_type === "development" ? (
                            <p className="user--country">Developer Job</p>
                          ) : (
                            ""
                          )}

                        </div>

                        <div className="job-apply ">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="user--country">
                            </p>
                          </div>
                          <p className="user--country">{elem?.description}</p>
                          {selectCurrentuser.role === Roles.recruiter && (
                            <>
                              <button
                                className="btn btn-medium btn-purple"
                                onClick={() => viewAllRequests(elem._id)}
                                disabled={elem?.no_of_users_applied === 0}
                              >
                                View Requests{" "}
                                {elem?.no_of_users_applied > 0 &&
                                  `( ${elem?.no_of_users_applied} )`}
                              </button>
                              <button
                                className="btn btn-medium btn-purple user-post-button"
                                onClick={() => {
                                  set_active_model(models.BROADCAST_MESSAGE);
                                  handleJobId(elem._id);
                                }}
                              >
                                BroadCast
                              </button>
                            </>
                          )}

                          {selectCurrentuser.role !== Roles.recruiter && (
                            <>
                              <button
                                className={`btn btn-medium btn-purple user-post-button ${
                                  elem.applied ? "active" : null
                                }`}
                                onClick={() => handleFollowJob(elem?._id)}
                              >
                                Follow
                              </button>
                            </>
                          )}

                          <Link to={`/jobDetail/${elem?._id}`}>
                            <button className="btn btn-medium btn-purple user-post-button">
                              See Details
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )
        ) : (
          <JobBroadCast />
        )}
      </>
    </div>
  );
};

export default UserPost;
