import React, { useEffect } from "react";
import { Roles } from "src/enums";
import { store } from "../../store";
import { Link } from "react-router-dom";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import userIcon from "src/assets/images/icons/user-icon.svg";
import calendarIcon from "src/assets/images/icons/calendar-icon.svg";
import cardIcon from "src/assets/images/icons/card-icon.svg";
import cvvIcon from "src/assets/images/icons/cvv-icon.svg";
import Header from "src/components/Header";
import DevelopmentInfoForm from "src/components/UserPost/DevelopmentInfoForm";
import UserCard from "src/components/Shared/UserCard";
import { selectUser } from "src/container/auth/auth.selector";
import { driversNumberCount } from "../recruiter/recruiter.selector";
import { getFiltersData } from "src/container/recruiter/recruiter.slice";
import SalesInfoForm from "src/components/UserPost/SalesInfoForm";
import DriverInfoForm from "src/components/UserPost/DriverInfoForm";
import RecruiterInfoForm from "src/components/UserPost/RecruiterInfoForm";
import { useNavigate } from "react-router-dom";

const ProfilePage: React.FC<{}> = (props) => {
  const { getNumberOfEngagedUserCount } = useActions();
  const currentUser = useAppSelector(selectUser);
  const selectCurrentuser = useAppSelector(selectUser);
  const noHiredDrivers = useAppSelector(driversNumberCount);

  const navigation = useNavigate();
  useEffect(() => {
    getNumberOfEngagedUserCount();
    store.dispatch(getFiltersData({ filters: null }));
  }, []);

  return (
    <>
      <Header />
      <section className="home-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5 offset-md-0 col-10 offset-1">
              <UserCard />
              {(currentUser?.role === Roles.driver ||
                currentUser?.role === Roles.sales ||
                currentUser?.role === Roles.development) &&
                noHiredDrivers > 0 && (
                  <Link to="/engaged-recruiters" className="hired-drivers">
                    <p>Engaged Recruiters</p>
                    <p>{`(${noHiredDrivers})`}</p>
                  </Link>
                )}
              {currentUser?.role === Roles.recruiter && noHiredDrivers > 0 && (
                <Link to="/engaged-applicants" className="hired-drivers">
                  <p>Engaged Applicants</p>
                  <p>{`(${noHiredDrivers})`}</p>
                </Link>
              )}
            </div>
            <div className="col-lg-8 col-md-7 col-12">
              {selectCurrentuser.role === Roles.sales ? (
                <>
                  <SalesInfoForm />
                </>
              ) : selectCurrentuser.role === Roles.development ? (
                <>
                  <DevelopmentInfoForm />
                </>
              ) : selectCurrentuser.role === Roles.recruiter ? (
                <>
                  <RecruiterInfoForm />
                </>
              ) : selectCurrentuser.role === Roles.driver ? (
                <>
                  <DriverInfoForm />
                </>
              ) : (
                ""
              )}
              {selectCurrentuser.role === Roles.recruiter &&
              currentUser?.defaultCard !== null ? (
                <>
                  <div className="input-fields-area contact-form ml-2">
                    <div className="fields-wrapper mt-4">
                      <div className="form-group">
                        <h4>Credit Card Info</h4>
                      </div>
                    </div>

                    <div className="fields-wrapper ">
                      <div className="form-group">
                        <label>Card Number</label>
                        <div className="form-field">
                          <img src={cardIcon} alt="" />
                          <input
                            name="card_number"
                            type="text"
                            placeholder=""
                            disabled
                            value={
                              currentUser?.defaultCard?.last4
                                ? `xxxx xxxx xxxx ${currentUser?.defaultCard?.last4}`
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Expiry month</label>
                        <div className="form-field">
                          <img src={calendarIcon} alt="" />
                          <input
                            type="number"
                            name="exp_month"
                            placeholder=""
                            disabled
                            value={currentUser?.defaultCard?.exp_month}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fields-wrapper ">
                      <div className="form-group">
                        <label>Expiry Year</label>
                        <div className="form-field">
                          <img src={calendarIcon} alt="" />
                          <input
                            type="number"
                            name="exp_year"
                            placeholder=""
                            disabled
                            value={currentUser?.defaultCard?.exp_year}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-right mt-3">
                      <button
                        type="submit"
                        className="btn btn-medium btn-purple"
                        onClick={() => navigation("/payment-methods")}
                      >
                        Edit Card Info
                      </button>
                    </div>
                  </div>
                </>
              ) : selectCurrentuser.role === Roles.recruiter &&
                currentUser?.defaultCard === null ? (
                <div className="fields-wrapper mt-4">
                  <div className="form-group">
                    <h4>Financial Info</h4>
                  </div>
                  <div
                    className="form-group fin-info"
                    onClick={() => navigation("/payment-methods")}
                  >
                    <h6>Please Add Your Credit Card Details</h6>
                  </div>
                </div>
              ) : (
                ""
              )}

              {(selectCurrentuser.role === Roles.driver ||
                selectCurrentuser.role === Roles.sales ||
                selectCurrentuser.role === Roles.development) &&
              currentUser?.userBankDetails !== null ? (
                <>
                  <div className="input-fields-area contact-form ml-2">
                    <div className="fields-wrapper mt-4">
                      <div className="form-group">
                        <h4>Bank Details Info</h4>
                      </div>
                    </div>

                    <div className="fields-wrapper">
                      {/* Account Holder Field */}
                      <div className="form-group">
                        <div className="form-field">
                          <img src={userIcon} alt="" />
                          <input
                            type="text"
                            disabled
                            name="account_holder_name"
                            placeholder="Enter Holder Name"
                            value={
                              currentUser?.userBankDetails?.account
                                ?.account_holder_name
                            }
                          />
                        </div>
                      </div>

                      {/* Account Number Field */}
                      <div className="form-group">
                        <div className="form-field">
                          <img src={cvvIcon} alt="" />

                          <input
                            type="text"
                            name="account_number"
                            disabled
                            placeholder="Enter Account Number"
                            value={
                              currentUser?.userBankDetails?.account
                                ?.account_number
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="fields-wrapper">
                      {/* Rownting Number Field */}
                      <div className="form-group">
                        <div className="form-field">
                          <img src={cvvIcon} alt="" />

                          <input
                            type="text"
                            name="routing_number"
                            disabled
                            placeholder="Enter Routing Number"
                            value={
                              currentUser?.userBankDetails?.account
                                ?.routing_number
                            }
                          />
                        </div>
                      </div>

                      {/* Date of birth Field */}
                      <div className="form-group">
                        <div className="form-field">
                          <img src={calendarIcon} alt="" />

                          <input
                            type="text"
                            name="dob"
                            disabled
                            value={currentUser?.userBankDetails?.account?.dob}
                            placeholder="Enter Date of birth"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="fields-wrapper">
                      {/* SSN Field */}
                      <div className="form-group">
                        <div className="form-field">
                          <img src={cvvIcon} alt="" />

                          <input
                            type="number"
                            name="ssn"
                            disabled
                            placeholder="Enter SSN (Last 4)"
                            value={currentUser?.userBankDetails?.account?.ssn}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-right mt-3">
                      <button
                        type="submit"
                        className="btn btn-medium btn-purple"
                        onClick={() => navigation("/bank-details")}
                      >
                        Edit Bank Info
                      </button>
                    </div>
                  </div>
                </>
              ) : (selectCurrentuser.role === Roles.driver ||
                  selectCurrentuser.role === Roles.sales ||
                  selectCurrentuser.role === Roles.development) &&
                currentUser?.userBankDetails === null ? (
                <div className="fields-wrapper mt-4">
                  <div className="form-group">
                    <h4>Financial Info</h4>
                  </div>
                  <div
                    className="form-group fin-info"
                    onClick={() => navigation("/bank-details")}
                  >
                    <h6>Please Add Your Bank Details</h6>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfilePage;
