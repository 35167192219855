export function setcurrentuserandtoken(userObject: unknown, token: string) {
  const user = JSON.stringify(userObject);
  window.localStorage.setItem("user", user);
  window.localStorage.setItem("token", token);
}

export function setcurrentuser(userObject: any) {
  const user = JSON.stringify(userObject);
  window.localStorage.setItem("user", user);
}

export function getcurrentuser() {
  let user = window.localStorage.getItem("user");
  let token = window.localStorage.getItem("token");

  if (user && token) {
    return JSON.parse(user);
  } else {
    return false;
  }
}

export function setsourceinfo(sourceId: string) {
  window.localStorage.setItem("sourceId", sourceId);
}

export function getsourceinfo(): string {
  return window.localStorage.getItem("sourceId") || "";
}
