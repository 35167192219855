import React from "react";
import { Route, useLocation, Routes, Navigate } from "react-router-dom";
import Login from "../container/auth/Login";
import SignUp from "../container/auth/Signup";
import ResetPassword from "../components/ResetPassword";
import ForgotPassword from "../components/ForgotPassword";

const UnAuthenticatedRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <div className="w-screen h-screen overflow-hidden flex">
      <Routes location={location}>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/signup" element={<SignUp />} />

        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
};

export default UnAuthenticatedRoutes;
