const SharedData = {
  Endorsements: [
    "Double/Triple",
    "Passenger",
    "Tanker",
    "Bus",
    "School",
    "TankHazmat",
    "Hazmat",
  ],
  Haul_Type: [
    { value: "Regional", label: "Regional" },
    { value: "OTR", label: "OTR" },
    { value: "Local", label: "Local" },
  ],
  whatHult: [
    { value: "Flat Bed", label: "Flat Bed" },
    { value: "Dryvan", label: "Dryvan" },
    { value: "Refer", label: "Refer" },
    { value: "Tanker", label: "Tanker" },
  ],

  Accident: [
    { value: "None", label: "None" },
    { value: "One Accident", label: "One Accident" },
    { value: "More than one", label: "More then one" },
  ],

  yearsExperience: [
    { value: "", text: "Select " },
    { value: "1 Year", text: "< 1 Years" },
    { value: "1-3 Year", text: "1-3 Years" },
    { value: "3-5 Year", text: "3-5 Years" },
    { value: "<5 Year", text: "<5 Years" },
    { value: "5-10 Year", text: "5-10 Years" },
    { value: "10+ Years", text: "10+ Years" },
  ],

  IdealCompany: [
    { value: "Carrier", label: "Carrier" },
    { value: "Private Fleet", label: "Private Fleet" },
    { value: "Both", label: "Both" },
  ],

  DevelopmentyearsExperience: [
    { value: "", text: "Select " },
    { value: "1 Year", text: "< 1 Years" },
    { value: "1-3 Year", text: "1-3 Years" },
    { value: "3-5 Year", text: "3-5 Years" },
    { value: "<5 Year", text: "<5 Years" },
    { value: "5-10 Year", text: "5-10 Years" },
    { value: "10+ Years", text: "10+ Years" },
  ],

  DriveryearsExperience: [
    { value: "", text: "Select " },
    { value: "1-3 Year", text: "1-3 Years" },
    { value: "3-5 Year", text: "3-5 Years" },
    { value: "5-10 Year", text: "5-10 Years" },
    { value: "10+ Years", text: "10+ Years" },
  ],

  Sales_Methodologies: [
    { value: "Sandler", label: "SANDLER" },
    { value: "Spin", label: "SPIN" },
    { value: "Meddicc", label: "MEDDICC" },
    { value: "Challenger", label: "Challenger" },
    { value: "Neat", label: "NEAT" },
    { value: "Snap", label: "SNAP" },
    { value: "Value selling frameWork", label: "Value Selling Framework" },
  ],
  sales_Methodology: [
    "Sandler",
    "Spin",
    "Meddicc",
    "Challenger",
    "Neat",
    "Snap",
    "Value selling frameWork",
  ],

  Positions: [
    "SDR/BDR",
    "Inside Sales",
    "New Logo",
    "Partnership",
    "Sales Executive",
    "Sales Manager",
    "Account Manager",
  ],

  DealWon: [
    { value: "", text: "Select " },
    { value: "$10's", text: "$10's" },
    { value: "$100's", text: "$100's" },
    { value: "$1M+", text: "1M+" },
  ],

  UsualSales: [
    { value: "", text: "Select " },
    { value: "Transactional", text: "Transactional" },
    { value: "3-6 Months", text: "3-6 Months" },
    { value: "6-12 Months", text: "6-12 Months" },
    { value: "Years", text: "Years" },
  ],

  StackExperience: [
    { value: "MEAN", label: "MEAN" },
    { value: "MERN", label: "MERN" },
    { value: "MEVN", label: "MEVN" },
    { value: "RUBY", label: "RUBY" },
    { value: "FLUTTER", label: "FLUTTER" },
    { value: "LAMP", label: "LAMP" },
    { value: "DJANGO", label: "DJANGO" },
  ],

  StackExperiences: [
    "MEAN",
    "MERN",
    "MEVN",
    "RUBY",
    "FLUTTER",
    "LAMP",
    "DJANGO",
  ],

  IndividualSkill: [
    "JavaScript",
    "Html",
    "Css",
    "sql",
    "NodeJs",
    "Mongodb",
    "php",
    "ASP.NET",
    "UX",
  ],

  IndividualSkills: [
    { value: "JavaScript", label: "JavaScript" },
    { value: "Html", label: "HTML" },
    { value: "Css", label: "CSS" },
    { value: "sql", label: "SQL" },
    { value: "Mongodb", label: "MongoDB" },
    { value: "NodeJs", label: "Node.Js" },
    { value: "php", label: "PHP" },
    { value: "ASP.NET", label: "ASP.NET" },
    { value: "UX", label: "UX" },
  ],

  Endorsement: [
    { value: "Double/Triple", label: "Double/Triple" },
    { value: "Passenger", label: "Passenger" },
    { value: "Tanker", label: "Tanker" },
    { value: "Bus", label: "Bus" },
    { value: "School", label: "School" },
    { value: "Tank & hazmet", label: "Tank & Hazmat" },
    { value: "Hazmat", label: "Hazmat" },
  ],

  DriversRole: [
    { value: "Dryvan", label: "Dryvan" },
    { value: "Flatbed", label: "Flatbed" },
    { value: "LTL", label: "LTL" },
    { value: "REFER", label: "REFER" },
    { value: "INTERMODAL", label: "INTERMODAL" },
  ],

  DevelopersRole: [
    { value: "Developer", label: "Developer" },
    { value: "Scrum Leader", label: "Scrum Leader" },
    { value: "Full Stack Developer", label: "Full Stack Developer" },
  ],

  Roles: [
    { value: "SDR/BDR", label: "SDR/BDR" },
    { value: "Inside Sales", label: "Inside Sales" },
    { value: "New Logo", label: "New Logo" },
    { value: "Sales Executive", label: "Sales Executive" },
    { value: "Sales Manager", label: "Sales Manager" },
    { value: "Partnership", label: "Partnership" },
    { value: "Account Manager", label: "Account Manager" },
  ],

  //DriverOptions : [
  //   { value: "sales", text: "Sales" },
  //   { value: "driver", text: "Driver" },
  //   { value: "development", text: "Development" },
  // ],
};

export default SharedData;
