import React, { useEffect } from "react";
import { models } from "src/enums";
import { store } from "../../store";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { useParams, useNavigate } from "react-router-dom";
import { getMessageData } from "src/container/message/message.slice";
import { allBidsOnJob } from "src/container/PostJob/PostJob.selector";

const ViewRequest: React.FC<{}> = (props) => {
  const { jobId } = useParams();
  const navigation = useNavigate();
  const bidsOnJob = useAppSelector(allBidsOnJob);
  const { getJobBidsDetails, getDriverDetails, set_active_model } =
    useActions();

  useEffect(() => {
    getJobBidsDetails({ job_id: jobId });
  }, []);

  const viewApplicantDetails = async (
    already_hired: any,
    driverId: any,
    allData: any
  ) => {
    const results: any = await getDriverDetails({
      user_id: driverId,
    });
    if (results?.payload?.status) {
      if (already_hired) {
        store.dispatch(
          getMessageData({
            msgData: {
              id: driverId,
              name: `${allData?.first_name} ${allData?.last_name}`,
              avatar: allData?.avatar,
            },
          })
        );
        set_active_model(models.CHAT_MODEL);
      } else {
        navigation(`/viewUserDetails/${driverId}/${jobId}`);
      }
    }
  };

  return (
    <div className="filter-posts">
      <h5 className="search-heading">Applicants Requests</h5>
      <div className="row mt-md-4 mt-0">
        {bidsOnJob.map((curElem: any, index: number) => {
          return (
            <div className="col-md-3 col-12 mb-5" key={index}>
              <div className="search-card">
                <div className="detail-wrapper">
                  <div>
                    <img className="user-avatar" src={curElem?.avatar} alt="" />
                    <h5>{`${curElem?.first_name} ${curElem?.last_name}`}</h5>
                    <p>{`State: ${curElem?.state}`}</p>
                    <p>{`Experience: ${curElem?.experience}`}</p>
                  </div>
                </div>
                <button
                  className="btn btn-medium btn-purple"
                  onClick={() =>
                    viewApplicantDetails(
                      curElem.already_hired,
                      curElem._id,
                      curElem
                    )
                  }
                >
                  {curElem.already_hired ? "Message" : "View Details"}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ViewRequest;
