import React, { useState, useEffect } from "react";
import { models } from "src/enums";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import toastBuilder from "src/utils/toastBuilder";
import { getJobId } from "src/container/PostJob/PostJob.selector";
import { GrDocumentDownload } from "react-icons/gr";
import { IoMdAttach, IoMdCloseCircle } from "react-icons/io";

const BroadCastMessageModel: React.FC<{}> = (props) => {
  const { set_active_model, createBroadcast } = useActions();
  const [typedMsg, setTypedMsg] = useState<string>("");
  const [uploadImages, setUploadImages] = useState<any>([]);
  const job_id = useAppSelector(getJobId);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    let formData = new FormData();

    formData.append("message", typedMsg);
    formData.append("job_id", job_id);
    for (let focusedElem of uploadImages) {
      formData.append("filesToBeUploaded", focusedElem.orgObject);
    }
    setTypedMsg("");
    setUploadImages([]);

    const action: any = await createBroadcast(formData);
    if (action?.payload?.status) {
      toastBuilder("success", "Broadcast Created Successfully");
      set_active_model(models.CLEAR);
    }
  };

  const handleFileUpload = (event: any) => {
    const previewImgs = Array.from(event.target.files);
    const previewImgsMapped = previewImgs.map((focusedImg: any) => {
      return {
        src: URL.createObjectURL(focusedImg),
        orgObject: focusedImg,
        type: focusedImg.type,
        name: focusedImg.name,
      };
    });
    setUploadImages((previous: any) => [...previous, ...previewImgsMapped]);
    event.target.value = null;
  };

  const handleRemoveImage = (elem: any) => {
    setUploadImages((prevState: any) => {
      return prevState.filter((data: any) => {
        return data.src !== elem.src;
      });
    });
  };

  return (
    <>
      <div className="Jobs_Detail">
        <div className="broadcast-header">
          <h5>Start Broadcast</h5>
          <i
            onClick={() => set_active_model(models.CLEAR)}
            className="fa-solid fa-x"
          ></i>
        </div>

        <div className="broadcast-body">
          <form onSubmit={handleSubmit}>
            <div className="input-fields-area mt-0">
              <div className="fields-wrapper flex-column align-items-start">
                <div className="form-group w-100">
                  <label>Message: </label>
                  <div className="form-field">
                    <textarea
                      rows={4}
                      value={typedMsg}
                      onChange={(event) => setTypedMsg(event.target.value)}
                      name="message"
                      className="form-control broadcast-field"
                      placeholder="Please type a message"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="uploaded-wrapper">
              {uploadImages.map((elem: any, index: number) => {
                return (
                  <div key={index}>
                    <i
                      className="close-btn"
                      onClick={() => handleRemoveImage(elem)}
                    >
                      <IoMdCloseCircle />
                    </i>
                    {elem.type === "text/csv" ||
                    elem.type === "application/pdf" ||
                    elem.type ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    elem.type ===
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                      <div className="uploaded-documents">
                        <i>
                          <GrDocumentDownload />
                        </i>
                        <label>{elem.name}</label>
                      </div>
                    ) : (
                      <img src={elem.src} alt="" />
                    )}
                  </div>
                );
              })}
            </div>

            <div className="broadcast-footer position-relative">
              <button type="submit" className="btn btn-medium btn-purple">
                Publish a Broadcast
              </button>

              <div className="chat-input" title="Upload Files">
                <label>
                  <input
                    multiple
                    type="file"
                    className="attach-icon"
                    onChange={(event: any) => handleFileUpload(event)}
                    accept="image/png, image/jpeg, image/jpg, image/heic, image/heif, .xlsx, .csv, .pdf, .doc, .docx, video/mp4,video/x-m4v,video/*"
                  />
                  <IoMdAttach />
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="bg-overlay-fixed"></div>
    </>
  );
};

export default BroadCastMessageModel;
