import React, { useEffect } from "react";
import { Roles } from "src/enums";
import toastBuilder from "src/utils/toastBuilder";
import { Link } from "react-router-dom";
import { selectUser } from "src/container/auth/auth.selector";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { allAvailDriverJobs } from "src/container/PostJob/PostJob.selector";

const AllJobs: React.FC<{}> = (props) => {
  const {
    getAllRecruiterJobs,
    getAllDriverJobs,
    followJob,
    // driverBidOnJob,
  } = useActions();
  const selectCurrentuser = useAppSelector(selectUser);

  const driverAllJobs = useAppSelector(allAvailDriverJobs);

  const allPostedJob =
    selectCurrentuser.role === Roles.driver ||
    selectCurrentuser.role === Roles.sales ||
    selectCurrentuser.role === Roles.development
      ? driverAllJobs
      : "";

  const currUser = selectCurrentuser.role === Roles.recruiter;

  useEffect(() => {
    selectCurrentuser.role === Roles.recruiter && getAllRecruiterJobs();
    selectCurrentuser.role === Roles.driver && getAllDriverJobs();
    selectCurrentuser.role === Roles.sales && getAllDriverJobs();
    selectCurrentuser.role === Roles.development && getAllDriverJobs();
    getAllRecruiterJobs();
  }, []);

  const handleFollowJob = async (postedJobId: any) => {
    const results: any = await followJob({ job_id: postedJobId });
    if (results?.payload?.status) {
      toastBuilder("success", "Applied Sucessfully");
      getAllDriverJobs();
    }
  };

  return (
    <>
      {allPostedJob.length === 0 ? (
        <div className="no-story-text">
          <h2>No Jobs found...</h2>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 col-md-10 col-12">
              <div>
                {allPostedJob.map((allPostedJob: any, index: number) => {
                  return (
                    // <div className=" col-lg-12" key={index}>
                    <div className="news-item news-border mt-3">
                      <div className="news-text-box">
                        <div className="job-desc d-flex" key={index}>
                          <img
                            className="user-avatar"
                            src={
                              allPostedJob?.company_info?.company_logo?.file_url
                              // currUser
                              //   ? selectCurrentuser?.avatar
                              //   : allPostedJob.recruiter_info.avatar
                            }
                            alt=""
                          />
                          <div className="d-flex justify-content-between align-items-center ">
                            <div>
                              <h5 className="user--name">
                                {allPostedJob?.company_info?.company_name}
                                {/* {currUser
                                    ? `${selectCurrentuser?.first_name} ${selectCurrentuser?.last_name}`
                                    : `${allPostedJob?.recruiter_info?.first_name} ${allPostedJob?.recruiter_info?.last_name}`} */}
                              </h5>
                              <p className="user--country">
                                {allPostedJob?.job_title}
                                {/* {currUser
                                    ? `${selectCurrentuser?.city}, ${selectCurrentuser?.state}`
                                    : `${allPostedJob?.recruiter_info?.city}, ${allPostedJob?.recruiter_info?.state}`} */}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="w-100">
                          <div className="job-apply ">
                            <div className="d-flex justify-content-between mt-3">
                              <h5 className="user--name">
                                {/* {allPostedJob?.job_title} */}
                                {currUser
                                  ? `${selectCurrentuser?.first_name} ${selectCurrentuser?.last_name}`
                                  : `${allPostedJob?.recruiter_info?.first_name} ${allPostedJob?.recruiter_info?.last_name}`}
                              </h5>
                              <p className="user--country">
                                {allPostedJob?.job_location}
                              </p>
                            </div>
                            <p className="user--country">
                              {allPostedJob?.description}
                            </p>
                            {/* <img src={allPostedJob?.job_image_url} alt="" /> */}
                            <Link to={`/jobDetail/${allPostedJob?._id}`}>
                              <button
                                className={`btn btn-medium btn-purple ${
                                  allPostedJob.applied ? "active" : null
                                }`}
                              >
                                See Details
                              </button>
                            </Link>
                            {selectCurrentuser.role !== Roles.recruiter ? (
                              <>
                                <button
                                  className={`btn btn-medium btn-purple user-post-button ${
                                    allPostedJob.applied ? "active" : null
                                  }`}
                                  onClick={() =>
                                    handleFollowJob(allPostedJob?._id)
                                  }
                                >
                                  Follow
                                </button>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    // </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllJobs;
