import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "src/store";

const selectedreducer = (state: RootState) => state.headerNotify;

export const headerEmails = createDraftSafeSelector(
    selectedreducer,
    (userState) => userState.email
);

export const headerNotifcations = createDraftSafeSelector(
    selectedreducer,
    (userState) => userState.notification
);

export const getAllNotificationsMsg = createDraftSafeSelector(
    selectedreducer,
    (userState) => userState.allNotificationsMsg
);
