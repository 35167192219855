import React, { useEffect } from "react";
import { DateTime } from "luxon";
import { models } from "src/enums";
import { useActions } from "src/actions";
import { useAppSelector, store } from "src/store";
import { getMessageData } from "src/container/message/message.slice";
import { allHiredDrivers } from "src/container/recruiter/recruiter.selector";

const HiredDrivers: React.FC<{}> = (props) => {
  const hiredDriversDetail = useAppSelector(allHiredDrivers);
  const { getEngagedUserDetails, set_active_model } = useActions();
  const convertDate = DateTime.local(hiredDriversDetail?.assigned_date);
  const engageDate = convertDate.toFormat("dd-MM-yy");

  useEffect(() => {
    getEngagedUserDetails();
  }, []);

  const messageDriver = (driverDetail: any) => {
    set_active_model(models.CHAT_MODEL);
    store.dispatch(
      getMessageData({
        msgData: {
          id: driverDetail?._id,
          name: driverDetail?.first_name + " " + driverDetail?.last_name,
          avatar: driverDetail?.avatar,
        },
      })
    );
  };

  return (
    <div className="filter-posts">
      <h5 className="search-heading">Engaged Applicants</h5>
      <div className="row mt-md-4 mt-0 ">
        {hiredDriversDetail.map((hiredDriverDetail: any) => {
          return (
            <div
              className="col-lg-3 col-md-4 col-6 mb-4"
              key={hiredDriverDetail?._id}
            >
              <div className="search-card">
                <img
                  className="user-avatar"
                  src={hiredDriverDetail?.avatar}
                  alt=""
                />

                <div className="detail-wrapper">
                  <div>
                    <h5>{`${hiredDriverDetail?.first_name} ${hiredDriverDetail?.last_name}`}</h5>
                    <p>{`${hiredDriverDetail?.city}, ${hiredDriverDetail?.state}`}</p>
                    <p>Experience: {hiredDriverDetail?.years_experience}</p>
                    <p>
                      Engaged: <span className="text-purple">{engageDate}</span>
                    </p>
                  </div>

                  <button
                    className="btn btn-medium btn-purple"
                    onClick={() => {
                      messageDriver(hiredDriverDetail);
                    }}
                  >
                    Message
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HiredDrivers;
