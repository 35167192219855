import React, { useEffect, useState } from "react";
import { models } from "src/enums";
import { useActions } from "src/actions";
import { BiArrowBack } from "react-icons/bi";
import { useAppSelector, store } from "src/store";
import toastBuilder from "src/utils/toastBuilder";
import { UseQueryParams } from "src/utils/GetParams";
import { useParams, useNavigate } from "react-router-dom";
import { getMessageData } from "src/container/message/message.slice";
import { driverAllDetails } from "src/container/driver/driver.selector";
import AddPayentDetail from "../AccountSettings/AddPaymentPopup";

const ViewDriverDetails = () => {
  const navigation = useNavigate();
  const { driverId, jobId } = useParams();
  const [showPaymentCard, setShowPaymentCard] = useState(false);
  const [hired] = UseQueryParams("hired");
  const viewAllDetails = useAppSelector(driverAllDetails);
  const applicantDetails = viewAllDetails;
  const { getDriverDetails, acceptJob, set_active_model } = useActions();

  useEffect(() => {
    getDriverDetails({ user_id: driverId });
  }, []);

  const hireDriver = async () => {
    if (driverId && jobId) {
      const results: any = await acceptJob({
        job_id: jobId,
        applicant_id: driverId,
        hire_for_job: true,
      });
      if (results?.payload?.status) {
        if (results?.payload?.data?.payment_details === false) {
          setShowPaymentCard(true);
        } else {
          toastBuilder("success", "Applicant Engaged Successfully");
          navigation("/home");
        }
      }
    } else if (driverId && hired === "true") {
      set_active_model(models.CHAT_MODEL);
      store.dispatch(
        getMessageData({
          msgData: {
            id: applicantDetails?._id,
            name:
              applicantDetails?.first_name + " " + applicantDetails?.last_name,
            avatar: applicantDetails?.avatar,
          },
        })
      );
    } else if (driverId && hired === "false") {
      const results: any = await acceptJob({
        // @ts-ignore
        applicant_id: driverId,
        hire_for_job: false,
      });
      if (results?.payload?.status) {
        navigation("/home");
        toastBuilder("success", "Driver Engaged Successfully");
      }
    }
  };

  return (
    <>
      <div className="card user-card-full">
        <div className="row ml-0 mr-0">
          <div className="col-md-4 col-12 user-profile">
            <div onClick={() => navigation("/talent")}>
              <BiArrowBack />
            </div>
            <div className="card-block profile-side">
              <div className="mb-md-4 mb-2 driver-avatar">
                <img src={applicantDetails?.avatar} alt="" />
              </div>
              <h5>{`${applicantDetails?.first_name} ${applicantDetails?.last_name}`}</h5>
              <p className="m-0">{`${applicantDetails?.city}, ${applicantDetails?.state}`}</p>
              <p>{applicantDetails?.role}</p>
            </div>
          </div>

          <div className="col-md-8 col-12">
            <div className="card-block">
              <h5 className="mb-3 pb-2 b-b-default">Information</h5>
              {hired === "true" ? (
                <div className="row">
                  <div className="col-sm-6">
                    <h6>Email</h6>
                    <p>{applicantDetails?.email}</p>
                  </div>

                  <div className="col-sm-6">
                    <h6>Phone</h6>
                    <p>{applicantDetails?.phone ?? "Not Available"}</p>
                  </div>

                  <div className="col-sm-6">
                    <h6>Zip Code</h6>
                    <p>{applicantDetails?.zip_code}</p>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12 mb-4 hidden-msg">
                    <h6>
                      You will see this info once you engage this applicants...
                    </h6>
                  </div>
                </div>
              )}

              <h5 className="my-3 pb-2 b-b-default">Applicant Details</h5>
              <div className="row emp_history">
                {applicantDetails.skills?.haul_type?.length && (
                  <div className="col-sm-6 ">
                    <h6>Ideal Company:</h6>
                    <p>{applicantDetails.skills?.ideal_company}</p>
                  </div>
                )}

                {applicantDetails.skills?.haul_type?.length && (
                  <div className="col-sm-6 ">
                    <h6>Haul Type:</h6>
                    <p>{applicantDetails.skills?.haul_type}</p>
                  </div>
                )}

                {applicantDetails.skills?.largest_deal_won?.length && (
                  <div className="col-sm-6 ">
                    <h6>Average Deal Size:</h6>
                    <p>{applicantDetails.skills?.largest_deal_won}</p>
                  </div>
                )}

                {applicantDetails.experience?.experience && (
                  <div className="col-sm-6 ">
                    <h6>Experience:</h6>
                    <p>{applicantDetails.experience?.experience}</p>
                  </div>
                )}

                {applicantDetails.experience?.linkedIn_link && (
                  <div className="col-sm-6 ">
                    <h6>LinkedIn Link:</h6>
                    <p>{applicantDetails.experience?.linkedIn_link}</p>
                  </div>
                )}

                {applicantDetails?.skills?.usual_sales_cycles?.length > 0 && (
                  <div className="col-sm-6">
                    <h6>Usual Sale Cycles:</h6>
                    <div className="desires-grid">
                      {applicantDetails?.skills?.usual_sales_cycles?.map(
                        (el: any) => {
                          return <p> {el} </p>;
                        }
                      )}
                    </div>
                  </div>
                )}

                {applicantDetails?.skills?.sales_methodologies?.length > 0 && (
                  <div className="col-sm-6">
                    <h6>Sales Methodologies:</h6>
                    <div className="desires-grid">
                      {applicantDetails?.skills?.sales_methodologies?.map(
                        (el: any) => {
                          return <p>{el}</p>;
                        }
                      )}
                    </div>
                  </div>
                )}

                {applicantDetails?.skills?.stack_experiences?.length > 0 && (
                  <div className="col-sm-6 ">
                    <h6>Stack Experiences:</h6>
                    <div className="desires-grid">
                      {applicantDetails?.skills?.stack_experiences?.map(
                        (el: any) => {
                          return <p>{el}</p>;
                        }
                      )}
                    </div>
                  </div>
                )}

                {applicantDetails?.skills?.individual_skills?.length > 0 && (
                  <div className="col-sm-6 ">
                    <h6>Individual Skills:</h6>
                    <div className="desires-grid">
                      {applicantDetails?.skills?.individual_skills?.map(
                        (el: any) => {
                          return <p>{el}</p>;
                        }
                      )}
                    </div>
                  </div>
                )}

                {applicantDetails?.skills?.endorsements?.length > 0 && (
                  <div className="col-sm-6 ">
                    <h6>Endorsements:</h6>
                    <div className="desires-grid">
                      {applicantDetails?.skills?.endorsements?.map(
                        (el: any) => {
                          return <p>{el}</p>;
                        }
                      )}
                    </div>
                  </div>
                )}

                {applicantDetails.skills?.future_plan?.length && (
                  <div className="col-sm-6 ">
                    <h6>Future Plan:</h6>
                    <p>{applicantDetails.skills?.future_plan}</p>
                  </div>
                )}
              </div>

              <div className="mt-3 text-right">
                <button
                  className="btn btn-medium btn-purple"
                  onClick={() => hireDriver()}
                >
                  {hired === "true" ? "Message" : "Engage Applicant"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddPayentDetail
        showPaymentCard={showPaymentCard}
        setShowPaymentCard={setShowPaymentCard}
      />
    </>
  );
};

export default ViewDriverDetails;
