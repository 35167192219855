import React, { useEffect } from "react";
import { models } from "src/enums";
import { store } from "../../store";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { getMessageData } from "src/container/message/message.slice";
import { allHiredDrivers } from "src/container/recruiter/recruiter.selector";

const HiredRecruiters: React.FC<{}> = (props) => {
  const { getEngagedUserDetails, set_active_model } = useActions();
  const hiredDriversDetail = useAppSelector(allHiredDrivers);

  useEffect(() => {
    getEngagedUserDetails();
  }, []);

  const viewRecruiterDetails = (hiredDriverDetail: any) => {
    store.dispatch(
      getMessageData({
        msgData: {
          id: hiredDriverDetail?._id,
          name: `${hiredDriverDetail?.first_name} ${hiredDriverDetail?.last_name}`,
          avatar: hiredDriverDetail?.avatar,
        },
      })
    );
    set_active_model(models.CHAT_MODEL);
  };

  return (
    <div className="filter-posts">
      <h5 className="search-heading">Engaged Recruiters</h5>
      <div className="row mt-md-4 mt-0">
        {hiredDriversDetail.map((hiredDriverDetail: any) => {
          return (
            <div
              className="col-lg-3 col-md-4 col-6 mb-4"
              key={hiredDriverDetail?.recuriter_details_info?._id}
            >
              <div className="search-card">
                <img
                  className="user-avatar"
                  src={hiredDriverDetail?.avatar}
                  alt=""
                />
                <div className="detail-wrapper">
                  <div>
                    <h5 className="mb-1">{`${hiredDriverDetail?.first_name} ${hiredDriverDetail?.last_name}`}</h5>
                    <p>
                      {hiredDriverDetail?.recuriter_details_info?.company_name}
                    </p>
                    <p>{`${hiredDriverDetail?.city}, ${hiredDriverDetail?.state}`}</p>
                  </div>
                  <button
                    className="btn btn-medium btn-purple"
                    onClick={() => {
                      viewRecruiterDetails(hiredDriverDetail);
                    }}
                  >
                    Message
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HiredRecruiters;
