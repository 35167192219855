import React, { useEffect, useState } from "react";
import Header from "../Header";
import { Field, Formik } from "formik";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { useNavigate } from "react-router-dom";
import toastBuilder from "src/utils/toastBuilder";
import { BankDetailsSchema } from "src/validation/profile";
import { selectUser } from "src/container/auth/auth.selector";
import stripe_blurple from "../../../src/assets/images/stripe-blurple.svg";

const BankDetails: React.FC<{}> = (props) => {
  const { addBankDetails, getProfileData, getBankDetails, editBankDetails } =
    useActions();
  const currentUser = useAppSelector(selectUser);
  const navigation = useNavigate();

  useEffect(() => {
    if (currentUser?.userBankDetails?.account) {
      const getValues = async () => {
        const results: any = await getBankDetails();
        if (results.payload.status) {
          const bankInfo = results.payload.data.bank_details.account;
          const accNumber = `xxxx xxxx ${bankInfo.account_number}`;
          bankInfo.account_number = accNumber;
          setUserBankInfo(bankInfo);
        }
      };
      getValues();
    }
  }, []);

  const initalValues = {
    account_holder_name: "",
    account_number: "",
    routing_number: "",
    dob: "",
    ssn: "",
  };

  const [userBankInfo, setUserBankInfo] = useState(initalValues);

  const handleSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any
  ) => {
    const constructedDate = new Date(formValues.dob);
    const dob_day = constructedDate.getDate().toString();
    const dob_year = constructedDate.getFullYear().toString();
    const dob_month = (constructedDate.getMonth() + 1).toString();

    if (currentUser?.userBankDetails?.account) {
      const results: any = await editBankDetails({
        account_holder_name: formValues.account_holder_name,
        account_number: formValues.account_number,
        routing_number: formValues.routing_number,
        date_year: dob_year,
        date_month: dob_month,
        date_day: dob_day,
        ssn_last_4: formValues.ssn,
      });
      if (results.payload.status) {
        toastBuilder("success", "Updated Successfully");
        setSubmitting(false);
        await getProfileData();
        navigation("/home");
      }
    } else {
      const results: any = await addBankDetails({
        account_holder_name: formValues.account_holder_name,
        account_number: formValues.account_number,
        routing_number: formValues.routing_number,
        date_year: dob_year,
        date_month: dob_month,
        date_day: dob_day,
        ssn_last_4: formValues.ssn,
      });
      if (results.payload.status) {
        toastBuilder("success", "Added Successfully");
        setSubmitting(false);
        await getProfileData();
        navigation("/home");
      }
    }
  };

  return (
    <>
      <Header />
      <section className="bank-details">
        <div className="container">
          <h2>Bank Details</h2>

          <div className="bd-box">
            <div className="input-fields-area">
              <Formik
                validationSchema={BankDetailsSchema}
                onSubmit={handleSubmit}
                initialValues={userBankInfo}
                enableReinitialize={true}
              >
                {({ handleSubmit, values, touched, errors }) => {
                  return (
                    <>
                      <form onSubmit={handleSubmit}>
                        <div className="fields-wrapper">
                          {/* Account Holder Field */}
                          <div className="form-group">
                            <div className="form-field">
                              <Field
                                type="text"
                                name="account_holder_name"
                                placeholder="Enter Holder Name"
                              />
                            </div>
                            <span className="errorMsg">
                              {touched.account_holder_name &&
                                errors.account_holder_name}
                            </span>
                          </div>

                          {/* Account Number Field */}
                          <div className="form-group">
                            <div className="form-field">
                              <Field
                                type="text"
                                name="account_number"
                                placeholder="Enter Account Number"
                              />
                            </div>
                            <span className="errorMsg">
                              {touched.account_number && errors.account_number}
                            </span>
                          </div>
                        </div>

                        <div className="fields-wrapper">
                          {/* Rownting Number Field */}
                          <div className="form-group">
                            <div className="form-field">
                              <Field
                                type="text"
                                name="routing_number"
                                placeholder="Enter Routing Number"
                              />
                            </div>
                            <span className="errorMsg">
                              {touched.routing_number && errors.routing_number}
                            </span>
                          </div>

                          {/* Date of birth Field */}
                          <div className="form-group">
                            <div className="form-field">
                              <Field
                                type="date"
                                name="dob"
                                value={values.dob}
                                placeholder="Enter Date of birth"
                              />
                            </div>
                            <span className="errorMsg">
                              {touched.dob && errors.dob}
                            </span>
                          </div>
                        </div>

                        <div className="fields-wrapper ">
                          {/* SSN Field */}
                          <div className="form-group">
                            <div className="form-field">
                              <Field
                                type="number"
                                name="ssn"
                                placeholder="Enter SSN (Last 4)"
                              />
                            </div>
                            <span className="errorMsg">
                              {touched.ssn && errors.ssn}
                            </span>
                          </div>
                        </div>
                        <div className="form-group b-detail-submit d-flex justify-content-between">
                          <button
                            type="submit"
                            className="btn btn-medium btn-purple "
                          >
                            {currentUser?.userBankDetails?.account
                              ? "Update Details"
                              : "Add Details"}
                          </button>
                          <img
                            className="stripe-icon "
                            src={stripe_blurple}
                            alt="Stripe-icon"
                          />
                        </div>
                      </form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BankDetails;
