import React from "react";
import { removeCurrentUser } from "src";
import { useActions } from "src/actions";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/store";
import { models, Roles } from "src/enums";
import { logout } from "src/container/auth/auth.slice";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { selectUser } from "src/container/auth/auth.selector";
import logoutIcon from "src/assets/images/icons/logout-icon.svg";
import bankIcon from "src/assets/images/icons/bank-detail-icon.svg";
import paymentIcon from "src/assets/images/icons/payment-icon.svg";
import myAccountIcon from "src/assets/images/icons/my-account-icons.svg";
import { FaCoins } from "react-icons/fa";

const UserIconForm: React.FC<{}> = () => {
  const selectCurrentuser = useAppSelector(selectUser);
  const { set_active_model } = useActions();
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const handleLogoutCall = () => {
    dispatch(logout());
    navigation("/login");
  };

  return (
    <>
      <div className="mega-menu p-0">
        <ul className="account-info">
          <li onClick={() => set_active_model(models.CLEAR)}>
            <NavLink to="/my-account">
              <img src={myAccountIcon} alt="" />
              My Account
            </NavLink>
            <i className="fa-solid fa-chevron-right chevron--right-arrow"></i>
          </li>

          {selectCurrentuser.role === Roles.recruiter && (
            <li onClick={() => set_active_model(models.CLEAR)}>
              <NavLink to="/payment-methods">
                <img src={paymentIcon} alt="" />
                Payment Methods
              </NavLink>
              <i className="fa-solid fa-chevron-right chevron--right-arrow"></i>
            </li>
          )}

          {selectCurrentuser.role !== Roles.recruiter && (
            <>
              <li onClick={() => set_active_model(models.CLEAR)}>
                <NavLink to="/bank-details">
                  <img src={bankIcon} alt="" />
                  Bank Detail
                </NavLink>
                <i className="fa-solid fa-chevron-right chevron--right-arrow"></i>
              </li>
              <li onClick={() => set_active_model(models.CLEAR)}>
                <NavLink to="/earnings" className="nav-link">
                  {/* <img src={bankIcon} alt="" /> */}
                  <i className="earning_icons">
                    <FaCoins />{" "}
                  </i>
                  Earnings
                </NavLink>
                <i className="fa-solid fa-chevron-right chevron--right-arrow"></i>
              </li>
            </>
          )}

          <li
            onClick={() => {
              set_active_model(models.CLEAR);
              handleLogoutCall();
            }}
          >
            <NavLink to="#" onClick={() => removeCurrentUser()}>
              <img src={logoutIcon} alt="" />
              Logout
            </NavLink>
            <i className="fa-solid fa-chevron-right chevron--right-arrow"></i>
          </li>
        </ul>
      </div>

      <Link
        onClick={() => set_active_model(models.CLEAR)}
        id="close_side_menu"
        to="#."
      ></Link>
    </>
  );
};

export default UserIconForm;
