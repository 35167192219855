import React, { useState } from "react";
import { models } from "src/enums";
import Header from "src/components/Header";
import { useActions } from "src/actions";
import FilterPosts from "src/components/UserPost/FilterPosts";
import AllPosts from "src/components/UserPost/AllPosts";

const DriverPage: React.FC<{}> = (props) => {
  const { set_active_model, getHiredApplicantsList } = useActions();

  const [showTalent, setShowTalent] = useState(false);
  const handleHiredApplicants = () => {
    getHiredApplicantsList();
  };

  return (
    <>
      <Header />
      <section className="home-page">
        <div className="container">
          <div className="row align-items-center mb-3">
            <div className="col-lg-12 col-md-10 col-12 d-flex justify-content-center ">
              <button
                className="btn btn-medium btn-purple mr-1"
                onClick={() => {
                  set_active_model(models.FILTER_MODEL);
                  setShowTalent(false);
                }}
              >
                Search Talent
              </button>
              <button
                className="btn btn-medium btn-purple ml-1"
                onClick={() => {
                  setShowTalent(true);
                  handleHiredApplicants();
                }}
              >
                Your Talent
              </button>
            </div>
          </div>

          {showTalent ? (
            <div>
              <FilterPosts />
            </div>
          ) : (
            <div>
              <AllPosts />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default DriverPage;
