import { RootState } from "src/store";
import { createDraftSafeSelector } from "@reduxjs/toolkit";

const selectedreducer = (state: RootState) => state.headerMessage;

export const messageData = createDraftSafeSelector(selectedreducer, (userState) => userState.msgData);

export const getChatMessages = createDraftSafeSelector(
    selectedreducer,
    (userState) => userState.chatHeader
);

export const getChattingList = createDraftSafeSelector(
    selectedreducer,
    (userState) => userState.chattingList
);