import * as yup from "yup";

export const ProfileTabValidation = yup.object().shape({
  first_name: yup.string().trim().required("required"),
  last_name: yup.string().trim().required("required"),
  email: yup.string().email().required("required"),
  state: yup.string().trim().required("required"),
  city: yup.string().trim().required("required"),
  zip_code: yup.string().trim().required("required"),
});

export const ResetPassword = yup.object().shape({
  oldPassword: yup
    .string()
    .required("required")
    .matches(
      /^(?=.*[a-z,A-Z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/g,
      "Min 6 Characters, One Uppercase, One Number"
    ),
  newPassword: yup
    .string()
    .required("required")
    .matches(
      /^(?=.*[a-z,A-Z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/g,
      "Min 6 Characters, One Uppercase, One Number"
    ),
  newPasswordConfirm: yup
    .string()
    .required("required")
    .oneOf([yup.ref("newPassword"), null], "Password don't match!")
    .matches(
      /^(?=.*[a-z,A-Z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/g,
      "Min 6 Characters, One Uppercase, One Number"
    ),
});

export const RenameCompany = yup.object().shape({
  company_name: yup.string().required("required"),
});

export const ValidationCompany = yup.object().shape({
  logo: yup.mixed().required("required"),
  company_name: yup.string().trim().required("required"),
});

export const BankDetailsSchema = yup.object().shape({
  account_holder_name: yup.string().trim().required("required"),
  account_number: yup
    .number()
    .required("required")
    .typeError("Must be a number"),
  routing_number: yup
    .number()
    .required("required")
    .typeError("Must be a number"),
  dob: yup.string().required("required"),
  ssn: yup
    .string()
    .required("required")
    .max(4, "Enter maximum 4 digits")
    .min(4, "Minimum 4 digits"),
});

export const filterFormForDriver = yup.object().shape({
  ideal_company: yup.string().required("required"),
  years_experience: yup.string().required("required"),
  endoserments: yup.string().required("required"),
  twic_card: yup.string().required("required"),
  what_you_haul: yup.string().required("required"),
  accidents: yup.string().required("required"),
  haul_type: yup.string().required("required"),
});
