import React, { useEffect, useRef } from "react";
import { models } from "src/enums";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import toastBuilder from "src/utils/toastBuilder";
import { IoCloseCircleOutline } from "react-icons/io5";
import { getTribeId } from "src/container/Tribe/Tribe.selector";

const DeleteTribe: React.FC<{}> = () => {
  const ref = useRef<HTMLDivElement>(null);
  const tribe_id = useAppSelector(getTribeId);
  const { set_active_model, deleteTribe, getJoinedTribes } = useActions();

  useEffect(() => {
    const div = ref.current; // corresponding DOM node
    div?.classList.add("active");
    document.body.style.overflow = "hidden";
    return () => {
      div?.classList.remove("active");
      document.body.style.overflow = "scroll";
    };
  }, []);

  const handleDeleteTribe = async (id: any) => {
    const results: any = await deleteTribe(id);
    if (results.payload.status) {
      toastBuilder("success", "Tribe deleted successfully");
      set_active_model(models.CLEAR);
      getJoinedTribes();
    }
  };

  return (
    <>
      <div ref={ref} className="sidebar center-popup">
        <div className="model-info">
          <div
            className="close-icon"
            onClick={() => set_active_model(models.CLEAR)}
          >
            <IoCloseCircleOutline />
          </div>
          <h5>
            Are you sure you want to delete this tribe. It will be deleted
            permanently.
          </h5>
          <div className="mt-4">
            <button
              className="btn btn-medium btn-purple"
              onClick={() => handleDeleteTribe(tribe_id)}
            >
              Delete Now
            </button>
            <button
              className="btn btn-medium btn-purple"
              onClick={() => set_active_model(models.CLEAR)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div className="bg-overlay bg-fixed"></div>
    </>
  );
};

export default DeleteTribe;
