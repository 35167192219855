import React, { useEffect, useRef } from "react";
import moment from "moment";
import { models } from "src/enums";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { useNavigate } from "react-router-dom";
import {
  headerNotifcations,
  getAllNotificationsMsg,
} from "src/container/notification/notification.selector";

const NotifiPopUp: React.FC<{}> = () => {
  const navigation = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const notifyCount = useAppSelector(headerNotifcations);
  const getNotificationsMsg = useAppSelector(getAllNotificationsMsg);
  const { set_active_model, getAllNotifications, markNotificationsRead } =
    useActions();

  useEffect(() => {
    getAllNotifications();
    markNotificationsRead();
    const div = ref.current; // corresponding DOM node
    div?.classList.add("active");
    document.body.style.overflow = "hidden";

    return () => {
      div?.classList.remove("active");
      document.body.style.overflow = "scroll";
    };
  }, [ref]);

  const clickNavigate = ({ eventTag, user_data }: any) => {
    if (eventTag === "engage_driver") {
      navigation("/engaged-recruiters");
    } else if (eventTag === "new_boardcast") {
      navigation("/home");
    } else if (eventTag === "mark_interested") {
      navigation(`/view-request/${user_data}`);
    } else if (eventTag === "new_message") {
      set_active_model(models.MESSAGE_MODEL);
      return;
    }
    set_active_model(models.CLEAR);
  };

  return (
    <>
      <div ref={ref} className="sidebar">
        <div className="bar-header">
          <div className="main-heading">
            <h4>Notifications</h4>
            <span
              className={
                notifyCount === 0 ? "msg-counter d-none" : "msg-counter"
              }
            >
              {notifyCount}
            </span>
          </div>
          <div
            onClick={() => set_active_model(models.CLEAR)}
            className="close-sidebarMenu"
          >
            <i className="fa-solid fa-x"></i>
          </div>
        </div>

        {getNotificationsMsg.map((notificationMsg: any, index: number) => {
          return (
            <div
              className="user-message-box"
              key={index}
              onClick={() => clickNavigate(notificationMsg)}
            >
              <img src={notificationMsg?.sender_image_url} alt="" />
              <div className="w-80">
                <div className="user_name">
                  <h5>{notificationMsg?.header}</h5>
                  <span>
                    {moment(notificationMsg.assigned_date).format("DD-MM-YYYY")}
                  </span>
                </div>
                <p>{notificationMsg?.message}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="bg-overlay-fixed"></div>
    </>
  );
};

export default NotifiPopUp;
