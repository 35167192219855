import React, { useState } from "react";
import { models } from "src/enums";
import Header from "src/components/Header";
import { useActions } from "src/actions";
import JobFollowed from "./JobFollowed";
import AllJobs from "./AllJobs";

const JobsPage: React.FC<{}> = (props) => {
  const { set_active_model } = useActions();
  const [showTalent, setShowTalent] = useState(false);

  return (
    <>
      <Header />

      <section className="home-page">
        <div className="container-fluid">
          <div className="row align-items-center mb-3">
            <div className="col-lg-12 col-md-10 col-12 d-flex justify-content-center ">
              <button
                className="btn btn-medium btn-purple mr-1"
                onClick={() => {
                  set_active_model(models.JobSearch_MODEL);
                  setShowTalent(false);
                }}
              >
                Search Jobs
              </button>
              <button
                className="btn btn-medium btn-purple ml-1"
                onClick={() => {
                  setShowTalent(true);
                }}
              >
                Followed
              </button>
            </div>

            {showTalent ? <JobFollowed /> : <AllJobs />}
          </div>
        </div>
      </section>
    </>
  );
};

export default JobsPage;
