import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiClient } from "src/index";

export const AddPostJob = createAsyncThunk(
  "/job/postJob",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/job/postJob", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  
  }
);

export const getAllRecruiterJobs = createAsyncThunk("/job/getAllMyJobs", (payload, thunkApi) => {
  const result = ApiClient.get(`/job/getAllMyJobs`);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const getAllBroadCast = createAsyncThunk("/job/getAllBroadCast", (payload, thunkApi) => {
  const result = ApiClient.get(`/job/getAllBroadCast`);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const getAllDriverJobs = createAsyncThunk("/job/getAllJobs", (payload, thunkApi) => {
  const result = ApiClient.post(`/job/getAllJobs`, payload);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const driverBidOnJob = createAsyncThunk(
  "/job/madeBid",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/job/madeBid", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const getJobBidsDetails = createAsyncThunk(
  "/job/getJobBidsDetails",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/job/getJobBidsDetails", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const unPubllishJob = createAsyncThunk(
  "/job/unPubllishJob",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/job/unPublishJob", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const followJob = createAsyncThunk(
  "/job/unPubllishJob",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/job/followJob", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const getJobDetails = createAsyncThunk(
  "/job/getJobDetails",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/job/getJobDetails", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const editJobDetails = createAsyncThunk(
  "/job/editJobDetails",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/job/editJobDetails", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const acceptJob = createAsyncThunk(
  "/job/acceptJob",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/job/acceptJob", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  }
);

export const createBroadcast = createAsyncThunk(
  "/job/postJob",
  async (payload: any, thunkApi) => {
    const results = await ApiClient.post("/job/createBroadcast", payload);      
    if (results) return results;
    else thunkApi.rejectWithValue("");
  
  }
);