import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { store } from "../../store";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { State } from "country-state-city";
import userIcon from "src/assets/images/icons/user-icon.svg";
import phoneIcon from "src/assets/images/icons/phone-icon.svg";
import stateIcon from "src/assets/images/icons/flag-icon.svg";
import cityIcon from "src/assets/images/icons/city-icon.svg";
import pinIcon from "src/assets/images/icons/location-icon.svg";
import toastBuilder from "src/utils/toastBuilder";
import { BasicInfoForUpdate } from "src/validation/singnup";
import { selectUser } from "src/container/auth/auth.selector";
import { getFiltersData } from "src/container/recruiter/recruiter.slice";

const RecruiterInfoForm: React.FC<{}> = (props) => {
  const { getProfileData, updateUserData } = useActions();
  const currentUser = useAppSelector(selectUser);
  const [logoImage, setLogoImage] = useState(currentUser?.company_logo);
  const selectCurrentuser = useAppSelector(selectUser);

  const States = State.getStatesOfCountry("US").map((curElem) => {
    return curElem.name;
  });
  const initalValues = {
    first_name: "",
    last_name: "",
    phone: "",
    city: "",
    zip_code: "",
    companyLogo: "",
    company_name: "",
    conditions: true,
    state: "Alabama",
  };

  const [inistate, setstate] = useState(initalValues);

  useEffect(() => {
    getProfileData();
    store.dispatch(getFiltersData({ filters: null }));
  }, []);

  useEffect(() => {
    setstate(selectCurrentuser);
  }, [selectCurrentuser]);

  const handleSubmit = async (formValues: any, { setSubmitting }: any) => {
    var bodyFormData = new FormData();

    bodyFormData.append("companyLogo", formValues.companyLogo);
    bodyFormData.append("company_name", formValues.company_name);
    bodyFormData.append("first_name", formValues.first_name);
    bodyFormData.append("last_name", formValues.last_name);
    bodyFormData.append("phone", formValues.phone);
    bodyFormData.append("city", formValues.city);
    bodyFormData.append("zip_code", formValues.zip_code);
    bodyFormData.append("state", formValues.state);

    const result: any = await updateUserData(bodyFormData);
    if (result.payload.status) {
      getProfileData();
      setSubmitting(false);
      toastBuilder("success", "profile update successfully");
    }
  };

  return (
    <>
      <div className="input-fields-area contact-form ml-2">
        <Formik
          validationSchema={BasicInfoForUpdate}
          onSubmit={handleSubmit}
          initialValues={inistate}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            errors,
          }) => {
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <div className="fields-wrapper">
                    <div className="form-group">
                      <h4>Profile Info</h4>
                    </div>
                  </div>
                  <div className="fields-wrapper">
                    <div className="form-group">
                      <label>First Name</label>
                      <div className="form-field">
                        <img src={userIcon} alt="" />
                        <Field
                          type="text"
                          name="first_name"
                          placeholder="John"
                        />
                      </div>
                      <span className="errorMsg">
                        {touched.first_name && errors.first_name}
                      </span>
                    </div>
                    <div className="form-group">
                      <label>Last Name</label>
                      <div className="form-field">
                        <img src={userIcon} alt="" />
                        <Field type="text" name="last_name" placeholder="Doe" />
                      </div>
                      <span className="errorMsg">
                        {touched.last_name && errors.last_name}
                      </span>
                    </div>
                  </div>

                  <div className="fields-wrapper">
                    <div className="form-group">
                      <label>Zip Code</label>
                      <div className="form-field">
                        <img src={pinIcon} alt="" />
                        <Field
                          type="text"
                          name="zip_code"
                          placeholder="99753"
                        />
                      </div>
                      <span className="errorMsg">
                        {touched.zip_code && errors.zip_code}
                      </span>
                    </div>
                    <div className="form-group">
                      <label>Phone</label>
                      <div className="form-field">
                        <img src={phoneIcon} alt="" />
                        <Field
                          type="text"
                          name="phone"
                          placeholder="123 456 7890"
                        />
                      </div>
                      <span className="errorMsg">
                        {touched.phone && errors.phone}
                      </span>
                    </div>
                  </div>

                  <div className="fields-wrapper">
                    <div className="form-group">
                      <label>State</label>
                      <div className="form-field">
                        <img src={stateIcon} alt="" />
                        <select
                          onChange={handleChange}
                          value={values.state}
                          onBlur={handleBlur}
                          name="state"
                        >
                          {States.map((curElem, index) => {
                            return (
                              <option value={curElem} key={index}>
                                {curElem}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <span className="errorMsg">
                        {touched.state && errors.state}
                      </span>
                    </div>
                    <div className="form-group">
                      <label>City</label>
                      <div className="form-field">
                        <img src={cityIcon} alt="" />
                        <Field type="text" name="city" placeholder="New York" />
                      </div>
                      <span className="errorMsg">
                        {touched.city && errors.city}
                      </span>
                    </div>
                  </div>

                  <div className="fields-wrapper mt-4">
                    <div className="form-group">
                      <h4>Company Info</h4>
                    </div>
                  </div>
                  <div className="fields-wrapper">
                    <div className="form-group">
                      <label>Company Name</label>
                      <div className="form-field">
                        <img src={cityIcon} alt="" />
                        <Field
                          type="text"
                          name="company_name"
                          placeholder="company name"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Company Logo</label>
                      <div className="upload-logo">
                        <div className="logo-box">
                          <div className="upload-wrapper flex-row">
                            <img
                              className="avatar rounded-circle"
                              src={logoImage}
                              alt=""
                            />
                            <input
                              type="file"
                              accept="image/png, image/jpeg, image/gif, image/heic, .heic"
                              onChange={(event: any) => {
                                const previewImg = URL.createObjectURL(
                                  event.target.files[0]
                                );
                                setLogoImage(previewImg);
                                setFieldValue(
                                  "companyLogo",
                                  event.target.files[0]
                                );
                              }}
                              name="companyLogo"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-right mt-3">
                    <button type="submit" className="btn btn-medium btn-purple">
                      Update
                    </button>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default RecruiterInfoForm;
