import { createSlice } from "@reduxjs/toolkit";
import { createReducerBuilder } from "src/utils/actions";
import { getChatHeader, getAllMessage } from "./message.actions";

const initialState: any = {
  chatHeader: [],
  chattingList: [],
  msgData: {
    id: "",
    name: "",
    avatar: "",
  },
};

const reducerBuilder = createReducerBuilder<any>();

/* @ts-ignore */
const getAllChatHeader = reducerBuilder(getChatHeader, {
    fulfilled: (state, { payload }: any) => {      
      if (payload?.status) {
        state.chatHeader = payload.data.msg;
      }
    },
});

/* @ts-ignore */
const getMessageList = reducerBuilder(getAllMessage, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.status) {
      state.chattingList = payload.data.msg;
    }
  },
});


const headerSlice = createSlice({
    name: "headerMessage",
    initialState,
    reducers: {
      getMessageData: (state, action) => {
        state.msgData = action.payload;
      },
      set_socket_all_messages: (state, action) => {
        state.chattingList.push(action.payload.msg);
      },
    },
  
    extraReducers: (builder) => {
      getAllChatHeader(builder);
      getMessageList(builder);
    },
});

export const { getMessageData, set_socket_all_messages } = headerSlice.actions;
  
export default headerSlice.reducer;
