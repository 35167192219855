import { createReducerBuilder } from "src/utils/actions";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setcurrentuser, setcurrentuserandtoken } from "src/utils/localstorage";
import {
  updateUserData,
  getProfileData,
  userloginAction,
  signupuserAction,
  UploadImageAction,
  UploadProfileBgAction
} from "./auth.actions";

const initialState: any = {
  token: "",
  user: {
    role: "",
    _id: "",
    name: "",
    email: "",
    avatar: "",
  },
};

const reducerBuilder = createReducerBuilder<any>();

export const logout = createAsyncThunk("logoutaction", (e) => {
  // make any api call for server if need to logout from user
  // rightnow we are not doing this
});

/* @ts-ignore */
const loginReducer = reducerBuilder(userloginAction, {
  fulfilled: (state, { payload }: any) => {
    if (payload.status) {
      const { data } = payload;
      state.token = data.token;
      state.user = data.user;
      setcurrentuserandtoken(data.user, data.token);
    }
  },
});

/* @ts-ignore */
const singupuser = reducerBuilder(signupuserAction, {
  fulfilled: (state, { payload }: any) => {
    if (payload.status) {
      const { data } = payload;
      state.token = data.token;
      state.user = data.user;
      setcurrentuserandtoken(data.user, data.token);
    }
  },
});

/* @ts-ignore */
const UploadImage = reducerBuilder(UploadImageAction, {
  fulfilled: (state, { payload }: any) => {
    if (payload.status) {
      const { data } = payload;
      state.user = data.user;
      setcurrentuser(data.user);
    }
  },
});

/* @ts-ignore */
const UploadProfileBg = reducerBuilder(UploadProfileBgAction, {
  fulfilled: (state, { payload }: any) => {
    if (payload.status) {
      const { data } = payload;
      state.user = data.user;
      setcurrentuser(data.user);
    }
  },
});

/* @ts-ignore */
const updateUserDatareducer = reducerBuilder(updateUserData, {
  fulfilled: (state, { payload }: any) => {
    if (payload.status) {
      const { data } = payload;
      state.user = data.user;
      setcurrentuser(data.user);
    }
  },
});

/* @ts-ignore */
const getProfileDataReducer = reducerBuilder(getProfileData, {
  fulfilled: (state, { payload }: any) => {
    if (payload.status) {
      const { data } = payload;
      state.user = data.user;
      setcurrentuser(data.user);
    }
  },
});

const authslice = createSlice({
  name: "authslice",
  initialState,
  reducers: {
    settokenAndUser: (state, action) => {
      const { token, user } = action.payload;
      state.token = token;
      state.user = user;
    },
  },

  extraReducers: (builder) => {
    loginReducer(builder);
    singupuser(builder);
    UploadImage(builder);
    UploadProfileBg(builder);
    updateUserDatareducer(builder);
    getProfileDataReducer(builder);
  },
});

export const { settokenAndUser } = authslice.actions;

export default authslice.reducer;
