import * as yup from "yup";

export const PostJobSchema = yup.object().shape({
  job_type: yup.string().required("required"),
});

export const DriverPostJobSchema = yup.object().shape({
  jobImage: yup.mixed().required("required"),
  description: yup.string().trim().required("required"),
  title: yup.string().trim().required("required"),
  job_location: yup.string().required("required"),
  job_type: yup.string().required("required"),
  // application_link: yup.string().required("required"),
  state: yup.string().required("required"),
  experience: yup.string().required("required"),
  endorsements: yup
    .array()
    .min(1, "One is required")
    .required("This field is required"),
  role: yup
    .array()
    .min(1, "One is required")
    .required("This field is required"),
  // role: yup.array().required("required"),
  haul_type: yup.string().required("required"),
});

export const SalePostJobSchema = yup.object().shape({
  jobImage: yup.mixed().required("required"),
  description: yup.string().trim().required("required"),
  title: yup.string().trim().required("required"),
  job_location: yup.string().required("required"),
  job_type: yup.string().required("required"),
  // application_link: yup.string().required("required"),
  state: yup.string().required("required"),
  experience: yup.string().required("required"),
  usual_sales_cycles: yup.string().required("required"),
  largest_deal_won: yup.string().required("required"),
  role: yup
    .array()
    .min(1, "One is required")
    .required("This field is required"),
  sales_methodologies: yup
    .array()
    .min(1, "One is required")
    .required("This field is required"),
});

export const DevelopmentPostJobSchema = yup.object().shape({
  jobImage: yup.mixed().required("required"),
  description: yup.string().trim().required("required"),
  title: yup.string().trim().required("required"),
  job_location: yup.string().required("required"),
  job_type: yup.string().required("required"),
  // application_link: yup.string().required("required"),
  state: yup.string().required("required"),
  experience: yup.string().required("required"),
  role: yup
    .array()
    .min(1, "One is required")
    .required("This field is required"),
  stack_experiences: yup
    .array()
    .min(1, "One is required")
    .required("This field is required"),
  skills: yup
    .array()
    .min(1, "One is required")
    .required("This field is required"),
});

export const BroadcastSchema = yup.object().shape({
  message: yup.string().trim().required("required"),
});
