import { useLocation } from "react-router-dom";

// function to get more multipul params
export const UseQueryParams = (...queryOptions: Array<string>): Array<string | null> => {
  const location = useLocation();

  // console.log("location", location);

  const { search } = location ?? "";

  const params = new URLSearchParams(search);
  return queryOptions.map((query: string) => params.get(query));
};
