import io from "socket.io-client";

export default class socketAPI {
  socket;
  constructor(host) {
    this.socket = io.connect(host);

    this.socket.on("connect", (resturn) => { });
  }

  isSocketConnected() {
    return new Promise((resolve, reject) => {
      this.socket.on("connect", () => resolve(true));

      this.socket.on("connect_error", (error) => reject(false));
    });
  }

  connectSocket(host) {
    this.socket = io.connect(host);
  }

  disconnect() {
    return new Promise((resolve) => {
      this.socket.disconnect(() => {
        this.socket = null;
        resolve(null);
      });
    });
  }

  emit(event, data) {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject("No socket connection.");
      return this.socket.emit(event, JSON.stringify(data), (response) => {
        // Response is the optional callback that you can use with socket.io in every request. See 1 above.
        if (response.error) {
          console.error(response.error);
          return reject(response.error);
        }

        return resolve();
      });
    });
  }

  on(event, fun) {
    // No promise is needed here, but we're expecting one in the middleware.

    return new Promise((resolve, reject) => {
      if (!this.socket) return reject("No socket connection.");
      this.socket.on(event, fun);
      resolve();
    });
  }
}
