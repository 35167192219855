import * as yup from "yup";

export const forgetschema = yup.object().shape({
  email: yup.string().required("required"),
});

export const resPassFormValidation = yup.object().shape({
  newpassword: yup.string().required("Required").min(2, "Password is too short - should be 2 chars minimum"),
  passwordConform: yup
    .string()
    .oneOf([yup.ref("newpassword"), null], "Password don't match!")
    .min(2, "Password is too short - should be 2 chars minimum")
    .required("Required"),
});

export const SingupBasicInfoValidation = yup.object().shape({
  first_name: yup.string().trim().required("required"),
  last_name: yup.string().trim().required("required"),
  email: yup.string().email().required("required"),
  phone: yup.number().required("required").typeError('Phone must be a number'),
  state: yup.string().required("required"),
  city: yup.string().trim().required("required"),
  zip_code: yup.string().trim().required("required"),
  conditions: yup.boolean().oneOf([true], "Please check the box"),
  cpassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password don't match!")
    .required("Required"),

  password: yup
    .string()
    .required("required")
    .matches(
      /(?=.*[a-z,A-Z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}.*$/g,
      "Min 6 Characters, One Uppercase, One Number"
    ),
});

export const paymentInfor = yup.object().shape({
  card_number: yup.string().required("required"),
  exp_month: yup.number().min(1, "invalid").max(12, "invalid").required("required"),
  exp_year: yup
    .number()
    /* @ts-ignore*/
    .test("len", "invalid", (val) => val && val.toString().length === 4)
    .min(new Date().getFullYear()),
  cvv: yup.string().required("required"),
});

export const workHistoryForDriver = yup.object().shape({
  driverEmpHistory: yup.array(yup.object({
    company_name: yup.string().trim().required("required"),
    city: yup.string().trim().required("required"),
    state: yup.string().required("required"),
    zip: yup.string().trim().required("required"),
    start_date: yup.string().required("required"),
    end_date: yup.string().required("required"),
    dot: yup.number().required("required").typeError('Dot# must be a number'),
    phone: yup.number().required("required").typeError('Phone must be a number'),
  })).min(1)
});

export const DriverDetails = yup.object().shape({
  idealCompany: yup.string().required("required"),
  experience: yup.string().required("required"),
  endorsements: yup.array().required("required"),
  twicCard: yup.string().required("required"),
  whatYouHaul: yup.string().required("required"),
  accidents: yup.string().required("required"),
  haulType: yup.string().required("required"),
});

export const forgetPassword = yup.object().shape({
  company_name: yup.string().trim().required("required"),
});

export const SingupSalesContact = yup.object().shape({
  first_name: yup.string().trim().required("required"),
  last_name: yup.string().trim().required("required"),
  email: yup.string().email().required("required"),
  city: yup.string().required("required"),
  state: yup.string().required("required"),
  zip_code: yup.string().trim().required("required"),
  conditions: yup.boolean().oneOf([true], "Please check the box"),
  cpassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password don't match!")
    .required("Required"),

  password: yup
    .string()
    .required("required")
    .matches(
      /(?=.*[a-z,A-Z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}.*$/g,
      "Min 6 Characters, One Uppercase, One Number"
    ),
    positions: yup
    .array()
    .min(1, "required")
    .required("This field is required"),
});

export const DevelopmentSalesContact = yup.object().shape({
  first_name: yup.string().trim().required("required"),
  last_name: yup.string().trim().required("required"),
  email: yup.string().email().required("required"),
  city: yup.string().required("required"),
  state: yup.string().required("required"),
  zip_code: yup.string().trim().required("required"),
  conditions: yup.boolean().oneOf([true], "Please check the box"),
  cpassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password don't match!")
    .required("Required"),

  password: yup
    .string()
    .required("required")
    .matches(
      /(?=.*[a-z,A-Z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}.*$/g,
      "Min 6 Characters, One Uppercase, One Number"
    ),
  
});

export const salesSkillsValidation = yup.object().shape({
  futurePlan: yup.string().required("required"),
  likeSale: yup.string().required("required"),
  // salesMethodologies: yup.array().required("required"),
  largestDealWon: yup.string().required("required"),
  usualSalesCycles: yup.string().required("required"),
  salesMethodologies: yup
    .array()
    .min(1, "required")
    .required("This field is required"),
});





export const DevelopmentSkillValidation = yup.object().shape({
  stackExperiences: yup.array().required("required"),
  individualSkills: yup.array().required("required"),
});


export const SingupSalesContactForUpdate = yup.object().shape({
  first_name: yup.string().trim().required("required"),
  last_name: yup.string().trim().required("required"),
  email: yup.string().email().required("required"),
  city: yup.string().required("required"),
  state: yup.string().required("required"),
  zip_code: yup.string().trim().required("required"),
  conditions: yup.boolean().oneOf([true], "Please check the box"),
  
});

export const DeveloperContactForUpdate = yup.object().shape({
  first_name: yup.string().trim().required("required"),
  last_name: yup.string().trim().required("required"),
  email: yup.string().email().required("required"),
  city: yup.string().required("required"),
  state: yup.string().required("required"),
  zip_code: yup.string().trim().required("required"),
  conditions: yup.boolean().oneOf([true], "Please check the box"),
  
});

export const BasicInfoForUpdate = yup.object().shape({
  first_name: yup.string().trim().required("required"),
  last_name: yup.string().trim().required("required"),
  email: yup.string().email().required("required"),
  city: yup.string().required("required"),
  state: yup.string().required("required"),
  zip_code: yup.string().trim().required("required"),
  conditions: yup.boolean().oneOf([true], "Please check the box"),
  
});
