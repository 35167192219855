import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "src/store";

const selectedreducer = (state: RootState) => state.driverSlice;

export const selectUser = createDraftSafeSelector(selectedreducer, (userState) => userState.user);
export const addBankDetails = createDraftSafeSelector(selectedreducer, (userState) => userState.bankDetails);
export const editBankDetails = createDraftSafeSelector(selectedreducer, (userState) => userState.editDetails);
export const totalEarnings = createDraftSafeSelector(selectedreducer, (userState) => userState.totalEarnings);
export const driverAllDetails = createDraftSafeSelector(selectedreducer, (userState) => userState.driverDetails);
export const allDriverEarnings = createDraftSafeSelector(selectedreducer, (userState) => userState.driverEarnings);
