import React from "react";
import { Formik, Field } from "formik";
import { useActions } from "src/actions";
import { useNavigate } from "react-router-dom";
import toastBuilder from "src/utils/toastBuilder";
import { paymentInfor } from "src/validation/singnup";
import cvvIcon from "src/assets/images/icons/cvv-icon.svg";
import cardIcon from "src/assets/images/icons/card-icon.svg";
import calendarIcon from "src/assets/images/icons/calendar-icon.svg";

const PaymentInfo: React.FC<any> = (props) => {
  const navigation = useNavigate();
  const { addpaymentInfo, addrecuriterpaymetninfo } = useActions();

  const handleSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any
  ) => {
    const results: any = await addpaymentInfo(formValues);
    if (results?.payload?.id) {
      const internalresults: any = await addrecuriterpaymetninfo({
        stripe_token: results?.payload?.id,
      });
      if (internalresults.payload.status) {
        toastBuilder("success", "card added successfully");
        navigation({
          pathname: "/my-account",
          search: `?tab=profile`,
        });
      }
      setSubmitting(false);
      resetForm();
    }
  };

  const handleSkip = () => {
    navigation({
      pathname: "/my-account",
      search: `?tab=profile`,
    });
  };

  const initialValues = {
    card_number: "",
    exp_month: "",
    exp_year: "",
    cvv: "",
  };

  return (
    <div className="input-fields-area paymentInfo">
      <Formik
        validationSchema={paymentInfor}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, touched, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Card Number</label>
                <div className="form-field">
                  <img src={cardIcon} alt="" />
                  <Field name="card_number" type="number" placeholder="" />
                </div>
                <span className="errorMsg">
                  {touched.card_number && errors.card_number}
                </span>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Expiry month</label>
                  <div className="form-field">
                    <img src={calendarIcon} alt="" />
                    <Field type="number" name="exp_month" placeholder="" />
                  </div>
                  <span className="errorMsg">
                    {touched.exp_month && errors.exp_month}
                  </span>
                </div>
                <div className="form-group">
                  <label>Expiry Year</label>
                  <div className="form-field">
                    <img src={calendarIcon} alt="" />
                    <Field type="number" name="exp_year" placeholder="" />
                  </div>
                  <span className="errorMsg">
                    {touched.exp_year && errors.exp_year}
                  </span>
                </div>
                <div className="form-group">
                  <label>CVV</label>
                  <div className="form-field">
                    <img src={cvvIcon} alt="" />
                    <Field name="cvv" type="number" placeholder="" />
                  </div>
                  <span className="errorMsg">{touched.cvv && errors.cvv}</span>
                </div>
              </div>

              <div className="text-right info-buttons mt-3 mb-5">
                <button
                  type="submit"
                  className="btn btn-medium btn-purple mr-3"
                >
                  Submit
                </button>
                <button
                  type="submit"
                  className="btn btn-medium btn-purple"
                  onClick={handleSkip}
                >
                  Skip
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PaymentInfo;
