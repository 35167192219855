import { createSlice } from "@reduxjs/toolkit";
import { createReducerBuilder } from "src/utils/actions";
import {  getAllRecruiterJobs, getAllBroadCast , getAllDriverJobs, driverBidOnJob, getJobBidsDetails, acceptJob, getJobDetails} from "./PostJob.actions";

const initialState: any = {
    description: "",
    jobImage: "",
    allJobs: [],
    allBroadCast: [],
    allDriverJobs: [],
    allBidsOnJob: [],
    allJobBidsDetails: [],
    acceptJobRequest: [],
    jobDetails: [],
    jobId:"" ,
    jobsShow : false,
};

const reducerBuilder = createReducerBuilder<any>();

/* @ts-ignore */
const allRecruiterPostJobs = reducerBuilder(getAllRecruiterJobs, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.data) {
      state.allJobs = payload.data.jobs;
    }
  },
});

/* @ts-ignore */
const AllBroadCast = reducerBuilder(getAllBroadCast, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.data) {
      state.allBroadCast = payload.data;
    }
  },
});

/* @ts-ignore */
// const getAllOfJobs = reducerBuilder(getAllJobs, {
//   fulfilled: (state, { payload }: any) => {
//     if (payload?.data) {
//       state.allOfJobs = payload.data.jobs;
//     }
//   },
// });
/* @ts-ignore */
const allDriverPostJobs = reducerBuilder(getAllDriverJobs, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.data) {
      state.allDriverJobs = payload.data.jobs;
    }
  },
});

/* @ts-ignore */
const bidOnAnyJob = reducerBuilder(driverBidOnJob, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.data) {
      state.allBidsOnJob = payload.data.jobs;
    }
  },
});

/* @ts-ignore */
const jobBiderDetails = reducerBuilder(getJobBidsDetails, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.data) {
      state.allJobBidsDetails = payload.data.bids[0].interested_users_info;
    }
  },
});

/* @ts-ignore */
const acceptDriverJob = reducerBuilder(acceptJob, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.data) {
      state.acceptJobRequest = payload.data.job;
    }
  },
});

/* @ts-ignore */
const getPostJobDetails = reducerBuilder(getJobDetails, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.data) {
      state.jobDetails = payload.data;
    }
  },
});

const postJobSlice = createSlice({
    name: "AddpostJob",
    initialState,
    reducers: {
      storeJobId: (state, action) => {
        state.jobId = action.payload;
      },
      showJobsFilter: (state, action) => {
        state.jobsShow = action.payload;
      },
    },
  
    extraReducers: (builder) => {
      allRecruiterPostJobs(builder);
      allDriverPostJobs(builder);
      bidOnAnyJob(builder);
      jobBiderDetails(builder);
      acceptDriverJob(builder);
      getPostJobDetails(builder);
      AllBroadCast(builder);
      // getAllOfJobs(builder)
    },
});
export const { storeJobId , showJobsFilter} = postJobSlice.actions;
export default postJobSlice.reducer;