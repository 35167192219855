import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Field, Formik } from "formik";
import logo from "src/assets/images/logo.png";
import { loginDataTypes } from "src/types/auth";
import { Loginschema } from "src/validation/login";
import emailIcon from "src/assets/images/icons/message-icon.svg";
import passwordIcon from "src/assets/images/icons/lock-icon.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

interface Props {
  handleFormSubmit: (
    formValues: loginDataTypes,
    { resetForm, setSubmitting }: any
  ) => Promise<void>;
}

const Login: React.FC<Props> = ({ handleFormSubmit }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <>
      <section className="signIn-section">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="shadow-box">
          <h2>Sign in to dijiTribe</h2>
          <Formik
            validationSchema={Loginschema}
            onSubmit={handleFormSubmit}
            initialValues={{ email: "", password: "" }}
          >
            {({ handleSubmit, touched, errors }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>User Name / Email</label>
                    <div className="form-field">
                      <img src={emailIcon} alt="" />
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email Address"
                      />
                    </div>
                    <span className="errorMsg">
                      {touched.email && errors.email}
                    </span>
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <div className="form-field password-field">
                      <img src={passwordIcon} alt="" />
                      <Field
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Your Password"
                      />
                      {!showPassword ? (
                        <AiOutlineEyeInvisible
                          className="eyeIcon"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      ) : (
                        <AiOutlineEye
                          className="eyeIcon"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )}
                    </div>
                    <span className="errorMsg">
                      {touched.password && errors.password}
                    </span>
                  </div>

                  <div className="password-forgot">
                    <Link to="/forgot-password">Forgot Password?</Link>
                  </div>

                  <button
                    type="submit"
                    className="btn w-100 btn-medium btn-purple"
                  >
                    Login
                  </button>
                </form>
              );
            }}
          </Formik>

          <div className="account-signUp">
            <p>Don't have account yet?</p>
            <Link to="/signup" className="signUp-link">
              Sign Up
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
