import { createSlice } from "@reduxjs/toolkit";
import { createReducerBuilder } from "src/utils/actions";
import { getAllDrivers, getJoinedTribes, getAllDiscussion, getTribeDetail } from "./Tribe.actions";

const initialState: any = {
    getDrivers: [],
    joinedTribes: [],
    getDiscussion: [],
    tribesInfo:[],
    members:[],
    getTribeData:[],
    allDrivers:[],
    discussion_id: "",
    tribe_id: "",
};

const reducerBuilder = createReducerBuilder<any>();

/* @ts-ignore */
const getTribeDrivers = reducerBuilder(getAllDrivers, {
    fulfilled: (state, { payload }: any) => {
      if (payload?.status) {
        state.getDrivers = payload.data;
      }
    },
});

/* @ts-ignore */
const getAllJoinedTribes = reducerBuilder(getJoinedTribes, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.status) {
      state.joinedTribes = payload.data;
    }
  },
});

/* @ts-ignore */
const getAllDiscussionSlice = reducerBuilder(getAllDiscussion , {
  fulfilled: (state, { payload }: any) => {
    if (payload?.status) {
      state.getTribeData = payload.data;
      state.getDiscussion = payload.data.discussion;
    }
  },
});

/* @ts-ignore */
const getTribeInfo = reducerBuilder(getTribeDetail , {
  fulfilled: (state, { payload }: any) => {
    if (payload?.status) {
      state.tribesInfo = payload.data;
      state.members = payload.data.members;
    }
  },
});

const tribeSlice = createSlice({
    name: "tribeSlice",
    initialState,
    reducers: {
      storeDiscussionId: (state, action) => {
        state.discussion_id = action.payload;
      },

      storeTribeId: (state, action) => {
        state.tribe_id = action.payload;
      },
    },
  
    extraReducers: (builder) => {
      getTribeDrivers(builder);
      getAllJoinedTribes(builder);
      getAllDiscussionSlice(builder);
      getTribeInfo(builder);
    },
});

export const { storeDiscussionId, storeTribeId} = tribeSlice.actions;

export default tribeSlice.reducer;