import React from "react";
import { Roles } from "src/enums";
import { useAppSelector } from "src/store";
import SalesSearchFilter from "./SalesSearchForm";
import DriverSearchFiter from "./driverSearchForm";
import { selectUser } from "src/container/auth/auth.selector";
import DevelopmentSearchFilter from "./DevelopmentSearchForm";

const JobSearchForm: React.FC<{}> = (props) => {
  const selectCurrentuser = useAppSelector(selectUser);

  return (
    <div className="input-fields-area driver-detail">
      {selectCurrentuser.role === Roles.driver && <DriverSearchFiter />}
      {selectCurrentuser.role === Roles.sales && <SalesSearchFilter />}
      {selectCurrentuser.role === Roles.development && (
        <DevelopmentSearchFilter />
      )}
    </div>
  );
};

export default JobSearchForm;
