import React, { useState } from "react";
import { Field, Formik } from "formik";
import { useActions } from "src/actions";
import toastBuilder from "src/utils/toastBuilder";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const ResetPasswordTab: React.FC<{}> = (props) => {
  const { updatecurrentpassword } = useActions();
  const [showPassword, setShowPassword] = useState({
    oldPassIcon: false,
    newPassIcon: false,
    confirmPassIcon: false,
  });

  const initalValues = {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  };

  const handleSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any
  ) => {
    const results: any = await updatecurrentpassword(formValues);
    if (results.payload.status) {
      toastBuilder("success", "password change successfully");
      setSubmitting(false);
      resetForm();
    }
  };

  return (
    <div className="right-tabs reset-password">
      <div className="input-fields-area">
        <Formik
          onSubmit={handleSubmit}
          initialValues={initalValues}
          enableReinitialize={true}
        >
          {({ handleSubmit, touched, errors }) => {
            return (
              <>
                <form onSubmit={handleSubmit}>
                  {/* Old Password Field */}
                  <div className="fields-wrapper">
                    <div className="form-group">
                      <div className="form-field passwordField">
                        <Field
                          type={showPassword.oldPassIcon ? "text" : "password"}
                          name="oldPassword"
                          placeholder="Old Password"
                        />
                        {showPassword.oldPassIcon ? (
                          <AiOutlineEye
                            className="showHideIcon"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                oldPassIcon: !showPassword.oldPassIcon,
                              })
                            }
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            className="showHideIcon"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                oldPassIcon: !showPassword.oldPassIcon,
                              })
                            }
                          />
                        )}
                      </div>
                      <span className="errorMsg">
                        {touched.oldPassword && errors.oldPassword}
                      </span>
                    </div>
                  </div>

                  {/* New Password Field */}
                  <div className="fields-wrapper">
                    <div className="form-group">
                      <div className="form-field">
                        <Field
                          type={showPassword.newPassIcon ? "text" : "password"}
                          name="newPassword"
                          placeholder="New Password"
                        />
                        {showPassword.newPassIcon ? (
                          <AiOutlineEye
                            className="showHideIcon"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                newPassIcon: !showPassword.newPassIcon,
                              })
                            }
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            className="showHideIcon"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                newPassIcon: !showPassword.newPassIcon,
                              })
                            }
                          />
                        )}
                      </div>
                      <span className="errorMsg">
                        {touched.newPassword && errors.newPassword}
                      </span>
                    </div>
                  </div>

                  {/* Confirm Password Field */}
                  <div className="fields-wrapper">
                    <div className="form-group">
                      <div className="form-field">
                        <Field
                          type={
                            showPassword.confirmPassIcon ? "text" : "password"
                          }
                          name="newPasswordConfirm"
                          placeholder="Confirm Password"
                        />
                        {showPassword.confirmPassIcon ? (
                          <AiOutlineEye
                            className="showHideIcon"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                confirmPassIcon: !showPassword.confirmPassIcon,
                              })
                            }
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            className="showHideIcon"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                confirmPassIcon: !showPassword.confirmPassIcon,
                              })
                            }
                          />
                        )}
                      </div>
                      <span className="errorMsg">
                        {touched.newPasswordConfirm &&
                          errors.newPasswordConfirm}
                      </span>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-medium btn-purple mt-4"
                  >
                    Update
                  </button>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPasswordTab;
