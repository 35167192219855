import { useActions } from "../../actions";
import { registerCurrentUser } from "../..";
import LoginForm from "../../components/LogIn";
import { loginDataTypes } from "src/types/auth";

const User: React.FC = () => {
  const { userloginAction } = useActions();

  const handleFormSubmit = async (
    formValues: loginDataTypes,
    { resetForm, setSubmitting }: any
  ) => {
    const action: any = await userloginAction(formValues);
    if (action?.payload?.status) {
      resetForm();
      registerCurrentUser();
    }
    setSubmitting(false);
  };

  return <LoginForm handleFormSubmit={handleFormSubmit} />;
};

export default User;
