import React, { useState } from "react";
import Select from "react-select";
import { models } from "src/enums";
import { store } from "../../store";
import { useActions } from "src/actions";
import { State } from "country-state-city";
import { Formik, Field, Form } from "formik";
import toastBuilder from "src/utils/toastBuilder";
import { getFiltersData } from "src/container/recruiter/recruiter.slice";
import SharedData from "../SharedData/SharedApi";

const FilterForm: React.FC<{}> = (props) => {
  const { set_active_model, getAllApplicants } = useActions();
  const [selectSalesUser, setSelectUserSelect] = useState(false);
  const [selectDriverUser, setSelectDriverUserSelect] = useState(false);
  const [selectDevelopmentUser, setDevelopmentUserSelect] = useState(false);
  const States = State.getStatesOfCountry("US").map((curElem) => {
    return curElem.name;
  });

  const initialValues = {
    free_text: "",
    ideal_company: "",
    years_experience: "",
    endorsements: [],
    largest_deal_won: "",
    usual_sale_cycles: "",
    sales_methodologies: [],
    experience: "",
    haul_type: "",
    twic_card: "",
    what_you_haul: "",
    accidents: "",
    state: [],
    positions: [],
    stack_experiences: [],
    skills: [],
  };

  const handleSubmit = async (formValues: any, { setSubmitting }: any) => {
    formValues.endorsements = formValues.endorsements.flat();
    formValues.positions = formValues.positions.flat();
    formValues.sales_methodologies = formValues.sales_methodologies.flat();
    formValues.stack_experiences = formValues.stack_experiences.flat();
    formValues.skills = formValues.skills.flat();

    const objToReturn: any = {};
    for (const prop in formValues) {
      if (
        formValues[prop] !== undefined &&
        formValues[prop] !== null &&
        formValues[prop] !== ""
      ) {
        const isAnArray = Array.isArray(formValues[prop]);
        if (isAnArray) {
          const newArray = formValues[prop].filter((passedElement: unknown) => {
            return (
              passedElement !== null &&
              passedElement !== undefined &&
              passedElement !== ""
            );
          });
          formValues[prop] = newArray;
        }
        objToReturn[prop] = formValues[prop];
        if (typeof (formValues[prop] === "object")) {
          if (Object.keys(formValues[prop]).length === 0) {
            delete objToReturn[prop];
          }
        }
      }
    }
    store.dispatch(getFiltersData({ filters: objToReturn }));
    //@ts-ignore
    const results: any = await getAllApplicants({ filters: objToReturn });
    if (results.payload.status) {
      toastBuilder("success", "Filters Applied");
      setSubmitting(false);
      set_active_model(models.CLEAR);
    }
  };

  const toggleDriverClick = () => {
    setSelectUserSelect(false);
    setDevelopmentUserSelect(false);
    setSelectDriverUserSelect(true);
  };
  const toggleSalesClick = () => {
    setDevelopmentUserSelect(false);
    setSelectDriverUserSelect(false);
    setSelectUserSelect(true);
  };
  const toggleDevelopmentClick = () => {
    setSelectUserSelect(false);
    setSelectDriverUserSelect(false);
    setDevelopmentUserSelect(true);
  };

  return (
    <>
      <div className="mega-menu active">
        <div className="input-fields-area driver-detail">
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
          >
            {({
              setFieldValue,
              handleSubmit,
              handleChange,
              handleBlur,
              values,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="detail-field-row">
                    <h4>Role: </h4>
                    <div className="detail-info">
                      <span className=" pl-0">
                        <label>
                          <Field
                            type="radio"
                            name="role"
                            value="driver"
                            id="driver"
                            onClick={toggleDriverClick}
                          />
                          <label htmlFor="driver">Driver</label>
                        </label>
                      </span>
                      <span>
                        <label>
                          <Field
                            type="radio"
                            name="role"
                            value="sales"
                            id="sales"
                            onClick={toggleSalesClick}
                          />
                          <label htmlFor="sales">Sales</label>
                        </label>
                      </span>
                      <span className="mr-3">
                        <label>
                          <Field
                            type="radio"
                            name="role"
                            value="development"
                            id="development"
                            onClick={toggleDevelopmentClick}
                          />
                          <label htmlFor="development">Development</label>
                        </label>
                      </span>
                    </div>
                  </div>
                  <div className="detail-field-row">
                    <h4>Free Text: </h4>
                    <Field
                      className="sales-selection"
                      type="text"
                      name="free_text"
                      placeholder="Free Text"
                    />
                  </div>
                  <div className="detail-field-row">
                    <h4>Years Experience: </h4>
                    <select
                      name="years_experience"
                      onChange={handleChange}
                      value={values.years_experience}
                      className="sales-selection"
                    >
                      <option value="">Select</option>
                      <option value="1-3 Year">1-3 years</option>
                      <option value="3-5 Year">3-5 years</option>
                      <option value="5-10 Year">5-10 years</option>
                      <option value="10+ Years">10+ years</option>
                    </select>
                  </div>
                  <div className="detail-field-row ">
                    <h4>States: </h4>
                    <div className="detail-info states ">
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={(value) =>
                          setFieldValue(
                            "state",
                            value.map((curElem) => {
                              return curElem.value;
                            })
                          )
                        }
                        className="multiSelect-dropdown"
                        options={States.map((state: any) => ({
                          value: state,
                          label: state,
                        }))}
                      />
                    </div>
                  </div>

                  {selectDriverUser && (
                    <>
                      <div className="detail-field-row">
                        <h4>Ideal Company For You:</h4>
                        <div className="detail-info">
                          {SharedData.IdealCompany.map((comapny: any) => {
                            return (
                              <span className="pl-0 mr-2" key={comapny.value}>
                                <label>
                                  <Field
                                    type="radio"
                                    value={comapny.value}
                                    name="ideal_company"
                                    id={comapny.value}
                                  />
                                  <label htmlFor={comapny.value}>
                                    {comapny.label}
                                  </label>
                                </label>
                              </span>
                            );
                          })}
                        </div>
                      </div>

                      <div className="detail-field-row">
                        <h4>Endorsements: </h4>
                        <div className="detail-info three-col-grid">
                          {SharedData.Endorsement.map(
                            (endorsement: any, index) => {
                              return (
                                <span
                                  className="pl-0 mr-3"
                                  key={endorsement.value}
                                >
                                  <div>
                                    <Field
                                      id={`endorsements[${index}]`}
                                      name={`endorsements[${index}]`}
                                      value={endorsement.value}
                                      type="checkbox"
                                      className="checkbox-custom"
                                    />
                                    <label
                                      htmlFor={`endorsements[${index}]`}
                                      className="checkbox-custom-label"
                                    >
                                      {endorsement.label}
                                    </label>
                                  </div>
                                </span>
                              );
                            }
                          )}
                        </div>
                      </div>

                      <div className="detail-field-row">
                        <h4>TWIC Card: </h4>
                        <div className="detail-info">
                          {[
                            { label: "Yes", value: true },
                            { label: "No", value: false },
                          ].map((data) => {
                            return (
                              <span className="pl-0 mr-2" key={data.label}>
                                <label>
                                  <Field
                                    type="radio"
                                    value={data.value}
                                    id={data.label}
                                    onChange={(event: any) => {
                                      const value = event.target.value;
                                      if (value === "true")
                                        setFieldValue("twic_card", true);
                                      if (value === "false")
                                        setFieldValue("twic_card", false);
                                    }}
                                    name="twic_card"
                                  />
                                  <label htmlFor={data.label}>
                                    {data.label}
                                  </label>
                                </label>
                              </span>
                            );
                          })}
                        </div>
                      </div>

                      <div className="detail-field-row">
                        <h4>Haul Type: </h4>
                        <div className="detail-info">
                          {SharedData.Haul_Type.map((hualType) => {
                            return (
                              <span className="pl-0 mr-2" key={hualType.value}>
                                <label>
                                  <Field
                                    type="radio"
                                    value={hualType.value}
                                    name="haul_type"
                                    id={hualType.value}
                                  />
                                  <label htmlFor={hualType.value}>
                                    {hualType.label}
                                  </label>
                                </label>
                              </span>
                            );
                          })}
                        </div>
                      </div>

                      <div className="detail-field-row">
                        <h4>What do you Haul?</h4>
                        <div className="detail-info">
                          {SharedData.whatHult.map((hault) => {
                            return (
                              <span className="pl-0 mr-2" key={hault.value}>
                                <label>
                                  <Field
                                    type="radio"
                                    value={hault.value}
                                    name="what_you_haul"
                                    id={hault.value}
                                  />
                                  <label htmlFor={hault.value}>
                                    {hault.label}
                                  </label>
                                </label>
                              </span>
                            );
                          })}
                        </div>
                      </div>

                      <div className="detail-field-row">
                        <h4>Accident in 3 Years?</h4>
                        <div className="detail-info">
                          {SharedData.Accident.map((accident: any) => {
                            return (
                              <span className="pl-0 mr-2" key={accident.value}>
                                <label>
                                  <Field
                                    type="radio"
                                    value={accident.value}
                                    name="accidents"
                                    id={accident.value}
                                  />
                                  <label htmlFor={accident.value}>
                                    {accident.label}
                                  </label>
                                </label>
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  )}
                  {selectSalesUser && (
                    <>
                      <div className="detail-field-row ">
                        <h4>Positions: </h4>
                        <div className="detail-info three-col-grid">
                          {SharedData.Roles.map(
                            (Sales_Role: any, index: number) => {
                              return (
                                <span
                                  className="pl-0 mr-3"
                                  key={Sales_Role.value}
                                >
                                  <div>
                                    <Field
                                      id={`positions[${index}]`}
                                      name={`positions[${index}]`}
                                      value={Sales_Role.value}
                                      type="checkbox"
                                      className="checkbox-custom"
                                    />
                                    <label
                                      htmlFor={`positions[${index}]`}
                                      className="checkbox-custom-label"
                                    >
                                      {Sales_Role.label}
                                    </label>
                                  </div>
                                </span>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <div className="detail-field-row">
                        <h4>Average Deal Size:</h4>
                        <select
                          className="sales-selection"
                          onChange={handleChange}
                          value={values.largest_deal_won}
                          onBlur={handleBlur}
                          name="largest_deal_won"
                        >
                          {SharedData.DealWon.map((curElem) => {
                            return (
                              <option
                                className="w-100 m-0"
                                key={curElem.value}
                                value={curElem.value}
                              >
                                {curElem.text}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="detail-field-row ">
                        <h4>Usual Sales Cycles:</h4>

                        <select
                          className="sales-selection "
                          onChange={handleChange}
                          value={values.usual_sale_cycles}
                          onBlur={handleBlur}
                          name="usual_sale_cycles"
                        >
                          {SharedData.UsualSales.map((curElem) => {
                            return (
                              <option key={curElem.value} value={curElem.value}>
                                {curElem.text}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="detail-field-row ">
                        <h4>Sales Methodology: </h4>
                        <div className="detail-info three-col-grid">
                          {SharedData.Sales_Methodologies.map(
                            (Sales_Methodology: any, index: number) => {
                              return (
                                <span
                                  className="pl-0 mr-3"
                                  key={Sales_Methodology.value}
                                >
                                  <div>
                                    <Field
                                      id={`sales_methodologies[${index}]`}
                                      name={`sales_methodologies[${index}]`}
                                      value={Sales_Methodology.value}
                                      type="checkbox"
                                      className="checkbox-custom"
                                    />
                                    <label
                                      htmlFor={`sales_methodologies[${index}]`}
                                      className="checkbox-custom-label"
                                    >
                                      {Sales_Methodology.label}
                                    </label>
                                  </div>
                                </span>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {selectDevelopmentUser && (
                    <>
                      <div className="detail-field-row">
                        <h4>Stack Experience: </h4>
                        <div className="detail-info four-col-grid">
                          {SharedData.StackExperience.map(
                            (stack_experience: any, index: number) => {
                              return (
                                <span
                                  className="pl-0 mr-3"
                                  key={stack_experience.value}
                                >
                                  <div>
                                    <Field
                                      id={`stack_experiences[${index}]`}
                                      name={`stack_experiences[${index}]`}
                                      value={stack_experience.value}
                                      type="checkbox"
                                      className="checkbox-custom"
                                    />
                                    <label
                                      htmlFor={`stack_experiences[${index}]`}
                                      className="checkbox-custom-label"
                                    >
                                      {stack_experience.label}
                                    </label>
                                  </div>
                                </span>
                              );
                            }
                          )}
                        </div>
                      </div>

                      <div className="detail-field-row">
                        <h4>Individual Skills: </h4>
                        <div className="detail-info four-col-grid">
                          {SharedData.IndividualSkills.map(
                            (individual_skills: any, index: number) => {
                              return (
                                <span
                                  className="pl-0 mr-3"
                                  key={individual_skills.value}
                                >
                                  <div>
                                    <Field
                                      id={`skills[${index}]`}
                                      name={`skills[${index}]`}
                                      value={individual_skills.value}
                                      type="checkbox"
                                      className="checkbox-custom"
                                    />
                                    <label
                                      htmlFor={`skills[${index}]`}
                                      className="checkbox-custom-label"
                                    >
                                      {individual_skills.label}
                                    </label>
                                  </div>
                                </span>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="text-center mt-md-4">
                    <button type="submit" className="btn btn-medium btn-purple">
                      Search Talent
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>

        <div className="close-menu-btn">
          <i
            className="fa-solid fa-x"
            onClick={() => set_active_model(false)}
          ></i>
        </div>
      </div>
      <div className="bg-overlay-fixed"></div>
    </>
  );
};

export default FilterForm;
