import { createSlice } from "@reduxjs/toolkit";
import { createReducerBuilder } from "src/utils/actions";
import { addBankDetails, editBankDetails, getDriverDetails, getDriverEarnings } from "./driver.actions";

const initialState: any = {
  user: {
    role: "",
    _id: "",
    name: "",
    email: "",
    avator: "",
    token: "",
  },
  totalEarnings: "",
  driverDetails: [],
  bankDetails: [],
  editDetails: [],
  driverEarnings: [],
};

const reducerBuilder = createReducerBuilder<any>();

/* @ts-ignore */
const driverAllDetails = reducerBuilder(getDriverDetails, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.status) {
      state.driverDetails = payload.data.user;
    }
  },
});

/* @ts-ignore */
const addDriverBankDetails = reducerBuilder(addBankDetails, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.status) {
      state.bankDetails = payload.data.driver;
    }
  },
});

/* @ts-ignore */
const editDriverBankDetails = reducerBuilder(editBankDetails, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.status) {
      state.editDetails = payload.data.driver;
    }
  },
});

/* @ts-ignore */
const allDriverEarnings = reducerBuilder(getDriverEarnings, {
  fulfilled: (state, { payload }: any) => {
    if (payload?.status) {
      state.driverEarnings = payload.data.earnings;
      state.totalEarnings = payload.data.total_earnings;
    }
  },
});

const driverSlice = createSlice({
  name: "authslice",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    driverAllDetails(builder);
    addDriverBankDetails(builder);
    editDriverBankDetails(builder);
    allDriverEarnings(builder);
  },
});

export default driverSlice.reducer;
