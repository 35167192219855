/* eslint-disable */
export enum Roles {
  recruiter = "recruiter",
  driver = "driver",
  admin = "admin",
  sales = "sales",
  development = "development",
}

export enum models {
  FILTER_MODEL = "filter_model",
  JobSearch_MODEL = "jobSearch_model",
  MESSAGE_MODEL = "message_model",
  NOTIFICATION_MODEL = "notification_model",
  Jobs_MODEL = "jobs_model",
  Feed_MODEL = "feed_model",
  JobDetail_MODEL = "job_model",
  CHAT_MODEL = "chat_model",
  CLEAR = "clear",
  BROADCAST_MESSAGE = "broadcast_message",
  BROADCAST = "broad_cast",
  EDIT_BROADCAST = "edit_broad_cast",
  TRIBE_MESSAGE = "create_tribe_msg",
  USER_PROFILE = "user_profile",
  GROUP_INFO = "tribe_group_info",
  DELETE_TRIBE = "tribe_delete",
  BANK_INFO_MODEL = "bank_info",
  JOIN_TRIBE_POPUP = "join_tribe",
  CREATE_TRIBE_POPUP = "create_tribe",
  GET_TRIBE_LIST_POPUP = "tribe_list",
  EDIT_MESSAGE = "edit_tribe_msg",
  EDIT_TRIBE_POPUP = "edit_tribe_popup",
  EDIT_DESCRIPTION_POPUP = "edit_description_popup"
}