import React from "react";
import { Roles } from "src/enums";
import { MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import userAvatar from "../../assets/images/user-avatar.png";
import { selectUser } from "src/container/auth/auth.selector";
import DefaultProfile from "../../assets/images/side-card-bg.jpg";

const UserCard: React.FC = () => {
  const selectCurrentuser = useAppSelector(selectUser);
  const { UploadProfileBgAction, updateUserData } = useActions();

  const handleProfileBg = (event: any) => {
    const file = event.target.files[0];
    if (file !== undefined && file !== null) {
      var bodyFormData = new FormData();
      bodyFormData.append("image", file);
      UploadProfileBgAction(bodyFormData);
    }
  };

  const handleProfileAvatar = (event: any) => {
    const file = event.target.files[0];
    if (file !== undefined && file !== null) {
      var bodyFormData = new FormData();
      bodyFormData.append("avatar", file);
      updateUserData(bodyFormData);
    }
  };

  return (
    <div className="side-card">
      <div className="card-bg-img">
        <img
          src={selectCurrentuser?.user_card_image || DefaultProfile}
          alt=""
        />
        <label htmlFor="file-upload" className="bg-editIcon">
          <MdEdit />
          <input
            type="file"
            name="avatar"
            id="file-upload"
            onChange={handleProfileBg}
            accept="image/png, image/jpeg, image/gif, image/heic, .heic"
            style={{ opacity: 0 }}
          />
        </label>
      </div>

      <div className="profile-img">
        <div className="profilepic">
          <img
            className="avatar rounded-circle"
            src={selectCurrentuser?.avatar || userAvatar}
            alt="Not_Found"
          />
          <label htmlFor="avatar-upload" className="fileuplaod">
            <i>
              <MdEdit />
            </i>
            <input
              id="avatar-upload"
              type="file"
              accept="image/png, image/jpeg, image/gif, image/heic, .heic"
              name="avatar"
              onChange={handleProfileAvatar}
              style={{ opacity: 0 }}
            />
          </label>
        </div>
      </div>
      <h5>{`${selectCurrentuser?.first_name} ${selectCurrentuser?.last_name}`}</h5>
      <p>
        {selectCurrentuser?.role === Roles.recruiter
          ? selectCurrentuser?.company_name || "Company Name"
          : null}
      </p>
      <p>{`${selectCurrentuser?.city}, ${selectCurrentuser?.state}`}</p>
      <Link to="/my-account" className="btn btn-medium btn-purple">
        Edit Profile
      </Link>
    </div>
  );
};

export default UserCard;
