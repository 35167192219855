import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiClient } from "src/index";

export const addDriverDetails = createAsyncThunk(
  "/skill/addSkills",
  (payload: any) => ApiClient.post("/skill/addSkills", payload)
);

export const addDriverEmploymentHistory = createAsyncThunk(
  "/user/addDriverEmploymentHistory",
  (payload: any) =>
    ApiClient.patch("/user/addDriverEmploymentHistory", payload)
);

export const update_user_driver_details = createAsyncThunk(
  "/driver/update_user_driver_details",
  (payload: any) => ApiClient.patch("/driver/updateDriverData", payload)
);

export const addBankDetails = createAsyncThunk(
  "/applicant/addBankDetails",
  (payload: any) => ApiClient.post("/applicant/addBankDetails", payload)
);

export const editBankDetails = createAsyncThunk(
  "/applicant/editBankDetails",
  (payload: any) => ApiClient.post("/applicant/editBankDetails", payload)
);

export const getBankDetails = createAsyncThunk("/applicant/getBankDetails", (payload, thunkApi) => {
  const result = ApiClient.get(`/applicant/getBankDetails`);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const getDriverDetails = createAsyncThunk(
  "/user/getUserDetails",
  (payload: any) => ApiClient.post("/user/getUserDetails", payload)
);

export const getDriverEarnings = createAsyncThunk(
  "/applicant/earnings/all",
  (payload: any) => ApiClient.get("/applicant/earnings/" + payload)
);
