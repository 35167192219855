import React from "react";
import { Roles } from "src/enums";
import { useActions } from "src/actions";
import { Formik, Field, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { DriverDetails } from "src/validation/singnup";
import SharedData from "../SharedData/SharedApi";

const DriverDetail: React.FC<{}> = (props) => {
  const { addDriverDetails } = useActions();
  const navigation = useNavigate();

  const handleSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any
  ) => {
    formValues.endorsements = formValues.endorsements.flat();

    const objToReturn: any = {};
    for (const prop in formValues) {
      if (
        formValues[prop] !== undefined &&
        formValues[prop] !== null &&
        formValues[prop] !== ""
      ) {
        const isAnArray = Array.isArray(formValues[prop]);
        if (isAnArray) {
          const newArray = formValues[prop].filter((passedElement: any) => {
            return passedElement !== null && passedElement !== undefined;
          });
          formValues[prop] = newArray;
        }
        objToReturn[prop] = formValues[prop];
      }
    }

    const results: any = await addDriverDetails(objToReturn);
    if (results.payload.status) {
      navigation({
        pathname: "/user",
        search: `?tab=payment-info&role=${Roles.driver}`,
      });
      setSubmitting(false);
      resetForm();
    }
  };

  const initialValues = {
    idealCompany: "",
    experience: "",
    endorsements: [],
    haulType: "",
    twicCard: "",
    whatYouHaul: "",
    accidents: "",
  };

  return (
    <div className="input-fields-area driver-detail">
      <Formik
        validationSchema={DriverDetails}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="detail-field-row">
                <h4>Ideal Company for you: </h4>
                <div className="detail-info">
                  <span>
                    <label>
                      <Field
                        type="radio"
                        name="idealCompany"
                        value="Carrier"
                        id="Carrier"
                      />
                      <label htmlFor="Carrier">Carrier</label>
                    </label>
                  </span>
                  <span>
                    <label>
                      <Field
                        type="radio"
                        name="idealCompany"
                        value="Private fleet"
                        id="Private"
                      />
                      <label htmlFor="Private">Private Fleet</label>
                    </label>
                  </span>
                  <span>
                    <label>
                      <Field
                        type="radio"
                        name="idealCompany"
                        value="Both"
                        id="Both"
                      />
                      <label htmlFor="Both">Both</label>
                    </label>
                  </span>
                  <span className="errorMsg">
                    {touched.idealCompany && errors.idealCompany}
                  </span>
                </div>
              </div>

              <div className="detail-field-row">
                <h4>Years Experience: </h4>
                <select
                  name="experience"
                  onChange={handleChange}
                  value={values.experience}
                  className="exp-dropdown ml-2"
                >
                  <option value="">Select</option>
                  <option value="1-3 Year">1-3 years</option>
                  <option value="3-5 Year">2-5 years</option>
                  <option value="5-10 Year">5-10 years</option>
                  <option value="10+ Years">10+ years</option>
                </select>
                <span className="errorMsg">
                  {touched.experience && errors.experience}
                </span>
              </div>

              <div className="detail-field-row">
                <h4>Endorsements: </h4>
                <div className="detail-info">
                  {SharedData.Endorsement.map(
                    (endorsement: any, index: number) => {
                      return (
                        <span key={endorsement.value}>
                          <div>
                            <Field
                              id={`endoserments[${index}]`}
                              name={`endorsements[${index}]`}
                              value={endorsement.value}
                              type="checkbox"
                              className="checkbox-custom"
                            />
                            <label
                              htmlFor={`endoserments[${index}]`}
                              className="checkbox-custom-label"
                            >
                              {endorsement.label}
                            </label>
                          </div>
                        </span>
                      );
                    }
                  )}
                  <span className="errorMsg">
                    {touched.endorsements && errors.endorsements}
                  </span>
                </div>
              </div>

              <div className="detail-field-row">
                <h4>TWIC Card: </h4>
                <div className="detail-info">
                  <span>
                    <label>
                      <Field
                        type="radio"
                        value="yes"
                        name="twicCard"
                        id="yes"
                      />
                      <label htmlFor="yes">Yes</label>
                    </label>
                  </span>
                  <span>
                    <label>
                      <Field type="radio" value="no" name="twicCard" id="no" />
                      <label htmlFor="no">No</label>
                    </label>
                  </span>
                  <span className="errorMsg">
                    {touched.twicCard && errors.twicCard}
                  </span>
                </div>
              </div>

              <div className="detail-field-row">
                <h4>Haul Type: </h4>
                <div className="detail-info">
                  {SharedData.Haul_Type.map((hualType) => {
                    return (
                      <span key={hualType.value}>
                        <label>
                          <Field
                            type="radio"
                            value={hualType.value}
                            name="haulType"
                            id={hualType.value}
                          />
                          <label htmlFor={hualType.value}>
                            {hualType.label}
                          </label>
                        </label>
                      </span>
                    );
                  })}
                  <span className="errorMsg">
                    {touched.haulType && errors.haulType}
                  </span>
                </div>
              </div>

              <div className="detail-field-row">
                <h4>What do you Haul?</h4>
                <div className="detail-info">
                  {SharedData.whatHult.map((hault) => {
                    return (
                      <span key={hault.value}>
                        <label>
                          <Field
                            type="radio"
                            value={hault.value}
                            name="whatYouHaul"
                            id={hault.value}
                          />
                          <label htmlFor={hault.value}>{hault.label}</label>
                        </label>
                      </span>
                    );
                  })}
                  <span className="errorMsg">
                    {touched.whatYouHaul && errors.whatYouHaul}
                  </span>
                </div>
              </div>

              <div className="detail-field-row">
                <h4>Accident in 3 Years?</h4>
                <div className="detail-info">
                  {SharedData.Accident.map((accident: any) => {
                    return (
                      <span key={accident.value}>
                        <label>
                          <Field
                            type="radio"
                            value={accident.value}
                            name="accidents"
                            id={accident.value}
                          />
                          <label htmlFor={accident.value}>
                            {accident.label}
                          </label>
                        </label>
                      </span>
                    );
                  })}
                  <span className="errorMsg">
                    {touched.accidents && errors.accidents}
                  </span>
                </div>
              </div>

              <div className="text-right mt-3">
                <button type="submit" className="btn btn-medium btn-purple">
                  Next
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DriverDetail;
