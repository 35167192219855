import React, { useRef } from "react";
import { models } from "src/enums";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import UserIconForm from "../Header/UserIconForm";
import userAvatar from "../../assets/images/user-avatar.png";
import { selectUser } from "src/container/auth/auth.selector";
import { use_active_model } from "src/container/model/model.selector";

const UserProfile: React.FC = (props) => {
  const { set_active_model } = useActions();
  const componentRef = useRef<HTMLDivElement>(null);
  const selectCurrentuser = useAppSelector(selectUser);
  const active_model = useAppSelector(use_active_model);

  return (
    <>
      <div ref={componentRef} className="user-profile-img">
        <ul className="list-unstyled mb-0">
          <li className="dropdown active">
            <img
              className="navProfileImg"
              data-toggle="dropdown"
              onClick={() => set_active_model(models.USER_PROFILE)}
              src={selectCurrentuser?.avatar || userAvatar}
              alt="Not_Found"
            />
            {active_model === models.USER_PROFILE && <UserIconForm />}
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserProfile;
