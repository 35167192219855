import React, { useEffect, useRef } from "react";
import { useActions } from "src/actions";
import { models } from "src/enums";

const FeedPopUp: React.FC<{}> = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { set_active_model } = useActions();

  useEffect(() => {
    const div = ref.current; // corresponding DOM node
    div?.classList.add("active");
    document.body.style.overflow = "hidden";

    return () => {
      div?.classList.remove("active");
      document.body.style.overflow = "scroll";
    };
  }, [ref]);

  return (
    <>
      <div ref={ref} className="sidebar">
        <div className="bar-header">
          <div className="main-heading">
            <h4>News Feed</h4>
          </div>
          <div
            onClick={() => set_active_model(models.CLEAR)}
            className="close-sidebarMenu"
          >
            <i className="fa-solid fa-x"></i>
          </div>
        </div>

        <div className="user-jobs-detail">
          <div className="w-100">
            <div className="user_name">
              <h5>Zain Ahmed</h5>
              <span>11:45 am</span>
            </div>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fuga
              rerum nesciunt repudiandae ex ullam quia? Totam iure, quos
              quibusdam voluptatem eos nihil veritatis assumenda exercitationem
              officiis enim excepturi alias illum?{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="bg-overlay-fixed"></div>
    </>
  );
};

export default FeedPopUp;
