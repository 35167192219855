import { combineReducers } from "@reduxjs/toolkit";
import userstate, { logout } from "./container/auth/auth.slice";
import loaderstate from "./container/Loader/loader.slice";
import modelstate from "./container/model/model.slice";
import recruiter from "./container/recruiter/recruiter.slice";
import driverSlice from "./container/driver/driver.slice";
import headerNotify from "./container/notification/notification.slice";
import headerMessage from "./container/message/message.slice";
import AddpostJob from "./container/PostJob/PostJob.slice";
import tribeSlice from "./container/Tribe/Tribe.slice";

const app_reducer = combineReducers({
  userstate,
  loaderstate,
  modelstate,
  recruiter,
  driverSlice,
  headerNotify,
  headerMessage,
  AddpostJob,
  tribeSlice,
});

const rootReducer = (state: ReduceParams[0], action: ReduceParams[1]) => {
  // clearning state
  if (action.type === logout.fulfilled.toString()) {
    localStorage.clear();

    state = undefined;
  }

  return app_reducer(state, action);
};

export default rootReducer;

type ReduceParams = Parameters<typeof app_reducer>;
