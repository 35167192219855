import React from "react";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "./QuillEditor";
import { useAppSelector } from "src/store";
import { getDiscussionId } from "src/container/Tribe/Tribe.selector";

const RichTextEditor = ({ setEditorValue, setEditorFiles }) => {
  const discussion_id = useAppSelector(getDiscussionId);

  const onEditorChange = (value) => {
    setEditorValue(value);
  };

  const onFilesChange = (files) => {
    setEditorFiles(files);
  };

  return (
    <QuillEditor
      messageId={discussion_id}
      onEditorChange={onEditorChange}
      onFilesChange={onFilesChange}
      placeholder={"Start Typing..."}
    />
  );
};

export default RichTextEditor;
