import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiClient } from "src/index";


export const getHeader = createAsyncThunk("/user/getheader", (payload, thunkApi) => {
    const result = ApiClient.get(`/user/getheader`);
    if (result) return result;
    else thunkApi.rejectWithValue("");
});

export const getAllNotifications = createAsyncThunk("/notification/getAllNotifications", (payload, thunkApi) => {
    const result = ApiClient.get(`/notification/getAllNotifications`);
    if (result) return result;
    else thunkApi.rejectWithValue("");
});

export const markNotificationsRead = createAsyncThunk("/notification/markAllNotificationRead", (payload, thunkApi) => {
    const result = ApiClient.get(`/notification/markAllNotificationRead`);
    if (result) return result;
    else thunkApi.rejectWithValue("");
});