import React from "react";
import { Field, Formik } from "formik";
import { useActions } from "src/actions";
import { Link, useNavigate } from "react-router-dom";
import { DevelopmentSkillValidation } from "src/validation/singnup";
import SharedData from "../SharedData/SharedApi";

const DevelopmentSkills: React.FC = (props) => {
  const navigation = useNavigate();
  const { addDevelopmentSkills } = useActions();
  document.body.style.overflow = "scroll";

  const initialValues = {
    stackExperiences: [],
    individualSkills: [],
  };

  const handleSubmit = async (formValues: any) => {
    formValues.stackExperiences = formValues.stackExperiences.flat();
    formValues.individualSkills = formValues.individualSkills.flat();
    formValues.stackExperiences = formValues.stackExperiences.filter(
      (passedElement: unknown) => {
        return passedElement !== null && passedElement !== undefined;
      }
    );
    formValues.individualSkills = formValues.individualSkills.filter(
      (passedElement: unknown) => {
        return passedElement !== null && passedElement !== undefined;
      }
    );

    const action: any = await addDevelopmentSkills(formValues);
    if (action?.payload?.status) {
      navigation({
        pathname: "/my-account",
        search: `?tab=profile`,
      });
    }
  };

  return (
    <div className="input-fields-area step-two">
      <Formik
        validationSchema={DevelopmentSkillValidation}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {({ handleSubmit, touched, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="driver-detail dev-skills">
                <div className="form-group">
                  <div className="detail-field-row d-block">
                    <label className="w-100">Stack Experience: </label>
                    <div className="detail-info four-col-grid w-100">
                      {SharedData.StackExperience.map(
                        (stackExperiences: any, index: number) => {
                          return (
                            <span key={stackExperiences.value} className="mb-1">
                              <div>
                                <Field
                                  id={`stackExperiences[${index}]`}
                                  name={`stackExperiences[${index}]`}
                                  value={stackExperiences.value}
                                  type="checkbox"
                                  className="checkbox-custom"
                                />
                                <label
                                  htmlFor={`stackExperiences[${index}]`}
                                  className="checkbox-custom-label"
                                >
                                  {stackExperiences.label}
                                </label>
                              </div>
                            </span>
                          );
                        }
                      )}
                      <span className="errorMsg">
                        {touched.stackExperiences && errors.stackExperiences}
                      </span>
                    </div>
                  </div>

                  <div className="detail-field-row d-block">
                    <label className="w-100">Individual Skills: </label>
                    <div className="detail-info four-col-grid w-100">
                      {SharedData.IndividualSkills.map(
                        (individualSkills: any, index: number) => {
                          return (
                            <span key={individualSkills.value} className="mb-1">
                              <div>
                                <Field
                                  id={`individualSkills[${index}]`}
                                  name={`individualSkills[${index}]`}
                                  value={individualSkills.value}
                                  type="checkbox"
                                  className="checkbox-custom"
                                />
                                <label
                                  htmlFor={`individualSkills[${index}]`}
                                  className="checkbox-custom-label"
                                >
                                  {individualSkills.label}
                                </label>
                              </div>
                            </span>
                          );
                        }
                      )}
                      <span className="errorMsg">
                        {touched.individualSkills && errors.individualSkills}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-right mt-5">
                <button type="submit" className="btn btn-medium btn-purple">
                  Submit
                </button>
              </div>
            </form>
          );
        }}
      </Formik>

      <div className="account-signUp">
        <p>Already have account?</p>
        <Link to="/login" className="signUp-link">
          Sign In
        </Link>
      </div>
    </div>
  );
};

export default DevelopmentSkills;
