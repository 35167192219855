import React from "react";
import { Field, Formik } from "formik";
import { useActions } from "src/actions";
import { RiLinkedinLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { UseQueryParams } from "src/utils/GetParams";
import pinIcon from "src/assets/images/icons/location-icon.svg";
import dateIcon from "src/assets/images/icons/calendar-icon.svg";
import mountIcon from "src/assets/images/icons/mountain-icon.svg";
import experienceSkill from "src/assets/images/icons/experience-skill.png";
import SharedData from "../SharedData/SharedApi";

const SalesExperience: React.FC = (props) => {
  document.body.style.overflow = "scroll";
  const navigation = useNavigate();
  const [role] = UseQueryParams("role");
  const { addExperience } = useActions();

  const initialValues = {
    experience: "",
    linkedInLink: "",
    college: "",
    graduationDate: "",
    major: "",
    resume: "",
  };

  const handleSubmit = async (formValues: any) => {
    var bodyFormData = new FormData();
    bodyFormData.append("major", formValues.major);
    bodyFormData.append("resume", formValues.resume);
    bodyFormData.append("college", formValues.college);
    bodyFormData.append("experience", formValues.experience);
    bodyFormData.append("linkedInLink", formValues.linkedInLink);
    bodyFormData.append("graduationDate", formValues.graduationDate);

    const action: any = await addExperience(bodyFormData);
    if (action?.payload?.status) {
      navigation({
        pathname: "/user",
        search: `?tab=payment-info&role=${role}`,
      });
    }
  };

  const handleSkip = () => {
    navigation({
      pathname: "/user",
      search: `?tab=payment-info&role=${role}`,
    });
  };

  return (
    <div className="input-fields-area contact-form">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          handleBlur,
          values,
          touched,
          errors,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Enter College</label>
                  <div className="form-field">
                    <img src={pinIcon} alt="" />
                    <Field
                      type="text"
                      name="college"
                      placeholder="Enter your College Name"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label>Enter LinkedIn Link</label>
                  <div className="form-field">
                    <RiLinkedinLine size={18} />
                    <Field
                      type="text"
                      name="linkedInLink"
                      placeholder="Enter your LinkedIn Link"
                    />
                  </div>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Years Experience</label>
                  <div className="form-field">
                    <img
                      style={{ height: " 20px ", width: "20px" }}
                      src={experienceSkill}
                      alt=""
                    />
                    <select
                      onChange={handleChange}
                      value={values.experience}
                      onBlur={handleBlur}
                      name="experience"
                    >
                      {SharedData.yearsExperience.map((curElem) => {
                        return (
                          <option key={curElem.value} value={curElem.value}>
                            {curElem.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label>Enter Graduation Date</label>
                  <div className="form-field">
                    <img src={dateIcon} alt="" />
                    <Field
                      className="pb-1"
                      type="date"
                      name="graduationDate"
                      placeholder="Enter Graduation Date"
                    />
                  </div>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Major</label>
                  <div className="form-field">
                    <img src={pinIcon} alt="" />
                    <Field type="text" name="major" placeholder="Enter Major" />
                  </div>
                </div>
                <div className="form-group">
                  <label>Upload Resume</label>
                  <div className="upload-logo flex-row">
                    <div className="logo-box">
                      <div className="upload-wrapper flex-row justify-content-start">
                        <img src={mountIcon} alt="" className="resume_icon" />
                        <input
                          type="file"
                          accept=".xlsx, .csv, .pdf, .doc, .docx, .ppt, .pptx, rtf"
                          onChange={(event: any) => {
                            setFieldValue("resume", event.target.files[0]);
                          }}
                          name="resume"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-right info-buttons mt-3">
                <button
                  type="submit"
                  className="btn btn-medium btn-purple mr-3"
                >
                  Next
                </button>
                <button
                  type="submit"
                  className="btn btn-medium btn-purple"
                  onClick={handleSkip}
                >
                  Skip
                </button>
              </div>
            </form>
          );
        }}
      </Formik>

      <div className="account-signUp">
        <p>Already have account?</p>
        <Link to="/login" className="signUp-link">
          Sign In
        </Link>
      </div>
    </div>
  );
};

export default SalesExperience;
