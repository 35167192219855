import React, { useState } from "react";
import Select from "react-select";
import { models } from "src/enums";
import { store } from "../../store";
import { useActions } from "src/actions";
import { State } from "country-state-city";
import { Formik, Field, Form } from "formik";
import toastBuilder from "src/utils/toastBuilder";
import { getFiltersData } from "src/container/recruiter/recruiter.slice";
import SharedData from "../SharedData/SharedApi";

const DevelopmentSearchFilter: React.FC<{}> = (props) => {
  const { set_active_model, getAllDriverJobs } = useActions();
  const [selectStateUser, setStateUserSelect] = useState(false);

  const States = State.getStatesOfCountry("US").map((curElem) => {
    return curElem.name;
  });

  const initialValues = {
    job_location: "",
    skills: [],
    state: [],
    stack_experiences: [],
  };

  const toggleStateClick = () => {
    setStateUserSelect(true);
  };

  const handleSubmit = async (formValues: any, { setSubmitting }: any) => {
    formValues.stack_experiences = formValues.stack_experiences.flat();
    formValues.skills = formValues.skills.flat();

    const objToReturn: any = {};
    for (const prop in formValues) {
      if (
        formValues[prop] !== undefined &&
        formValues[prop] !== null &&
        formValues[prop] !== ""
      ) {
        const isAnArray = Array.isArray(formValues[prop]);
        if (isAnArray) {
          const newArray = formValues[prop].filter((passedElement: unknown) => {
            return passedElement !== null && passedElement !== undefined;
          });
          formValues[prop] = newArray;
        }
        objToReturn[prop] = formValues[prop];
        if (typeof (formValues[prop] === "object")) {
          if (Object.keys(formValues[prop]).length === 0) {
            delete objToReturn[prop];
          }
        }
      }
    }
    store.dispatch(getFiltersData({ filters: objToReturn }));
    //@ts-ignore
    const results: any = await getAllDriverJobs({ filters: objToReturn });
    if (results.payload.status) {
      toastBuilder("success", "Filters Applied");
      setSubmitting(false);
      set_active_model(models.CLEAR);
    }
  };

  return (
    <>
      <div className="mega-menu active">
        <div className="input-fields-area driver-detail">
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
          >
            {({ handleSubmit, setFieldValue }) => {
              return (
                <>
                  <Form onSubmit={handleSubmit}>
                    <div className="detail-field-row">
                      <h4>Job Location: </h4>
                      <div className="detail-info">
                        <span className="p-0">
                          <label>
                            <Field
                              type="radio"
                              name="job_location"
                              value="Remote"
                              id="remote"
                              onClick={() => setStateUserSelect(false)}
                            />
                            <label htmlFor="remote">Remote</label>
                          </label>
                        </span>
                        <span>
                          <label>
                            <Field
                              type="radio"
                              name="job_location"
                              value="State/City"
                              id="state/city"
                              onClick={toggleStateClick}
                            />
                            <label htmlFor="state/city">State/City</label>
                          </label>
                        </span>
                        <span>
                          <label>
                            <Field
                              type="radio"
                              name="job_location"
                              value="Hybrid"
                              id="hybrid"
                              onClick={toggleStateClick}
                            />
                            <label htmlFor="hybrid">Hybrid</label>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div className="detail-field-row">
                      <h4>Stack Experience: </h4>
                      <div className="detail-info four-col-grid">
                        {SharedData.StackExperience.map(
                          (stack_experience: any, index: number) => {
                            return (
                              <span
                                className="p-0 mr-3"
                                key={stack_experience.value}
                              >
                                <div>
                                  <Field
                                    id={`stack_experiences[${index}]`}
                                    name={`stack_experiences[${index}]`}
                                    value={stack_experience.value}
                                    type="checkbox"
                                    className="checkbox-custom"
                                  />
                                  <label
                                    htmlFor={`stack_experiences[${index}]`}
                                    className="checkbox-custom-label"
                                  >
                                    {stack_experience.label}
                                  </label>
                                </div>
                              </span>
                            );
                          }
                        )}
                      </div>
                    </div>

                    <div className="detail-field-row">
                      <h4>Individual Skills: </h4>
                      <div className="detail-info four-col-grid">
                        {SharedData.IndividualSkills.map(
                          (skills: any, index: number) => {
                            return (
                              <span className="p-0 mr-3" key={skills.value}>
                                <div>
                                  <Field
                                    id={`skills[${index}]`}
                                    name={`skills[${index}]`}
                                    value={skills.value}
                                    type="checkbox"
                                    className="checkbox-custom"
                                  />
                                  <label
                                    htmlFor={`skills[${index}]`}
                                    className="checkbox-custom-label"
                                  >
                                    {skills.label}
                                  </label>
                                </div>
                              </span>
                            );
                          }
                        )}
                      </div>
                    </div>

                    {selectStateUser ? (
                      <>
                        <div className="detail-field-row">
                          <h4 className="m-0">States: </h4>
                          <div className="detail-info">
                            <Select
                              isMulti
                              closeMenuOnSelect={false}
                              onChange={(value) =>
                                setFieldValue(
                                  "state",
                                  value.map((curElem) => {
                                    return curElem.value;
                                  })
                                )
                              }
                              className="multiSelect-dropdown"
                              options={States.map((state: any) => ({
                                value: state,
                                label: state,
                              }))}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="text-center mt-md-4">
                      <button
                        type="submit"
                        className="btn btn-medium btn-purple"
                      >
                        Search Jobs
                      </button>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>

        <div className="close-menu-btn">
          <i
            className="fa-solid fa-x"
            onClick={() => set_active_model(false)}
          ></i>
        </div>
      </div>
      <div className="bg-overlay-fixed"></div>
    </>
  );
};

export default DevelopmentSearchFilter;
