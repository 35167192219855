import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "src/store";

const selectedreducer = (state: RootState) => state.recruiter;

export const usepaymentCads = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.paymentCads
);

export const usedefaultCard = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.defaultCard
);

export const driversNumberCount = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.hiredDriversCount
);

export const allHiredDrivers = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.allHiredDriverDetails
);

export const alldriversList = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.alldriversList
);

export const allFiltersData = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.filtersData
);

export const allHiredApplicantSelector = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.allHiredApplicantsDetail
);

export const allApplicantSelector = createDraftSafeSelector(
  selectedreducer,
  (userState) => userState.allApplicantsDetail
);