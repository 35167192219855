import React, { useEffect } from "react";
import { Roles } from "src/enums";
import { useAppSelector } from "src/store";
import { UseQueryParams } from "src/utils/GetParams";
import SignUpHeader from "src/components/SignUp/Header";
import BasicInfo from "src/components/SignUp/BasicInfo";
import { Navigate, useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "./auth.selector";
import SalesSkills from "src/components/SignUp/SalesSkills";
import CompanyInfo from "src/components/SignUp/CompanyInfo";
import PaymentInfo from "src/components/SignUp/PaymentInfo";
import DriverDetail from "src/components/SignUp/DriverDetail";
import SalesContact from "src/components/SignUp/SalesContact";
import SalesExperience from "src/components/SignUp/SalesExperience";
// import PurpleBoxContent from "src/components/SignUp/PurpleBoxContent";
import EmploymentHistory from "src/components/SignUp/EmploymentHistory";
import DevelopmentSkills from "src/components/SignUp/DevelopmentSkills";
import DevelopmentContact from "src/components/SignUp/DevelopmentContact";
import DevelopmentExperience from "src/components/SignUp/DevelopmentExperience";

const SignUp: React.FC<{}> = () => {
  const navigation = useNavigate();
  const token = useAppSelector(selectToken);
  const currnetUser = useAppSelector(selectUser);
  const [tab, role] = UseQueryParams("tab", "role");

  useEffect(() => {
    if (!tab && !token) {
      navigation({
        pathname: "/signup",
        search: `?tab=basic-info&role=${Roles.recruiter}`, //user is not login so pussing him to singup page
      });
    } else if (!tab && token) {
      navigation({
        pathname: "/user",
        search: `?tab=company-info&role=${currnetUser.role}`, // user is login so pushing to complete their remaining steps
      });
    }
  }, [tab]);

  return (
    <section className="signUp-section">
      <div className="custom-container w-100">
        <div className="row align-items-center justify-content-center">
          {/* <div className="col-md-5 px-md-0">
            <PurpleBoxContent />
          </div> */}

          <div className="col-md-7 px-md-0">
            <div className="signUp-area">
              <h2>Sign Up to dijiTribe</h2>
              <SignUpHeader role={role} Activetab={tab} />
              {(tab === "basic-info" && !token && role === Roles.recruiter) ||
              (tab === "basic-info" && !token && role === Roles.driver) ? (
                <BasicInfo role={role} Activetab={tab} />
              ) : tab === "basic-info" && !token && role === Roles.sales ? (
                <SalesContact role={role} Activetab={tab} />
              ) : tab === "basic-info" &&
                !token &&
                role === Roles.development ? (
                <DevelopmentContact role={role} Activetab={tab} />
              ) : tab === "company-info" && role === Roles.recruiter ? (
                <CompanyInfo />
              ) : tab === "payment-info" && role === Roles.recruiter ? (
                <PaymentInfo />
              ) : tab === "company-info" && role === Roles.driver ? (
                <DriverDetail />
              ) : tab === "payment-info" && role === Roles.driver ? (
                <EmploymentHistory />
              ) : tab === "company-info" && role === Roles.sales ? (
                <SalesExperience />
              ) : tab === "payment-info" && role === Roles.sales ? (
                <SalesSkills />
              ) : tab === "company-info" && role === Roles.development ? (
                <DevelopmentExperience />
              ) : tab === "payment-info" && role === Roles.development ? (
                <DevelopmentSkills />
              ) : (
                <Navigate to="/my-account?tab=profile" />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
