const {
  REACT_APP_SOCKET_URL,
  REACT_APP_STRIPE_SECRET_KEY,
} = process.env;

export const config = {
  SOCKET_URL: REACT_APP_SOCKET_URL,
  STRIPE_URL: REACT_APP_STRIPE_SECRET_KEY,
  SERVER_URL:
    process.env.REACT_APP_SERVER_URL_DEV_LOCAL && process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_SERVER_URL_DEV_LOCAL
      : process.env.REACT_APP_SERVER_URL,
  NODE_ENV: process.env.NODE_ENV,
  showsAPIBodies: true,
};
