import React from "react";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { useNavigate } from "react-router-dom";
import { allHiredApplicantSelector } from "src/container/recruiter/recruiter.selector";

const FilterPosts: React.FC<{}> = (props) => {
  const navigation = useNavigate();
  const allHiredApplicants = useAppSelector(allHiredApplicantSelector);
  const { getDriverDetails } = useActions();

  const viewApplicantDetails = async (getDriverList: any) => {
    const results: any = await getDriverDetails({
      user_id: getDriverList?.user_id,
    });
    if (results?.payload?.status) {
      if (getDriverList?.already_hired) {
        navigation({
          pathname: `/viewUserDetails/${getDriverList?.user_id}`,
          search: `?hired=${true}`,
        });
      } else {
        navigation({
          pathname: `/viewUserDetails/${getDriverList?.user_id}`,
          search: `?hired=${false}`,
        });
      }
    }
  };

  return (
    <div className="filter-posts">
      {allHiredApplicants.length === 0 ? (
        <div className="no-story-text">
          <h2>No Talent found...</h2>
        </div>
      ) : (
        <div className="row mt-md-4 mt-0">
          {allHiredApplicants?.map((getApplicantsList: any) => {
            return (
              <div
                className="col-lg-3 col-md-4 col-12 mb-4"
                key={getApplicantsList._id}
              >
                <div className="search-card">
                  <img
                    className="user-avatar"
                    src={getApplicantsList.avatar}
                    alt=""
                  />
                  <div className="detail-wrapper">
                    <div>
                      <h5>{`${getApplicantsList?.first_name} ${getApplicantsList?.last_name}`}</h5>
                      <p>{`${getApplicantsList?.city}, ${getApplicantsList?.state}`}</p>
                      <p>Experience: {getApplicantsList?.experience}</p>
                    </div>
                    <button
                      className="btn btn-medium btn-purple"
                      onClick={() => viewApplicantDetails(getApplicantsList)}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FilterPosts;
