import React, { useEffect, useRef } from "react";
import moment from "moment";
import { models } from "src/enums";
import { store } from "../../store";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { getMessageData } from "src/container/message/message.slice";
import { getChatMessages } from "src/container/message/message.selector";
import { headerEmails } from "src/container/notification/notification.selector";

const MessagePopup: React.FC<{}> = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { set_active_model, getChatHeader } = useActions();
  const emailCount = useAppSelector(headerEmails);
  const getAllMessages = useAppSelector(getChatMessages);

  useEffect(() => {
    getChatHeader();
    const div = ref.current; // corresponding DOM node
    div?.classList.add("active");
    document.body.style.overflow = "hidden";

    return () => {
      div?.classList.remove("active");
      document.body.style.overflow = "scroll";
    };
  }, []);

  const openChatModel = (messageId: string, name: string, avatar: string) => {
    set_active_model(models.CHAT_MODEL);
    store.dispatch(
      getMessageData({ msgData: { id: messageId, name, avatar } })
    );
  };

  return (
    <>
      <div ref={ref} className="sidebar">
        <div className="bar-header">
          <div className="main-heading">
            <h4>Messages</h4>
            <span
              className={
                emailCount === 0 ? "msg-counter d-none" : "msg-counter"
              }
            >
              {emailCount}
            </span>
          </div>
          <div
            onClick={() => set_active_model(models.CLEAR)}
            className="close-sidebarMenu"
          >
            <i className="fa-solid fa-x"></i>
          </div>
        </div>

        {getAllMessages?.map((getMessage: any, index: number) => (
          <div
            className="user-message-box"
            key={index}
            onClick={() =>
              openChatModel(
                getMessage._id,
                getMessage.outer_name,
                getMessage.outer_user_image
              )
            }
          >
            <img src={getMessage?.outer_user_image} alt="" />
            <div className="w-80">
              <div className="user_name">
                <h5>{getMessage?.outer_name}</h5>
                <span>{moment(getMessage.last_date).format("DD-MM-YYYY")}</span>
              </div>
              <p>{getMessage?.last_msg}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="bg-overlay-fixed"></div>
    </>
  );
};

export default MessagePopup;
