import React, { useEffect, useState } from "react";
import moment from "moment";
import { useAppSelector } from "src/store";
import { store } from "../../store";
import { useActions } from "src/actions";
import { models, Roles } from "src/enums";
import Header from "src/components/Header";
import { useNavigate } from "react-router-dom";
import FeedPopUp from "../../components/Header/FeedPopup";
import userAvatar from "../../assets/images/user-avatar.png";
import { selectUser } from "src/container/auth/auth.selector";
import { use_active_model } from "src/container/model/model.selector";
import { getAllNotificationsMsg } from "../notification/notification.selector";
import { getMessageData } from "src/container/message/message.slice";

const JobsPage: React.FC<{}> = (props) => {
  const { set_active_model, getAllNotifications, markNotificationsRead } =
    useActions();
  const [showDetail, setShowDetail] = useState({
    showValue: false,
    jobId: "",
  });

  const active_model = useAppSelector(use_active_model);
  const currentUser = useAppSelector(selectUser);
  const getAllNotification = useAppSelector(getAllNotificationsMsg);
  const navigation = useNavigate();

  useEffect(() => {
    getAllNotifications();
    markNotificationsRead();
  }, []);

  const clickNavigate = ({ eventTag, user_data }: any) => {
    if (eventTag === "new_job") {
      navigation(`/jobDetail/${user_data}`);
    } else if (eventTag === "new_broadcast") {
      navigation(`/jobDetail/${user_data}`);
    } else if (eventTag === "mark_interested") {
      navigation(`/jobDetail/${user_data}`);
    } else if (eventTag === "engage_applicant") {
      navigation(`/jobDetail/${user_data}`);
    } else if (eventTag === "new_message") {
      set_active_model(models.MESSAGE_MODEL);
      return;
    }
  };

  const viewApplicantDetails = async (driverId: any, allData: any) => {
    store.dispatch(
      getMessageData({
        msgData: {
          id: driverId,
          name: allData?.sender_name,
          avatar: allData?.sender_image_url,
        },
      })
    );
    set_active_model(models.CHAT_MODEL);
  };

  return (
    <>
      <Header />
      <section className="home-page feed-page">
        <div className="container-fluid">
          <div className="row justify-content-center">
            {currentUser.role !== Roles.recruiter ? (
              getAllNotification.length === 0 ? (
                <div className="no-story-text">
                  <h2>No Notifications found...</h2>
                </div>
              ) : (
                <div className="col-lg-6 col-md-7 col-12">
                  {getAllNotification.map((notification: any, index: any) => {
                    return (
                      <div
                        className="user-jobs-box"
                        key={index}
                        onClick={() =>
                          setShowDetail({
                            showValue: !showDetail.showValue,
                            jobId: notification._id,
                          })
                        }
                      >
                        <div className="w-100">
                          <div className="notification-body">
                            <div className="d-flex" key={index}>
                              {notification?.eventTag !== "new_job" &&
                              notification?.eventTag !== "engage_applicant" &&
                              notification?.eventTag !== "new_broadcast" ? (
                                <img
                                  src={
                                    notification.sender_image_url || userAvatar
                                  }
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={notification.company_logo || userAvatar}
                                  alt=""
                                />
                              )}

                              <div className=" w-100">
                                <div className="d-flex justify-content-between ">
                                  <div>
                                    {notification?.eventTag !== "new_job" &&
                                    notification?.eventTag !==
                                      "engage_applicant" &&
                                    notification?.eventTag !==
                                      "new_broadcast" ? (
                                      <h5 className="user--name">
                                        {notification.sender_name}
                                      </h5>
                                    ) : (
                                      <h5 className="user--name">
                                        {notification.company_name}
                                      </h5>
                                    )}

                                    <p className="user--country">
                                      {notification.header}
                                    </p>
                                  </div>
                                  <p className="user--country">
                                    {moment(notification.createdAt).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </p>
                                </div>

                                <div className="w-100">
                                  {showDetail.showValue &&
                                    showDetail.jobId === notification._id && (
                                      <div>
                                        <div className="mt-3">
                                          <p>{notification.message}</p>
                                        </div>
                                        <div className="mt-3">
                                          <button
                                            className="btn btn-medium btn-purple mr-3"
                                            onClick={() =>
                                              viewApplicantDetails(
                                                notification.sender_id,
                                                notification
                                              )
                                            }
                                          >
                                            Message
                                          </button>
                                          {notification?.eventTag !==
                                            "new_message" &&
                                            notification?.eventTag !==
                                              "engage_applicant" && (
                                              <button
                                                className="btn btn-medium btn-purple"
                                                onClick={() =>
                                                  clickNavigate(notification)
                                                }
                                              >
                                                See Detail
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )
            ) : getAllNotification.length === 0 ? (
              <div className="no-story-text">
                <h2>No Notifications found...</h2>
              </div>
            ) : (
              <div className="col-lg-6 col-md-7 col-12">
                {getAllNotification.map((notification: any, index: any) => {
                  return (
                    <div
                      className="user-jobs-box"
                      key={index}
                      onClick={() =>
                        setShowDetail({
                          showValue: !showDetail.showValue,
                          jobId: notification._id,
                        })
                      }
                    >
                      <div className="w-100">
                        <div className="notification-body">
                          <div className="d-flex" key={index}>
                            {notification?.eventTag !== "new_job" &&
                            notification?.eventTag !== "engage_applicant" &&
                            notification?.eventTag !== "new_broadcast" ? (
                              <img
                                src={
                                  notification.sender_image_url || userAvatar
                                }
                                alt=""
                              />
                            ) : (
                              <img
                                src={notification.company_logo || userAvatar}
                                alt=""
                              />
                            )}
                            <div className="w-100">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  {notification?.eventTag !== "new_job" &&
                                  notification?.eventTag !==
                                    "engage_applicant" &&
                                  notification?.eventTag !== "new_broadcast" ? (
                                    <h5 className="user--name">
                                      {notification.sender_name}
                                    </h5>
                                  ) : (
                                    <h5 className="user--name">
                                      {notification.company_name}
                                    </h5>
                                  )}

                                  <p className="user--country">
                                    {notification.header}
                                  </p>
                                </div>
                                <p className="user--country">
                                  {moment(notification.createdAt).format(
                                    "DD-MM-YYYY"
                                  )}
                                </p>
                              </div>
                              <div className="w-100">
                                {showDetail.showValue &&
                                  showDetail.jobId === notification._id && (
                                    <div>
                                      <div className="mt-3">
                                        <p>{notification.message}</p>
                                      </div>
                                      <div className="mt-3">
                                        <button
                                          className="btn btn-medium btn-purple"
                                          onClick={() =>
                                            viewApplicantDetails(
                                              notification.sender_id,
                                              notification
                                            )
                                          }
                                        >
                                          Message
                                        </button>
                                        {notification?.eventTag !==
                                          "new_message" &&
                                          notification?.eventTag !==
                                            "engage_applicant" && (
                                            <button
                                              className="btn btn-medium btn-purple user-post-button"
                                              onClick={() =>
                                                clickNavigate(notification)
                                              }
                                            >
                                              See Detail
                                            </button>
                                          )}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </section>
      {active_model === models.Feed_MODEL && <FeedPopUp />}
    </>
  );
};

export default JobsPage;
