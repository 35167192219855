import React, { useState } from "react";
import { store } from "src/store";
import { models } from "src/enums";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { BsThreeDotsVertical } from "react-icons/bs";
import { storeTribeId } from "../../container/Tribe/Tribe.slice";
import { joinedTribesSelector } from "src/container/Tribe/Tribe.selector";

const TribeChatCard: React.FC = () => {
  const [showPostMenu, setShowPostMenu] = useState(false);
  const getJoinedTribes = useAppSelector(joinedTribesSelector);
  const { set_active_model, getAllDiscussion, getTribeDetail } = useActions();

  const storeTribe = (tribe: any) => {
    store.dispatch(storeTribeId(tribe?._id));
    getAllDiscussion(tribe?._id);
    getTribeDetail(tribe?._id);
    localStorage.setItem("tribeName", tribe?.name);
  };

  return (
    <div className="side-card tribe__side-card">
      <div className="card-bg-img">
        <h2>Tribes</h2>
        <div className="userdropdown bg-editIcon">
          <div className="dropdown copybtn align-items-center">
            <BsThreeDotsVertical
              onClick={() => setShowPostMenu(!showPostMenu)}
            />

            <div
              className={`dropdown-menu dropdown-menu-right ${
                showPostMenu ? "show" : null
              }`}
            >
              <div
                className="dropdown-item sharelink"
                onClick={() => {
                  setShowPostMenu(false);
                  set_active_model(models.CREATE_TRIBE_POPUP);
                }}
              >
                Create Tribe
              </div>
              <div
                className="dropdown-item sharelink"
                onClick={() => {
                  setShowPostMenu(false);
                  set_active_model(models.JOIN_TRIBE_POPUP);
                }}
              >
                Join Tribe
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tribe-aside">
        {getJoinedTribes.map(
          (
            tribe: {
              _id: string;
              name: string;
              createdAt: Date;
              image_url: string;
            },
            index: number
          ) => {
            return (
              <div
                className="aside__message-box flex"
                key={index}
                onClick={() => storeTribe(tribe)}
              >
                <img src={tribe?.image_url} alt="Profile" />
                <div className="aside__details">
                  <h3>{tribe?.name}</h3>
                </div>
                <div className="aside__condition">
                  <div className="aside_condition-time">
                    <time>
                      {new Date(tribe.createdAt).toLocaleTimeString()}
                    </time>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default TribeChatCard;
