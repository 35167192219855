import React, { useEffect } from "react";
import { Roles, models } from "src/enums";
import { Link } from "react-router-dom";
import { allRecruiterJobs } from "src/container/PostJob/PostJob.selector";
import { getMessageData } from "src/container/message/message.slice";
import { selectUser } from "src/container/auth/auth.selector";
import { store } from "../../store";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { useNavigate } from "react-router-dom";

const JobFollowed: React.FC<{}> = (props) => {
  const {
    getAllRecruiterJobs,
    getJobBidsDetails,
    set_active_model,
    // getAllDriverJobs,
  } = useActions();
  const navigation = useNavigate();
  const selectCurrentuser = useAppSelector(selectUser);
  const recruiterAllJobs = useAppSelector(allRecruiterJobs);

  const currUser = selectCurrentuser.role === Roles.recruiter;

  useEffect(() => {
    getAllRecruiterJobs();
  }, []);

  const viewAllRequests = async (jobId: any) => {
    const results: any = await getJobBidsDetails({ job_id: jobId });
    if (results?.payload?.status) {
      navigation(`/view-request/${jobId}`);
    }
  };

  const viewApplicantDetails = async (driverId: any, allData: any) => {
    store.dispatch(
      getMessageData({
        msgData: {
          id: driverId,
          name: `${allData?.recruiter_info?.first_name} ${allData?.recruiter_info?.last_name}`,
          avatar: allData?.recruiter_info?.avatar,
        },
      })
    );
    set_active_model(models.CHAT_MODEL);
  };

  return (
    <>
      {recruiterAllJobs.length === 0 ? (
        <div className="no-story-text">
          <h2>No Followed Jobs found...</h2>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 col-md-10 col-12">
              <div>
                {recruiterAllJobs.map((allPostedJob: any, index: number) => {
                  return (
                    <div className="news-item news-border mt-3">
                      <div className="news-text-box">
                        <div className="job-desc d-flex " key={index}>
                          <img
                            className="user-avatar"
                            src={
                              allPostedJob?.company_info?.company_logo?.file_url
                              // currUser
                              //   ? selectCurrentuser?.avatar
                              //   : allPostedJob.recruiter_info.avatar
                            }
                            alt=""
                          />
                          <div className="d-flex justify-content-center align-items-center">
                            <div>
                              <h5 className="user--name">
                                {allPostedJob?.company_info?.company_name}
                              </h5>
                              <p className="user--country">
                                {allPostedJob?.job_title}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="w-100">
                          <div className="job-apply ">
                            <div className="d-flex justify-content-between mt-3">
                              <h5 className="user--name">
                                {/* {allPostedJob?.job_title} */}
                                {currUser
                                  ? `${selectCurrentuser?.first_name} ${selectCurrentuser?.last_name}`
                                  : `${allPostedJob?.recruiter_info?.first_name} ${allPostedJob?.recruiter_info?.last_name}`}
                              </h5>
                              <p className="user--country">
                                {allPostedJob?.job_location}
                              </p>
                            </div>
                            <p className="user--country">
                              {allPostedJob?.description}
                            </p>
                            {/* <img src={allPostedJob?.job_image_url} alt="" /> */}

                            {selectCurrentuser.role === Roles.recruiter ? (
                              <>
                                <button
                                  className="btn btn-medium btn-purple"
                                  onClick={() =>
                                    viewAllRequests(allPostedJob._id)
                                  }
                                  disabled={
                                    allPostedJob?.no_of_users_applied === 0
                                  }
                                >
                                  View Requests ({" "}
                                  {allPostedJob?.no_of_users_applied} )
                                </button>
                              </>
                            ) : null}
                            <Link to={`/jobDetail/${allPostedJob?._id}`}>
                              <button
                                className={`btn btn-medium btn-purple ${
                                  allPostedJob.applied ? "active" : null
                                }`}
                              >
                                See Details
                              </button>
                            </Link>
                            {allPostedJob?.message === true && (
                              <button
                                onClick={() =>
                                  viewApplicantDetails(
                                    allPostedJob?.recruiter_info?.id,
                                    allPostedJob
                                  )
                                }
                                className="btn btn-medium btn-purple user-post-button"
                              >
                                Message
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-3"> </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobFollowed;
