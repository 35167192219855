import React from "react";
import { Roles } from "src/enums";
import { useAppSelector } from "src/store";
import { useNavigate } from "react-router-dom";
import { selectToken, selectUser } from "src/container/auth/auth.selector";

type PropsData = { Activetab: string | null; role: string | null };

const SignUpHeader: React.FC<PropsData> = (props) => {
  const { Activetab, role } = props;
  const navigation = useNavigate();
  const User = useAppSelector(selectUser);
  const userToken = useAppSelector(selectToken);

  return (
    <div className="form-tabs">
      <ul className="list-unstyled">
        <div className="liner" />
        <li
          onClick={() => {
            return (
              !userToken &&
              navigation({
                pathname: "/signup",
                search: `?tab=basicinfo&role=${role}`,
              })
            );
          }}
          className={Activetab === "basic-info" ? "active" : ""}
        >
          <span>1</span>
          {role === Roles.driver
            ? "Basic Info"
            : role === Roles.recruiter
            ? "Basic Info"
            : role === Roles.sales
            ? "Contact"
            : role === Roles.development
            ? "Contact"
            : null}
        </li>

        <li
          onClick={(event) => {
            return (
              userToken &&
              navigation({
                search: `?tab=company-info&role=${User.role}`,
              })
            );
          }}
          className={Activetab === "company-info" ? "active" : ""}
        >
          <span>2</span>
          {role === Roles.recruiter
            ? "Company Info"
            : role === Roles.driver
            ? "Driver Details"
            : role === Roles.sales
            ? "Experience"
            : role === Roles.development
            ? "Experience"
            : null}
        </li>

        <li
          onClick={(event) => {
            return (
              userToken &&
              navigation({
                search: `?tab=payment-info&role=${User.role}`,
              })
            );
          }}
          className={Activetab === "payment-info" ? "active" : ""}
        >
          <span>3</span>
          {role === Roles.recruiter
            ? " Payment Info"
            : role === Roles.driver
            ? "Employment History"
            : role === Roles.sales
            ? "Skills"
            : role === Roles.development
            ? "Skills"
            : null}
        </li>
      </ul>
    </div>
  );
};

export default SignUpHeader;
