import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiClient } from "src/index";

export const userloginAction = createAsyncThunk("/singupuser", async (payload: any, thunkApi) => {
  const result = await ApiClient.post("/user/login", payload);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const signupuserAction = createAsyncThunk("/signupuserAction", async (payload: any, thunkApi) => {
  const result = await ApiClient.post("/user/signup", payload);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const UploadImageAction = createAsyncThunk(
  "/UploadImageAction/553454",
  async (payload: any, thunkApi) => {
    const result = await ApiClient.post("/user/uploadProfile", payload);
    if (result) return result;
    else thunkApi.rejectWithValue("");
  }
);

export const UploadProfileBgAction = createAsyncThunk(
  "/UploadProfileBgAction",
  async (payload: any, thunkApi) => {
    const result = await ApiClient.post("/user/updateCardImage", payload);
    if (result) return result;
    else thunkApi.rejectWithValue("");
  }
);

export const SubmitCompanyInfo = createAsyncThunk("/SubmitCompanyInfo", async (payload: any, thunkApi) => {
  const result = await ApiClient.post("/user/uploadProfle", payload);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const forgetuserpassword = createAsyncThunk("/forgetuserpassword", async (payload: any, thunkApi) => {
  const result = await ApiClient.post("/user/forgetPassword", payload);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const setuserpassword = createAsyncThunk("/setuserpassword", async (payload: any, thunkApi) => {
  const result = await ApiClient.post("/user/forgetPassword", payload);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const resetpassword = createAsyncThunk(
  "/resetpassword",
  (payload: { token: string; password: string }, thunkApi) => {
    const result = ApiClient.patch(`/user/restPassword/${payload.token}`, payload);
    if (result) return result;
    else thunkApi.rejectWithValue("");
  }
);

export const updatecurrentpassword = createAsyncThunk(
  "/updatecurrentpassword/called",
  (payload: any, thunkApi) => {
    const result = ApiClient.patch(`/user/updatePassword`, payload);
    if (result) return result;
    else thunkApi.rejectWithValue("");
  }
);

export const updateUserData = createAsyncThunk("/updateUserData/updateUserData", (payload: any, thunkApi) => {
  const result = ApiClient.patch(`/user/updateUserData`, payload);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});

export const getProfileData = createAsyncThunk("/profileInfo/profileInfo", (payload, thunkApi) => {
  const result = ApiClient.get(`/user/profileInfo`);
  if (result) return result;
  else thunkApi.rejectWithValue("");
});
