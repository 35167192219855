import React, { useState } from "react";
import { Field, Formik } from "formik";
import { useActions } from "src/actions";
import { useNavigate } from "react-router-dom";
import { UseQueryParams } from "src/utils/GetParams";
import userAvatar from "src/assets/images/user-avatar.png";
import { ValidationCompany } from "src/validation/profile";
import cityIcon from "src/assets/images/icons/city-icon.svg";

const CompanyInfo: React.FC<{}> = (props) => {
  const [role] = UseQueryParams("role");
  const navigation = useNavigate();
  const { AddrecuriterCompanyInfo } = useActions();
  const [logoImage, setLogoImage] = useState("");

  const handleSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any
  ) => {
    var bodyFormData = new FormData();
    bodyFormData.append("fileToBeUploaded", formValues.logo);
    bodyFormData.append("company_name", formValues.company_name);

    const results: any = await AddrecuriterCompanyInfo(bodyFormData);
    if (results?.payload?.data) {
      navigation({
        pathname: "/user",
        search: `?tab=payment-info&role=${role}`,
      });
      setSubmitting(false);
      resetForm();
    }
  };

  return (
    <div className="input-fields-area company-info">
      <Formik
        validationSchema={ValidationCompany}
        onSubmit={handleSubmit}
        initialValues={{ logo: "", company_name: "" }}
      >
        {({ handleSubmit, setFieldValue, touched, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="upload-logo">
                <div className="logo-box">
                  <div className="upload-wrapper">
                    <img
                      className="avatar rounded-circle"
                      src={logoImage || userAvatar}
                      alt=""
                    />
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/gif, image/heic, .heic"
                      onChange={(event: any) => {
                        const previewImg = URL.createObjectURL(
                          event.target.files[0]
                        );
                        setLogoImage(previewImg);
                        setFieldValue("logo", event.target.files[0]);
                      }}
                      name="logo"
                      placeholder=""
                    />
                  </div>
                </div>
                <p>Upload Company Logo</p>
                <span className="errorMsg">{touched.logo && errors.logo}</span>
              </div>

              <div className="form-group">
                <label>Company Name</label>
                <div className="form-field">
                  <img src={cityIcon} alt="" />
                  <Field
                    type="text"
                    name="company_name"
                    placeholder="company name"
                  />
                </div>
                <span className="errorMsg">
                  {touched.company_name && errors.company_name}
                </span>
              </div>

              <div className="text-right mt-3">
                <button type="submit" className="btn btn-medium btn-purple">
                  Next
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CompanyInfo;
