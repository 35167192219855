import React, { useState, useEffect } from "react";
import { Field, Formik } from "formik";
import moment from "moment";
import { useActions } from "src/actions";
import { State } from "country-state-city";
import { CgWebsite } from "react-icons/cg";
import { useAppSelector } from "src/store";
import mountIcon from "src/assets/images/icons/mountain-icon.svg";
import dateIcon from "src/assets/images/icons/calendar-icon.svg";
import experienceSkill from "src/assets/images/icons/experience-skill.png";
import toastBuilder from "src/utils/toastBuilder";
import { SingupSalesContactForUpdate } from "src/validation/singnup";
import userIcon from "src/assets/images/icons/user-icon.svg";
import cityIcon from "src/assets/images/icons/city-icon.svg";
import stateIcon from "src/assets/images/icons/flag-icon.svg";
import pinIcon from "src/assets/images/icons/location-icon.svg";
import emailIcon from "src/assets/images/icons/message-icon.svg";
import { selectUser } from "src/container/auth/auth.selector";
import { RiLinkedinLine } from "react-icons/ri";
import { GiRecycle } from "react-icons/gi";
import { BiCoinStack } from "react-icons/bi";
import SharedData from "../SharedData/SharedApi";

const SalesInfoForm: React.FC<{}> = (props) => {
  document.body.style.overflow = "scroll";
  const currentUser = useAppSelector(selectUser);
  const { updateUserData, getProfileData } = useActions();
  const dates = moment(currentUser?.graduation_date).format("YYYY-MM-DD");
  const [date, setDate] = useState(dates);
  const tdate = new Date();
  const today = moment(tdate).format("YYYY-MM-DD");

  const States = State.getStatesOfCountry("US").map((curElem) => {
    return curElem.name;
  });

  const initalValues = {
    logo: "",
    first_name: "",
    last_name: "",
    email: "",
    city: "",
    state: "Alabama",
    zip_code: "",
    website: "",
    positions: ["one"],
    conditions: true,
    experience: "",
    linkedIn_link: "",
    college: "",
    graduation_date: "",
    major: "",
    resume: "",
    sales_methodologies: [],
    like_sale: "",
    future_plan: "",
    largest_deal_won: "",
    usual_sales_cycles: "",
  };

  const [inistate, setstate] = useState(initalValues);

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    setstate(currentUser);
  }, [currentUser]);

  const handleSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any
  ) => {
    var bodyFormData = new FormData();
    formValues.positions = formValues.positions.flat();
    formValues.sales_methodologies = formValues.sales_methodologies.flat();
    const rolesChecks = formValues.positions.filter(
      (passedElement: unknown) => {
        return passedElement !== null && passedElement !== undefined;
      }
    );
    const salesChecks = formValues.sales_methodologies.filter(
      (passedElement: unknown) => {
        return passedElement !== null && passedElement !== undefined;
      }
    );

    formValues.graduation_date = date;
    rolesChecks.forEach((item: any) => bodyFormData.append("positions", item));
    salesChecks.forEach((item: any) =>
      bodyFormData.append("sales_methodologies", item)
    );
    bodyFormData.append("role", formValues.role);
    bodyFormData.append("city", formValues.city);
    bodyFormData.append("state", formValues.state);
    bodyFormData.append("email", formValues.email);
    bodyFormData.append("zip_code", formValues.zip_code);
    bodyFormData.append("last_name", formValues.last_name);
    bodyFormData.append("first_name", formValues.first_name);
    bodyFormData.append("website", formValues.website);
    if (formValues.college === undefined) {
      delete formValues["college"];
    } else {
      bodyFormData.append("college", formValues.college);
    }
    if (formValues.linkedIn_link === undefined) {
      delete formValues["linkedIn_link"];
    } else {
      bodyFormData.append("linkedIn_link", formValues.linkedIn_link);
    }
    if (formValues.experience === undefined) {
      delete formValues["experience"];
    } else {
      bodyFormData.append("experience", formValues.experience);
    }
    if (formValues.graduation_date === undefined) {
      delete formValues["graduation_date"];
    } else {
      bodyFormData.append("graduation_date", formValues.graduation_date);
    }
    if (formValues.major === undefined) {
      delete formValues["major"];
    } else {
      bodyFormData.append("major", formValues.major);
    }

    bodyFormData.append("resume", formValues.resume);
    bodyFormData.append("largest_deal_won", formValues.largest_deal_won);
    bodyFormData.append("usual_sales_cycles", formValues.usual_sales_cycles);
    bodyFormData.append("future_plan", formValues.future_plan);
    bodyFormData.append("like_sale", formValues.like_sale);

    const results: any = await updateUserData(bodyFormData);
    if (results?.payload?.data) {
      // setSubmitting(false);
      getProfileData();
      toastBuilder("success", "profile update successfully");
      setSubmitting(false);
      resetForm();
    }
  };

  return (
    <div className="input-fields-area contact-form">
      <Formik
        validationSchema={SingupSalesContactForUpdate}
        onSubmit={handleSubmit}
        initialValues={inistate}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          handleBlur,
          values,
          touched,
          errors,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="fields-wrapper">
                <div className="form-group">
                  <h4>Profile Info</h4>
                </div>
              </div>
              <div className="fields-wrapper">
                <div className="form-group">
                  <label>First Name</label>
                  <div className="form-field">
                    <img src={userIcon} alt="" />
                    <Field type="text" name="first_name" placeholder="John" />
                  </div>
                  <span className="errorMsg">
                    {touched.first_name && errors.first_name}
                  </span>
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <div className="form-field">
                    <img src={userIcon} alt="" />
                    <Field type="text" name="last_name" placeholder="Doe" />
                  </div>
                  <span className="errorMsg">
                    {touched.last_name && errors.last_name}
                  </span>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Email</label>
                  <div className="form-field">
                    <img src={emailIcon} alt="" />
                    <Field
                      type="email"
                      name="email"
                      placeholder="info@email.com"
                    />
                  </div>
                  <span className="errorMsg">
                    {touched.email && errors.email}
                  </span>
                </div>
                <div className="form-group">
                  <label>State</label>
                  <div className="form-field">
                    <img src={stateIcon} alt="" />
                    <select
                      onChange={handleChange}
                      value={values.state}
                      onBlur={handleBlur}
                      name="state"
                    >
                      {States.map((curElem, index) => {
                        return (
                          <option value={curElem} key={index}>
                            {curElem}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <span className="errorMsg">
                    {touched.state && errors.state}
                  </span>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Zip Code</label>
                  <div className="form-field">
                    <img src={pinIcon} alt="" />
                    <Field type="text" name="zip_code" placeholder="99753" />
                  </div>
                  <span className="errorMsg">
                    {touched.zip_code && errors.zip_code}
                  </span>
                </div>
                <div className="form-group">
                  <label>Enter Website Url</label>
                  <div className="form-field">
                    <CgWebsite size={20} />
                    <Field
                      type="text"
                      name="website"
                      placeholder="Enter Website Url"
                    />
                  </div>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>City</label>
                  <div className="form-field">
                    <img src={cityIcon} alt="" />
                    <Field type="text" name="city" placeholder="New York" />
                  </div>
                  <span className="errorMsg">
                    {touched.city && errors.city}
                  </span>
                </div>
              </div>

              <div className="driver-detail mt-3">
                <div className="form-group">
                  <div className="detail-field-row align-items-baseline d-block">
                    <label>Pick up to 3: </label>
                    <div className="w-100 detail-info three-col-grid">
                      {SharedData.Positions.map(
                        (position: any, index: number) => {
                          return (
                            <span key={position.value} className="mb-2">
                              <div>
                                <Field
                                  id={`positions[${index}]`}
                                  name="positions"
                                  value={position}
                                  type="checkbox"
                                  className="checkbox-custom"
                                />
                                <label
                                  htmlFor={`positions[${index}]`}
                                  className="checkbox-custom-label"
                                >
                                  {position}
                                </label>
                              </div>
                            </span>
                          );
                        }
                      )}
                      <span className="errorMsg">
                        {touched.positions && errors.positions}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fields-wrapper mt-4">
                <div className="form-group">
                  <h4>Experience</h4>
                </div>
              </div>
              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Enter College</label>
                  <div className="form-field">
                    <img src={pinIcon} alt="" />
                    <Field
                      type="text"
                      name="college"
                      placeholder="Enter your College Name"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label>Your LinkedIn Link</label>
                  <div className="form-field">
                    <RiLinkedinLine size={18} />
                    <Field
                      type="text"
                      name="linkedIn_link"
                      placeholder="Enter your LinkedIn Link"
                    />
                  </div>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Years Experience</label>
                  <div className="form-field">
                    <img
                      style={{ height: " 20px ", width: "20px" }}
                      src={experienceSkill}
                      alt=""
                    />
                    <select
                      onChange={handleChange}
                      value={values.experience}
                      onBlur={handleBlur}
                      name="experience"
                    >
                      {SharedData.yearsExperience.map((curElem) => {
                        return (
                          <option key={curElem.value} value={curElem.value}>
                            {curElem.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label>Graduation Date</label>
                  <div className="form-field">
                    <img src={dateIcon} alt="" />
                    <Field
                      className="pb-1"
                      type="date"
                      value={dates !== today ? date : null}
                      onChange={(event: any) => setDate(event.target.value)}
                      name="graduation_date"
                      placeholder="Enter Graduation Date"
                    />
                  </div>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Major</label>
                  <div className="form-field">
                    <img src={pinIcon} alt="" />
                    <Field type="text" name="major" placeholder="Enter Major" />
                  </div>
                </div>
                <div className="form-group">
                  <label>Upload Resume</label>
                  <div className="upload-logo flex-row">
                    <div className="logo-box">
                      <div className="upload-wrapper flex-row justify-content-start">
                        <img src={mountIcon} alt="" className="resume_icon" />
                        <input
                          type="file"
                          accept=".xlsx, .csv, .pdf, .doc, .docx, .ppt, .pptx, rtf"
                          onChange={(event: any) => {
                            setFieldValue("resume", event.target.files[0]);
                          }}
                          name="resume"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fields-wrapper mt-4">
                <div className="form-group">
                  <h4>Skills</h4>
                </div>
              </div>
              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Average Deal Size</label>
                  <div className="form-field">
                    <img src={stateIcon} alt="" />
                    <select
                      onChange={handleChange}
                      value={values.largest_deal_won}
                      onBlur={handleBlur}
                      name="largest_deal_won"
                    >
                      {SharedData.DealWon.map((curElem) => {
                        return (
                          <option key={curElem.value} value={curElem.value}>
                            {curElem.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <span className="errorMsg">
                    {touched.largestDealWon && errors.largestDealWon}
                  </span> */}
                </div>
                <div className="form-group">
                  <label>Usual Sales Cycles:</label>
                  <div className="form-field">
                    <GiRecycle size={20} />
                    <select
                      onChange={handleChange}
                      value={values.usual_sales_cycles}
                      onBlur={handleBlur}
                      name="usual_sales_cycles"
                    >
                      {SharedData.UsualSales.map((curElem) => {
                        return (
                          <option key={curElem.value} value={curElem.value}>
                            {curElem.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <span className="errorMsg">
                    {touched.usualSalesCycles && errors.usualSalesCycles}
                  </span> */}
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group w-100">
                  <label>
                    Where are you today and where do you want to be in 2 years
                  </label>
                  <div className="form-field">
                    <img src={pinIcon} alt="" />
                    <Field
                      type="text"
                      name="future_plan"
                      placeholder="Free Text"
                    />
                  </div>
                  {/* <span className="errorMsg">
                    {touched.futurePlan && errors.futurePlan}
                  </span> */}
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group w-100">
                  <label>Why Do you Like Sales</label>
                  <div className="form-field">
                    <BiCoinStack size={22} />
                    <Field
                      type="text"
                      name="like_sale"
                      placeholder="Why Do you Like Sales"
                    />
                  </div>
                  {/* <span className="errorMsg">
                    {touched.likeSale && errors.likeSale}
                  </span> */}
                </div>
              </div>

              <div className="driver-detail">
                <div className="form-group">
                  <div className="detail-field-row mt-2 align-items-baseline d-block">
                    <label>Sales Methodology: </label>
                    <div className="detail-info three-col-grid w-100">
                      {SharedData.sales_Methodology.map(
                        (salesMethodologies: any, index: number) => {
                          return (
                            <span
                              key={salesMethodologies.value}
                              className="mb-1"
                            >
                              <div>
                                <Field
                                  id={`sales_methodologies[${index}]`}
                                  name="sales_methodologies"
                                  value={salesMethodologies}
                                  type="checkbox"
                                  className="checkbox-custom"
                                />
                                <label
                                  htmlFor={`sales_methodologies[${index}]`}
                                  className="checkbox-custom-label"
                                >
                                  {salesMethodologies}
                                </label>
                              </div>
                            </span>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-right mt-3">
                <button type="submit" className="btn btn-medium btn-purple">
                  Update
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SalesInfoForm;
