import React from "react";
import { Formik, Field } from "formik";
import { useActions } from "src/actions";
import calendarIcon from "src/assets/images/icons/calendar-icon.svg";
import cardIcon from "src/assets/images/icons/card-icon.svg";
import cvvIcon from "src/assets/images/icons/cvv-icon.svg";
import { paymentInfor } from "src/validation/singnup";
import toastBuilder from "src/utils/toastBuilder";
import { VscChromeClose } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import stripe_blurple from "../../../src/assets/images/stripe-blurple.svg";

const AddPayentDetail: React.FC<any> = ({
  showPaymentCard,
  setShowPaymentCard,
}) => {
  const { driverId, jobId } = useParams();
  const { addpaymentInfo, addrecuriterpaymetninfo, acceptJob } = useActions();

  const acceptJobs = async () => {
    const results: any = await acceptJob({
      job_id: jobId,
      applicant_id: driverId,
    });
    if (results?.payload?.status) {
      toastBuilder("success", "Applicant Engaged successfully");
    }
  };

  const handleSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any
  ) => {
    const results: any = await addpaymentInfo(formValues);
    if (results?.payload?.id) {
      const internalresults: any = await addrecuriterpaymetninfo({
        stripe_token: results?.payload?.id,
      });
      if (internalresults.payload.status) {
        acceptJobs();
        toastBuilder("success", "card added successfully");
        setShowPaymentCard(false);
        resetForm();
      }
      setSubmitting(false);
    }
  };

  const initialValues = {
    card_number: "",
    exp_month: "",
    exp_year: "",
    cvv: "",
  };

  return (
    <>
      {showPaymentCard && (
        <section className="paymentMethods-sec">
          <div className="bg-overlay"></div>
          <div className="input-fields-area paymentInfoStep">
            <VscChromeClose
              onClick={() => setShowPaymentCard(false)}
              className="closeIcon"
            />

            <Formik
              validationSchema={paymentInfor}
              onSubmit={handleSubmit}
              initialValues={initialValues}
            >
              {({ handleSubmit, touched, errors }) => {
                return (
                  <>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label>Card Number</label>
                        <div className="form-field">
                          <img src={cardIcon} alt="" />
                          <Field
                            name="card_number"
                            type="text"
                            placeholder=""
                          />
                        </div>
                        <span className="errorMsg">
                          {touched.card_number && errors.card_number}
                        </span>
                      </div>

                      <div className="fields-wrapper">
                        <div className="form-group">
                          <label>Expiry month</label>
                          <div className="form-field">
                            <img src={calendarIcon} alt="" />
                            <Field
                              type="number"
                              name="exp_month"
                              placeholder=""
                            />
                          </div>
                          <span className="errorMsg">
                            {touched.exp_month && errors.exp_month}
                          </span>
                        </div>
                        <div className="form-group">
                          <label>Expiry Year</label>
                          <div className="form-field">
                            <img src={calendarIcon} alt="" />
                            <Field
                              type="number"
                              name="exp_year"
                              placeholder=""
                            />
                          </div>
                          <span className="errorMsg">
                            {touched.exp_year && errors.exp_year}
                          </span>
                        </div>
                        <div className="form-group">
                          <label>CVV</label>
                          <div className="form-field">
                            <img src={cvvIcon} alt="" />
                            <Field name="cvv" type="number" placeholder="" />
                          </div>
                          <span className="errorMsg">
                            {touched.cvv && errors.cvv}
                          </span>
                        </div>
                      </div>

                      <div className="b-detail-submit d-flex justify-content-between mt-3 mb-3">
                        <button
                          type="submit"
                          className="btn btn-medium btn-purple"
                        >
                          Submit
                        </button>
                        <img
                          className="stripe-icon "
                          src={stripe_blurple}
                          alt="Stripe-icon"
                        />
                      </div>
                    </form>
                  </>
                );
              }}
            </Formik>
          </div>
        </section>
      )}
    </>
  );
};

export default AddPayentDetail;
