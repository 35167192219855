import React, { useState, useEffect } from "react";
import { models } from "src/enums";
import { Formik, Field } from "formik";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import toastBuilder from "src/utils/toastBuilder";
import { PostJobSchema } from "src/validation/postjob";
import { beditModelDataTypes } from "src/types/header";
import userAvatar from "../../assets/images/user-avatar.png";
import { selectUser } from "src/container/auth/auth.selector";
import { allJobDetails } from "src/container/PostJob/PostJob.selector";

const EditBroadCastModel: React.FC<{}> = () => {
  const selectCurrentuser = useAppSelector(selectUser);
  const getAllJobDetails = useAppSelector(allJobDetails);
  const { set_active_model, editJobDetails, getAllRecruiterJobs } =
    useActions();

  const handleSubmit = async (
    formValues: beditModelDataTypes,
    { resetForm, setSubmitting }: any
  ) => {
    var bodyFormData = new FormData();
    bodyFormData.append("jobImage", formValues.jobImage);
    bodyFormData.append("description", formValues.description);
    bodyFormData.append("job_id", getAllJobDetails[0]._id);
    bodyFormData.append("image_url", getAllJobDetails[0]?.job_image_url);

    const results: any = await editJobDetails(bodyFormData);
    if (results?.payload?.status) {
      toastBuilder("success", "Job Edit Successfull");
      set_active_model(models.CLEAR);
      setSubmitting(false);
      getAllRecruiterJobs();
      resetForm();
    }
  };

  const [initialValues, setInitialValues] = useState({
    description: "",
    jobImage: "",
  });

  useEffect(() => {
    setInitialValues({
      description: getAllJobDetails[0]?.description,
      jobImage: getAllJobDetails[0]?.job_image_url,
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [logoImage, setLogoImage] = useState<string>(
    selectCurrentuser?.recruiters_details_info?.company_logo
  );

  return (
    <>
      <div className="create-broadcast edit-broadcast">
        <div className="broadcast-header">
          <h5>Edit a Broadcast</h5>
          <i
            onClick={() => set_active_model(models.CLEAR)}
            className="fa-solid fa-x"
          />
          
        </div>

        <div className="broadcast-body">
          <div className="cast-user">
            <img
              src={selectCurrentuser?.avatar || userAvatar}
              alt="Not_Found"
            />
            <h4>{`${selectCurrentuser?.first_name} ${selectCurrentuser?.last_name}`}</h4>
          </div>

          <Formik
            validationSchema={PostJobSchema}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              touched,
              errors,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    as="textarea"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="description"
                    placeholder="What do you want to talk about?"
                  />
                  <span className="errorMsg">
                    {touched.description && errors.description}
                  </span>

                  <div className="broadcast-footer">
                    <div className="profilepic mb-md-3">
                      <img
                        className="avatar rounded-circle"
                        src={logoImage}
                        alt=""
                      />
                      <label htmlFor="file-upload" className="fileuplaod">
                        <i className="fas fa-camera camera pointer"></i>
                        <input
                          id="file-upload"
                          type="file"
                          accept="image/png, image/jpeg, image/gif, image/heic, .heic"
                          onChange={(event: any) => {
                            const previewImg = URL.createObjectURL(
                              event.target.files[0]
                            );
                            setLogoImage(previewImg);
                            setFieldValue("jobImage", event.target.files[0]);
                          }}
                          name="logo"
                          style={{ opacity: 0 }}
                        />
                      </label>
                    </div>
                    <button type="submit" className="btn btn-medium btn-purple">
                      Edit a Broadcast
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className="bg-overlay-fixed"></div>
    </>
  );
};

export default EditBroadCastModel;
