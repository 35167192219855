import React, { useState, useEffect } from "react";
import { models } from "src/enums";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import toastBuilder from "src/utils/toastBuilder";
import { selectUser } from "src/container/auth/auth.selector";
import { getTribeId } from "src/container/Tribe/Tribe.selector";
import userAvatar from "../../../assets/images/user-avatar.png";
import RichTextEditor from "src/General/TextEditor/RichTextEditor";
import { getDiscussionId } from "src/container/Tribe/Tribe.selector";

const TribeEditMsg: React.FC<{}> = (props) => {
  const tribe_id = useAppSelector(getTribeId);
  const [editorValue, setEditorValue] = useState("");
  const [editorfiles, setEditorFiles] = useState([]);
  const selectCurrentuser = useAppSelector(selectUser);
  const discussion_id = useAppSelector(getDiscussionId);
  const { set_active_model, editDiscussion, getAllDiscussion } = useActions();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  const handleEdit = async () => {
    const payload = {
      discussion: editorValue,
      discussion_id: discussion_id,
      files: editorfiles,
    };
    const results: any = await editDiscussion(payload);
    if (results.payload.status) {
      toastBuilder("success", "Discussion edit successfully");
      set_active_model(models.CLEAR);
      getAllDiscussion(tribe_id);
    }
  };

  return (
    <>
      <div className="create-broadcast tribe-editor">
        <div className="broadcast-header">
          <h5>Edit Discussion</h5>
          <i
            onClick={() => set_active_model(models.CLEAR)}
            className="fa-solid fa-x"
          ></i>
        </div>

        <div className="broadcast-body">
          <div className="cast-user">
            <img
              src={selectCurrentuser?.avatar || userAvatar}
              alt="Not_Found"
            />
            <h4>{`${selectCurrentuser?.first_name} ${selectCurrentuser?.last_name}`}</h4>
          </div>

          <div className="rich-text-editor">
            <RichTextEditor
              setEditorValue={setEditorValue}
              setEditorFiles={setEditorFiles}
            />
          </div>

          <div className="broadcast-footer">
            <button
              className="btn btn-medium btn-purple"
              onClick={() => handleEdit()}
            >
              Edit Discussion
            </button>
          </div>
        </div>
      </div>
      <div className="bg-overlay-fixed" />
    </>
  );
};

export default TribeEditMsg;
