import React from "react";
import { Roles } from "src/enums";
import { Link } from "react-router-dom";
import { useAppSelector } from "src/store";
import { selectUser } from "src/container/auth/auth.selector";

type Props = {
  setselectedTab: any;
  selectedTab: any;
};

const AccountTabs: React.FC<Props> = (props) => {
  const { setselectedTab, selectedTab } = props;
  const currentUser = useAppSelector(selectUser);
  return (
    <div className="account_tabs">
      <ul className="list-unstyled mb-0">
        {[
          {
            label: "Profile",
            value: "ProfileTab",
            permission: "all",
          },
          { label: "Password", value: "ResetPasswordTab", permission: "all" },
          {
            label: "Company",
            value: "CompanyTab",
            permission: Roles.recruiter,
          },
          // {
          //   label: "Driving Detail",
          //   value: "DrivingDetailTab",
          //   permission: Roles.driver,
          // },
        ]
          .map((el, index) => {
            if (el.permission === currentUser.role || el.permission === "all") {
              return (
                <li
                  key={index}
                  className={selectedTab === el.value ? "active" : ""}
                  onClick={() => setselectedTab(el.value)}
                >
                  <Link to="">{el.label}</Link>
                </li>
              );
            }
            return <div key={index}></div>;
          })
          .filter(Boolean)}
      </ul>
    </div>
  );
};

export default AccountTabs;
