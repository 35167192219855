import React, { useState } from "react";
import moment from "moment";
import { models } from "src/enums";
import { store } from "../../store";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import toastBuilder from "src/utils/toastBuilder";
import { BsThreeDotsVertical } from "react-icons/bs";
import userAvatar from "../../assets/images/user-avatar.png";
import { getTribeId } from "../../container/Tribe/Tribe.selector";
import { storeDiscussionId } from "../../container/Tribe/Tribe.slice";
import { getTribeDataSelector } from "../../container/Tribe/Tribe.selector";
import { getAllDiscussionSelector } from "../../container/Tribe/Tribe.selector";

const UserPost: React.FC<{}> = (props) => {
  const user: any = localStorage.getItem("user");
  const userId = JSON.parse(user);
  const tribeName = localStorage.getItem("tribeName");
  const [showMsgDropDown, setShowMsgDropDown] = useState({
    showValue: false,
    msgId: "",
  });
  const tribe_id = useAppSelector(getTribeId);
  const TribeData = useAppSelector(getTribeDataSelector);
  const alldiscussion = useAppSelector(getAllDiscussionSelector);
  const { set_active_model, getAllDiscussion, deleteDiscussion } = useActions();

  const handleDeleteDiscussion = async (id: any) => {
    const results: any = await deleteDiscussion(id);
    if (results.payload.status) {
      toastBuilder("success", "Discussion deleted successfully");
    }
    getAllDiscussion(tribe_id);
  };

  const storeDiscussion = (id: any) => {
    store.dispatch(storeDiscussionId(id));
  };

  return (
    <>
      {tribe_id ? (
        <div className="right-post-side tribe-right">
          <div className="bg-image header mb-4">
            <img
              src={TribeData?.tribeHeaderImage}
              className="w-100 rounded img-responsive"
              alt="headerimage"
            />
            <div className="header-text text-light w-100 rounded-bottom">
              <p className="p-2 m-0">
                {TribeData?.tribeDescription?.slice(0, 65)}
                {TribeData?.tribeDescription?.length > 0 && (
                  <a
                    href="#show"
                    className="pe-auto text-decoration-none text-white ms-3 description-dots"
                    onClick={() =>
                      set_active_model(models.EDIT_DESCRIPTION_POPUP)
                    }
                  >
                    ...
                  </a>
                )}
              </p>
            </div>
          </div>

          <h3 className="mb-4">{tribeName}</h3>
          <div className="start-broadcast mb-5">
            <img className="user-avatar" src={userAvatar} alt="" />
            <div
              className="broradcast-field"
              onClick={() => set_active_model(models.TRIBE_MESSAGE)}
            >
              <input type="text" placeholder="Start a Conversation" />
            </div>
          </div>

          {alldiscussion.map((el: any, index: number) => {
            return (
              <div className="job-desc mb-5" key={index}>
                <div>
                  <img className="user-avatar" src={el.user.avatar} alt="" />
                </div>
                <div className="w-100">
                  <div className="d-flex justify-content-between align-content-center">
                    <div>
                      <h5 className="user--name">{`${el.user.first_name} ${el.user.last_name}`}</h5>
                      <p className="user--country">
                        {moment(el.discussion_time).format("DD/MM/YYYY hh:mm")}
                      </p>
                    </div>

                    {userId?._id === el?.user_id && (
                      <div className="userdropdown bg-editIcon remove-bg">
                        <div className="dropdown copybtn align-items-center">
                          <BsThreeDotsVertical
                            onClick={() =>
                              setShowMsgDropDown({
                                showValue: !showMsgDropDown.showValue,
                                msgId: el._id,
                              })
                            }
                          />

                          <div
                            className={`dropdown-menu dropdown-menu-right ${
                              showMsgDropDown.showValue &&
                              showMsgDropDown.msgId === el._id
                                ? "show"
                                : null
                            }`}
                          >
                            <div
                              className="dropdown-item sharelink"
                              onClick={() => {
                                setShowMsgDropDown({
                                  ...showMsgDropDown,
                                  showValue: false,
                                });
                                handleDeleteDiscussion(el._id);
                              }}
                            >
                              Delete Discission
                            </div>
                            <div
                              className="dropdown-item sharelink"
                              onClick={() => {
                                setShowMsgDropDown({
                                  ...showMsgDropDown,
                                  showValue: false,
                                });
                                storeDiscussion(el._id);
                                set_active_model(models.EDIT_MESSAGE);
                              }}
                            >
                              Edit Discussion
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="job-apply">
                    <div dangerouslySetInnerHTML={{ __html: el.discussion }} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <h3 className="messages-heading">
          Select a tribe to view its discussion...
        </h3>
      )}
    </>
  );
};

export default UserPost;
