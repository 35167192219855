import React, { useEffect } from "react";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { store } from "../../store";
import { models } from "src/enums";
import { useNavigate } from "react-router-dom";
import { allFiltersData } from "src/container/recruiter/recruiter.selector";
import { allApplicantSelector } from "src/container/recruiter/recruiter.selector";
import { getMessageData } from "src/container/message/message.slice";

const AllPosts: React.FC<{}> = (props) => {
  const navigation = useNavigate();
  const getAllApplicantsData = useAppSelector(allApplicantSelector);
  const selectedFilters = useAppSelector(allFiltersData);

  const { getDriverDetails, getAllApplicants, set_active_model } = useActions();

  useEffect(() => {
    getAllApplicants(selectedFilters);
  }, []);

  const viewApplicantDetails = async (
    already_hired: any,
    driverId: any,
    allData: any
  ) => {
    const results: any = await getDriverDetails({
      user_id: driverId,
    });
    if (results?.payload?.status) {
      if (already_hired) {
        store.dispatch(
          getMessageData({
            msgData: {
              id: driverId,
              name: `${allData?.first_name} ${allData?.last_name}`,
              avatar: allData?.avatar,
            },
          })
        );
        set_active_model(models.CHAT_MODEL);
      } else {
        navigation({
          pathname: `/viewUserDetails/${allData?.user_id}`,
          search: `?hired=${false}`,
        });
      }
    }
  };

  return (
    <div className="filter-posts">
      {getAllApplicantsData.length === 0 ? (
        <div className="no-story-text">
          <h2>No Talent found...</h2>
        </div>
      ) : (
        <div className="row mt-md-4 mt-0">
          {getAllApplicantsData?.map((getApplicantsList: any) => {
            return (
              <div
                className="col-lg-3 col-md-4 col-12 mb-4"
                key={getApplicantsList._id}
              >
                <div className="search-card">
                  <img
                    className="user-avatar"
                    src={getApplicantsList.avatar}
                    alt=""
                  />
                  <div className="detail-wrapper">
                    <div>
                      <h5>{`${getApplicantsList?.first_name} ${getApplicantsList?.last_name}`}</h5>
                      {getApplicantsList?.role === "driver" ? (
                        <p>Driver</p>
                      ) : getApplicantsList?.role === "sales" ? (
                        <p>Sales</p>
                      ) : getApplicantsList?.role === "development" ? (
                        <p>Developer</p>
                      ) : (
                        ""
                      )}
                      <p>{`${getApplicantsList?.city}, ${getApplicantsList?.state}`}</p>
                      <p>Experience: {getApplicantsList?.experience}</p>
                    </div>
                    <button
                      className="btn btn-medium btn-purple"
                      onClick={() =>
                        viewApplicantDetails(
                          getApplicantsList.already_hired,
                          getApplicantsList._id,
                          getApplicantsList
                        )
                      }
                    >
                      {/* View Details */}
                      {getApplicantsList.already_hired
                        ? "Message"
                        : "View Details"}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AllPosts;
