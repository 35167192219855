import React, { useEffect, useRef } from "react";
import { models } from "src/enums";
import { useActions } from "src/actions";
import { useNavigate } from "react-router-dom";
import { IoCloseCircleOutline } from "react-icons/io5";

const BankInfoPopup: React.FC<{}> = () => {
  const ref = useRef<HTMLDivElement>(null);
  const navigation = useNavigate();
  const { set_active_model } = useActions();

  useEffect(() => {
    const div = ref.current; // corresponding DOM node
    div?.classList.add("active");
    document.body.style.overflow = "hidden";
    return () => {
      div?.classList.remove("active");
      document.body.style.overflow = "scroll";
    };
  }, []);

  const goToBankDetail = () => {
    navigation("/bank-details");
    set_active_model(models.CLEAR);
  };

  return (
    <>
      <div ref={ref} className="sidebar center-popup">
        <div className="model-info">
          <div
            className="close-icon"
            onClick={() => set_active_model(models.CLEAR)}
          >
            <IoCloseCircleOutline />
          </div>
          <h5>
            You will not engage with any recruiter until you add bank details
          </h5>
          <div className="mt-4">
            <button
              className="btn btn-medium btn-purple"
              onClick={() => goToBankDetail()}
            >
              Add Now
            </button>
            <button
              className="btn btn-medium btn-purple"
              onClick={() => set_active_model(models.CLEAR)}
            >
              Skip for Now
            </button>
          </div>
        </div>
      </div>
      <div className="bg-overlay bg-fixed"></div>
    </>
  );
};

export default BankInfoPopup;
