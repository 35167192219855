import { store } from "src/store";
import {
  set_socket_receive_data,
  set_socket_notify_count,
} from "src/container/notification/notification.slice";
import { set_socket_all_messages } from "src/container/message/message.slice";

// For Message Count
export function team_lead_add_callback(values) {
  store.dispatch(set_socket_receive_data(values));
}

// For Notification Count
export function notify_count_callback(values) {
  store.dispatch(set_socket_notify_count(values));
}

// Fot Getting Chat Messages
export function get_messages_callback(values) {
  store.dispatch(set_socket_all_messages(values));
}
