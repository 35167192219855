import React, { useEffect, useRef } from "react";
import moment from "moment";
import { models } from "src/enums";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { IoIosPersonAdd } from "react-icons/io";
import toastBuilder from "src/utils/toastBuilder";
import { getTribeId } from "src/container/Tribe/Tribe.selector";
import { getTribeDetailSelector } from "src/container/Tribe/Tribe.selector";
import { getTribeDetailMemberSelector } from "src/container/Tribe/Tribe.selector";

const GroupInfoPopUp: React.FC<{}> = () => {
  const ref = useRef<HTMLDivElement>(null);
  const tribe_id = useAppSelector(getTribeId);
  const tribeDetails = useAppSelector(getTribeDetailSelector);
  const memberList = useAppSelector(getTribeDetailMemberSelector);
  const { set_active_model, getTribeDetail, removeMember } = useActions();

  useEffect(() => {
    getTribeDetail(tribe_id);
    const div = ref.current; // corresponding DOM node
    div?.classList.add("active");
    document.body.style.overflow = "hidden";

    return () => {
      div?.classList.remove("active");
      document.body.style.overflow = "scroll";
    };
  }, [ref]);

  const handleRemoveMember = async (memberId: any) => {
    const results: any = await removeMember({
      tribe_id: tribe_id,
      member_id: memberId,
    });
    if (results.payload.status) {
      getTribeDetail(tribe_id);
      toastBuilder("success", "Remove member succesfully");
    }
  };

  return (
    <>
      <div ref={ref} className="sidebar">
        <div className="bar-header">
          <div className="main-heading">
            <h4>Tribe Info</h4>
          </div>
          <div
            onClick={() => set_active_model(models.CLEAR)}
            className="close-sidebarMenu"
          >
            <i className="fa-solid fa-x" />
          </div>
        </div>

        <div className="profile-info">
          <div className="profile-image">
            <img src={tribeDetails.image_url} alt="" />
          </div>
          <div className="heading-text">
            <h5> {tribeDetails.name} </h5>
            <p className="mb-0">{tribeDetails.description}</p>
          </div>
        </div>

        <div className="created-group">
          <p className="mb-5">{`Group created by ${
            tribeDetails.admin_name
          }, on ${moment(tribeDetails.created_at).format("DD/MM/YYYY")}`}</p>
        </div>

        <div className="participants-num">
          <h5>Participants</h5>
          <h4 onClick={() => set_active_model(models.GET_TRIBE_LIST_POPUP)}>
            <IoIosPersonAdd />
          </h4>
        </div>

        {memberList.map((el: any, index: any) => {
          return (
            <div className="user-message-box mem-list" key={index}>
              <img src={el.avatar} alt="" />
              <div className="w-80 delete-icons">
                <div className="user_name">
                  <h5>{`${el.first_name} ${el.last_name}`}</h5>
                  {el.admin && <span className="clr-primary">Tribe Admin</span>}

                  {!el.admin && (
                    <span
                      className="delete-icons"
                      onClick={() => handleRemoveMember(el._id)}
                    >
                      Remove
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="bg-overlay-fixed"></div>
    </>
  );
};

export default GroupInfoPopUp;
