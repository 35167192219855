import React from "react";
import { useActions } from "src/actions";
import { MdDelete } from "react-icons/md";
import { useAppSelector } from "src/store";
import toastBuilder from "src/utils/toastBuilder";
import { getTribeId } from "src/container/Tribe/Tribe.selector";
import { getTribeDetailMemberSelector } from "src/container/Tribe/Tribe.selector";

const TribeMemberCard: React.FC = () => {
  const tribe_id = useAppSelector(getTribeId);
  const { getTribeDetail, removeMember } = useActions();
  const TribeMembers = useAppSelector(getTribeDetailMemberSelector);

  const handleRemoveMember = async (memberId: any) => {
    const results: any = await removeMember({
      tribe_id: tribe_id,
      member_id: memberId,
    });
    if (results.payload.status) {
      toastBuilder("success", "Remove member succesfully");
      getTribeDetail(tribe_id);
    }
  };

  return (
    <>
      {tribe_id && (
        <div className="side-card tribe__side-card">
          <div className="card-bg-img tribe-header mt-3">
            <h5>Tribe Header Member</h5>
          </div>
          <div className="tribe-aside">
            {TribeMembers.map((el: any, index: any) => {
              return (
                <div className="user-message-box mem-list imgBx" key={index}>
                  <img src={el.avatar} alt="" />
                  <div className="w-80 delete-icons">
                    <div className="user_name">
                      <h5 className="m-0">{`${el.first_name} ${el.last_name}`}</h5>
                      {el.admin ? (
                        <span className="badge badge-success text-white">
                          admin
                        </span>
                      ) : (
                        <span
                          className="delete-icons text-danger  "
                          onClick={() => handleRemoveMember(el._id)}
                        >
                          <MdDelete />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default TribeMemberCard;
