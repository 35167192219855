import React, { useState } from "react";
import { Field, Formik } from "formik";
import { useActions } from "src/actions";
import { State } from "country-state-city";
import { CgWebsite } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import { UseQueryParams } from "src/utils/GetParams";
import userAvatar from "src/assets/images/user-avatar.png";
import { DevelopmentSalesContact } from "src/validation/singnup";
import cityIcon from "src/assets/images/icons/city-icon.svg";
import userIcon from "src/assets/images/icons/user-icon.svg";
import stateIcon from "src/assets/images/icons/flag-icon.svg";
import pinIcon from "src/assets/images/icons/location-icon.svg";
import emailIcon from "src/assets/images/icons/message-icon.svg";
import passwordIcon from "src/assets/images/icons/lock-icon.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

type inCommingData = {
  role: string | null;
  Activetab: string;
};

const DevelopmentContact: React.FC<inCommingData> = (props) => {
  document.body.style.overflow = "scroll";
  const [role] = UseQueryParams("role");
  const navigation = useNavigate();
  const { signupuserAction } = useActions();
  const [logoImage, setLogoImage] = useState("");
  const [showPassword, setShowPassword] = useState({
    passwordIcon: false,
    confirmIcon: false,
  });
  const States = State.getStatesOfCountry("US").map((curElem) => {
    return curElem.name;
  });

  const initalValues = {
    first_name: "",
    last_name: "",
    email: "",
    city: "",
    password: "",
    cpassword: "",
    state: "Alabama",
    zip_code: "",
    website: "",
    conditions: true,
  };

  const handleSubmit = async (
    formValues: any,
    { resetForm, setSubmitting }: any
  ) => {
    formValues.role = role;
    var bodyFormData = new FormData();
    bodyFormData.append("role", formValues.role);
    bodyFormData.append("city", formValues.city);
    bodyFormData.append("image", formValues.logo);
    bodyFormData.append("email", formValues.email);
    bodyFormData.append("state", formValues.state);
    bodyFormData.append("zip_code", formValues.zip_code);
    bodyFormData.append("password", formValues.password);
    bodyFormData.append("last_name", formValues.last_name);
    bodyFormData.append("cpassword", formValues.cpassword);
    bodyFormData.append("first_name", formValues.first_name);
    bodyFormData.append("website", formValues.website);

    const action: any = await signupuserAction(bodyFormData);
    if (action?.payload?.status) {
      navigation({
        pathname: "/user",
        search: `?tab=company-info&role=${role}`,
      });
      setSubmitting(false);
      resetForm();
    }
  };

  return (
    <div className="input-fields-area contact-form">
      <Formik
        validationSchema={DevelopmentSalesContact}
        onSubmit={handleSubmit}
        initialValues={initalValues}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          handleBlur,
          values,
          touched,
          errors,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="fields-wrapper">
                <div className="form-group">
                  <label>First Name</label>
                  <div className="form-field">
                    <img src={userIcon} alt="" />
                    <Field type="text" name="first_name" placeholder="John" />
                  </div>
                  <span className="errorMsg">
                    {touched.first_name && errors.first_name}
                  </span>
                </div>
                <div className="form-group">
                  <label>Last Name</label>
                  <div className="form-field">
                    <img src={userIcon} alt="" />
                    <Field type="text" name="last_name" placeholder="Doe" />
                  </div>
                  <span className="errorMsg">
                    {touched.last_name && errors.last_name}
                  </span>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Email</label>
                  <div className="form-field">
                    <img src={emailIcon} alt="" />
                    <Field
                      type="email"
                      name="email"
                      placeholder="info@email.com"
                    />
                  </div>
                  <span className="errorMsg">
                    {touched.email && errors.email}
                  </span>
                </div>
                <div className="form-group">
                  <label>State</label>
                  <div className="form-field">
                    <img src={stateIcon} alt="" />
                    <select
                      onChange={handleChange}
                      value={values.state}
                      onBlur={handleBlur}
                      name="state"
                    >
                      {States.map((curElem, index) => {
                        return (
                          <option value={curElem} key={index}>
                            {curElem}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <span className="errorMsg">
                    {touched.state && errors.state}
                  </span>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Zip Code</label>
                  <div className="form-field">
                    <img src={pinIcon} alt="" />
                    <Field type="text" name="zip_code" placeholder="99753" />
                  </div>
                  <span className="errorMsg">
                    {touched.zip_code && errors.zip_code}
                  </span>
                </div>
                <div className="form-group">
                  <label>Enter Website Url</label>
                  <div className="form-field">
                    <CgWebsite size={20} />
                    <Field
                      type="text"
                      name="website"
                      placeholder="Enter Website Url"
                    />
                  </div>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>City</label>
                  <div className="form-field">
                    <img src={cityIcon} alt="" />
                    <Field type="text" name="city" placeholder="New York" />
                  </div>
                  <span className="errorMsg">
                    {touched.city && errors.city}
                  </span>
                </div>

                <div className="form-group">
                  <label>Upload Photo</label>
                  <div className="upload-logo">
                    <div className="logo-box">
                      <div className="upload-wrapper flex-row">
                        <img
                          className="avatar rounded-circle"
                          src={logoImage || userAvatar}
                          alt=""
                        />
                        <input
                          type="file"
                          accept="image/png, image/jpeg, image/gif, image/heic, .heic"
                          onChange={(event: any) => {
                            const previewImg = URL.createObjectURL(
                              event.target.files[0]
                            );
                            setLogoImage(previewImg);
                            setFieldValue("logo", event.target.files[0]);
                          }}
                          name="logo"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="fields-wrapper">
                <div className="form-group">
                  <label>Password</label>

                  <div className="form-field passwordField">
                    <img src={passwordIcon} alt="" />
                    <Field
                      name="password"
                      type={showPassword.passwordIcon ? "text" : "password"}
                    />
                    {!showPassword.passwordIcon ? (
                      <AiOutlineEyeInvisible
                        className="showHideIcon"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            passwordIcon: !showPassword.passwordIcon,
                          })
                        }
                      />
                    ) : (
                      <AiOutlineEye
                        className="showHideIcon"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            passwordIcon: !showPassword.passwordIcon,
                          })
                        }
                      />
                    )}
                  </div>
                  <span className="errorMsg">
                    {touched.password && errors.password}
                  </span>
                </div>

                <div className="form-group">
                  <label>Confirm Password</label>
                  <div className="form-field">
                    <img src={passwordIcon} alt="" />
                    <Field
                      name="cpassword"
                      type={showPassword.confirmIcon ? "text" : "password"}
                    />
                    {!showPassword.confirmIcon ? (
                      <AiOutlineEyeInvisible
                        className="showHideIcon"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            confirmIcon: !showPassword.confirmIcon,
                          })
                        }
                      />
                    ) : (
                      <AiOutlineEye
                        className="showHideIcon"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            confirmIcon: !showPassword.confirmIcon,
                          })
                        }
                      />
                    )}
                  </div>
                  <span className="errorMsg">
                    {touched.cpassword && errors.cpassword}
                  </span>
                </div>
              </div>

              <div className="terms-agreement">
                <Field name="conditions" type="checkbox" />

                <span>
                  I have read and accepted the{" "}
                  <a
                    className="privacy-policy"
                    href="https://www.termsfeed.com/live/201807ac-6ee4-4031-9890-7afb82581ae5"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </span>

                <span className="termsMsg">
                  {touched.conditions && errors.conditions}
                </span>
              </div>

              <div className="text-right mt-3">
                <button type="submit" className="btn btn-medium btn-purple">
                  Next
                </button>
              </div>
            </form>
          );
        }}
      </Formik>

      <div className="account-signUp">
        <p>Already have account?</p>
        <Link to="/login" className="signUp-link">
          Sign In
        </Link>
      </div>
    </div>
  );
};

export default DevelopmentContact;
