import React, { useEffect } from "react";
import { store } from "../../store";
import Header from "src/components/Header";
import UserPost from "src/components/UserPost";
import { getFiltersData } from "src/container/recruiter/recruiter.slice";

const ProviderHome: React.FC<{}> = (props) => {
  useEffect(() => {
    store.dispatch(getFiltersData({ filters: null }));
    store.dispatch(getFiltersData({ filters: null }));
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3"> </div>
          <div className="col-lg-6 col-md-10 col-12">
            <section className="home-page">
              <UserPost />
            </section>
          </div>
          <div className="col-lg-3 col-md-6 "></div>
        </div>
      </div>
    </>
  );
};

export default ProviderHome;
