import React, { useState } from "react";
import { Field, Formik } from "formik";
import { useActions } from "src/actions";
import { State } from "country-state-city";
import { FiArrowRight } from "react-icons/fi";
import { AiOutlineUserAdd } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import userIcon from "src/assets/images/icons/user-icon.svg";
import phoneIcon from "src/assets/images/icons/phone-icon.svg";
import stateIcon from "src/assets/images/icons/flag-icon.svg";
import cityIcon from "src/assets/images/icons/city-icon.svg";
import pinIcon from "src/assets/images/icons/location-icon.svg";
import passwordIcon from "src/assets/images/icons/lock-icon.svg";
import emailIcon from "src/assets/images/icons/message-icon.svg";
import { SingupBasicInfoValidation } from "src/validation/singnup";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

type inCommingData = {
  role: string | null;
  Activetab: string;
};

const BasicInfo: React.FC<inCommingData> = (props) => {
  const { Activetab } = props;
  const navigation = useNavigate();
  const { signupuserAction } = useActions();
  const [selectDriver, setSelectDriver] = useState(false);
  const [addRolePopup, setAddRolePopup] = useState("RolePopup");
  const [selectedRole, setSelectedRole] = useState("recruiter");
  const [showPassword, setShowPassword] = useState({
    passwordIcon: false,
    confirmIcon: false,
  });

  const States = State.getStatesOfCountry("US").map((curElem) => {
    return curElem.name;
  });

  const DriverOptions = [
    { value: "driver", text: "Driver" },
    { value: "sales", text: "Sales" },
    { value: "development", text: "Development" },
  ];

  const initalValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    city: "",
    zip_code: "",
    password: "",
    cpassword: "",
    conditions: true,
    state: "Alabama",
  };

  const handleSubmit = async (formValues: any) => {
    formValues.role = selectedRole;
    const action: any = await signupuserAction(formValues);
    if (action?.payload?.status) {
      navigation({
        pathname: "/user",
        search: `?tab=company-info&role=${formValues.role}`,
      });
    }
  };

  if (addRolePopup === "RolePopup") {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "scroll";
  }

  const selectRoleApplicant = () => {
    navigation({
      search: `?tab=${Activetab}&role=${selectedRole}`,
    });
  };

  const toggleRecruiterClick = () => {
    setSelectDriver(false);
  };

  const toggleDriverClick = () => {
    setSelectDriver(true);
  };

  const handleDriverChange = (event: any) => {
    setSelectedRole(event.target.value);
  };

  return (
    <>
      <div className="input-fields-area">
        <Formik
          validationSchema={SingupBasicInfoValidation}
          onSubmit={handleSubmit}
          initialValues={initalValues}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => {
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <div className="fields-wrapper">
                    <div className="form-group">
                      <label>First Name</label>
                      <div className="form-field">
                        <img src={userIcon} alt="" />
                        <Field
                          type="text"
                          name="first_name"
                          placeholder="John"
                        />
                      </div>
                      <span className="errorMsg">
                        {touched.first_name && errors.first_name}
                      </span>
                    </div>
                    <div className="form-group">
                      <label>Last Name</label>
                      <div className="form-field">
                        <img src={userIcon} alt="" />
                        <Field type="text" name="last_name" placeholder="Doe" />
                      </div>
                      <span className="errorMsg">
                        {touched.last_name && errors.last_name}
                      </span>
                    </div>
                  </div>

                  <div className="fields-wrapper">
                    <div className="form-group">
                      <label>Email</label>
                      <div className="form-field">
                        <img src={emailIcon} alt="" />
                        <Field
                          type="email"
                          name="email"
                          placeholder="info@email.com"
                        />
                      </div>
                      <span className="errorMsg">
                        {touched.email && errors.email}
                      </span>
                    </div>
                    <div className="form-group">
                      <label>Phone</label>
                      <div className="form-field">
                        <img src={phoneIcon} alt="" />
                        <Field
                          type="text"
                          name="phone"
                          placeholder="123 456 7890"
                        />
                      </div>
                      <span className="errorMsg">
                        {touched.phone && errors.phone}
                      </span>
                    </div>
                  </div>

                  <div className="fields-wrapper">
                    <div className="form-group">
                      <label>State</label>
                      <div className="form-field">
                        <img src={stateIcon} alt="" />
                        <select
                          onChange={handleChange}
                          value={values.state}
                          onBlur={handleBlur}
                          name="state"
                        >
                          {States.map((curElem, index) => {
                            return (
                              <option value={curElem} key={index}>
                                {curElem}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <span className="errorMsg">
                        {touched.state && errors.state}
                      </span>
                    </div>
                    <div className="form-group">
                      <label>City</label>
                      <div className="form-field">
                        <img src={cityIcon} alt="" />
                        <Field type="text" name="city" placeholder="New York" />
                      </div>
                      <span className="errorMsg">
                        {touched.city && errors.city}
                      </span>
                    </div>
                  </div>

                  <div className="fields-wrapper">
                    <div className="form-group">
                      <label>Zip Code</label>
                      <div className="form-field">
                        <img src={pinIcon} alt="" />
                        <Field
                          type="text"
                          name="zip_code"
                          placeholder="99753"
                        />
                      </div>
                      <span className="errorMsg">
                        {touched.zip_code && errors.zip_code}
                      </span>
                    </div>
                  </div>

                  <div className="fields-wrapper">
                    <div className="form-group">
                      <label>Password</label>

                      <div className="form-field passwordField">
                        <img src={passwordIcon} alt="" />
                        <Field
                          name="password"
                          type={showPassword.passwordIcon ? "text" : "password"}
                        />
                        {!showPassword.passwordIcon ? (
                          <AiOutlineEyeInvisible
                            className="showHideIcon"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                passwordIcon: !showPassword.passwordIcon,
                              })
                            }
                          />
                        ) : (
                          <AiOutlineEye
                            className="showHideIcon"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                passwordIcon: !showPassword.passwordIcon,
                              })
                            }
                          />
                        )}
                      </div>
                      <span className="errorMsg">
                        {touched.password && errors.password}
                      </span>
                    </div>

                    <div className="form-group">
                      <label>Confirm Password</label>
                      <div className="form-field">
                        <img src={passwordIcon} alt="" />
                        <Field
                          name="cpassword"
                          type={showPassword.confirmIcon ? "text" : "password"}
                        />
                        {!showPassword.confirmIcon ? (
                          <AiOutlineEyeInvisible
                            className="showHideIcon"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                confirmIcon: !showPassword.confirmIcon,
                              })
                            }
                          />
                        ) : (
                          <AiOutlineEye
                            className="showHideIcon"
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                confirmIcon: !showPassword.confirmIcon,
                              })
                            }
                          />
                        )}
                      </div>
                      <span className="errorMsg">
                        {touched.cpassword && errors.cpassword}
                      </span>
                    </div>
                  </div>

                  <div className="terms-agreement">
                    <Field name="conditions" type="checkbox" />

                    <span>
                      I have read and accepted the{" "}
                      <a
                        className="privacy-policy"
                        href="https://www.termsfeed.com/live/201807ac-6ee4-4031-9890-7afb82581ae5"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </span>

                    <span className="termsMsg">
                      {touched.conditions && errors.conditions}
                    </span>
                  </div>

                  <div className="text-right mt-3">
                    <button type="submit" className="btn btn-medium btn-purple">
                      Next
                    </button>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>

        <div className="account-signUp">
          <p>Already have account?</p>
          <Link to="/login" className="signUp-link">
            Sign In
          </Link>
        </div>
      </div>

      {addRolePopup === "RolePopup" && (
        <>
          <div className="sidebar role-select-popup">
            <div className="popup-header">
              <AiOutlineUserAdd />
              <h5>Select Role Type</h5>
            </div>

            <div className="model-info">
              <div className="form-group w-100 mb-0">
                <label className="mb-3">Please Select Role Type</label>

                <div className="role-tab">
                  <div className="detail-field-row">
                    <div className="detail-info">
                      <span>
                        <label>
                          <input
                            type="radio"
                            value="recruiter"
                            id="recruiter"
                            name="Role"
                            checked={selectedRole === "recruiter"}
                            onChange={(e) => setSelectedRole(e.target.value)}
                            onClick={toggleRecruiterClick}
                          />
                          <label className="radio-text" htmlFor="recruiter">
                            Recruiter
                          </label>
                        </label>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="role-tab">
                  <div className="detail-field-row">
                    <div className="detail-info">
                      <span>
                        <label>
                          <input
                            type="radio"
                            value="sales"
                            id="sales"
                            name="Role"
                            checked={
                              selectedRole === "driver" ||
                              selectedRole === "sales" ||
                              selectedRole === "development"
                            }
                            onChange={(e) => setSelectedRole(e.target.value)}
                            onClick={toggleDriverClick}
                          />
                          <label className="radio-text" htmlFor="sales">
                            Applicant
                          </label>
                        </label>
                      </span>
                    </div>
                  </div>
                </div>

                {selectDriver && (
                  <select
                    value={selectedRole}
                    onChange={handleDriverChange}
                    className="d-flex p-3 mb-4 px-3 signup-selection w-100"
                    name=""
                    id="DriverSelection"
                  >
                    {DriverOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                )}

                <button
                  className="btn btn-purple"
                  onClick={() => {
                    setAddRolePopup("Close");
                    selectRoleApplicant();
                  }}
                >
                  Select <FiArrowRight />
                </button>
              </div>
            </div>
          </div>
          <div className="bg-overlay bg-fixed"></div>
        </>
      )}
    </>
  );
};

export default BasicInfo;
