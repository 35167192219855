import { useState } from "react";
import { Formik, Field } from "formik";
import { useActions } from "src/actions";
import logo from "src/assets/images/logo.png";
import toastBuilder from "src/utils/toastBuilder";
import { useParams, useNavigate } from "react-router-dom";
import { resPassFormValidation } from "src/validation/singnup";
import passwordIcon from "src/assets/images/icons/lock-icon.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const ResetPasswordComponent = () => {
  const navigation = useNavigate();
  const { token }: any = useParams();
  const { resetpassword } = useActions();
  const [showPassword, setShowPassword] = useState({
    passwordIcon: false,
    confirmIcon: false,
  });

  const handleFormSubmit = async (
    data: any,
    { resetForm, setSubmitting }: any
  ) => {
    const paylaod: any = {
      newPassword: data.newpassword,
      passwordConfirm: data.passwordConform,
      token: token,
    };
    const action: any = await resetpassword(paylaod);
    if (action?.payload?.status) {
      toastBuilder("success", "Password change successfully");
      navigation("/");
    }
    setSubmitting(false);
    resetForm();
  };

  return (
    <section className="signIn-section reset-password">
      <div className="logo">
        <img src={logo} alt="" />
      </div>

      <div className="shadow-box">
        <h2>Reset Your Password</h2>
        <p className="main-para">Enter your new password for new credentials</p>
        <Formik
          validationSchema={resPassFormValidation}
          onSubmit={handleFormSubmit}
          initialValues={{ newpassword: "", passwordConform: "" }}
          enableReinitialize={true}
        >
          {({ handleSubmit, touched, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>New Password</label>
                  <div className="form-field password-field">
                    <img src={passwordIcon} alt="" />
                    <Field
                      type={showPassword.passwordIcon ? "text" : "password"}
                      name="newpassword"
                      placeholder="Enter New Password"
                    />
                    {showPassword.passwordIcon ? (
                      <AiOutlineEyeInvisible
                        className="showHideIcon eyeIcon"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            passwordIcon: !showPassword.passwordIcon,
                          })
                        }
                      />
                    ) : (
                      <AiOutlineEye
                        className="showHideIcon eyeIcon"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            passwordIcon: !showPassword.passwordIcon,
                          })
                        }
                      />
                    )}
                  </div>
                  <span className="errorMsg">
                    {touched.newpassword && errors.newpassword}
                  </span>
                </div>

                <div className="form-group">
                  <label>Confirm Password</label>
                  <div className="form-field password-field">
                    <img src={passwordIcon} alt="" />
                    <Field
                      type={showPassword.confirmIcon ? "text" : "password"}
                      name="passwordConform"
                      placeholder="Confirm New Password"
                    />
                    {showPassword.confirmIcon ? (
                      <AiOutlineEyeInvisible
                        className="showHideIcon eyeIcon"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            confirmIcon: !showPassword.confirmIcon,
                          })
                        }
                      />
                    ) : (
                      <AiOutlineEye
                        className="showHideIcon eyeIcon"
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            confirmIcon: !showPassword.confirmIcon,
                          })
                        }
                      />
                    )}
                  </div>
                  <span className="errorMsg">
                    {touched.passwordConform && errors.passwordConform}
                  </span>
                </div>
                <button
                  type="submit"
                  className="btn w-100 btn-medium btn-purple"
                >
                  Reset
                </button>
              </form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};

export default ResetPasswordComponent;
