import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { store } from "../../store";
import moment from "moment";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import { State } from "country-state-city";
import userIcon from "src/assets/images/icons/user-icon.svg";
import phoneIcon from "src/assets/images/icons/phone-icon.svg";
import stateIcon from "src/assets/images/icons/flag-icon.svg";
import cityIcon from "src/assets/images/icons/city-icon.svg";
import pinIcon from "src/assets/images/icons/location-icon.svg";
import toastBuilder from "src/utils/toastBuilder";
import { BasicInfoForUpdate } from "src/validation/singnup";
import { selectUser } from "src/container/auth/auth.selector";
import { getFiltersData } from "src/container/recruiter/recruiter.slice";
import calendarIcon from "src/assets/images/icons/calendar-icon.svg";
import SharedData from "../SharedData/SharedApi";

const DriverInfoForm: React.FC<{}> = (props) => {
  const { getProfileData, updateUserData } = useActions();
  const currentUser = useAppSelector(selectUser);
  const selectCurrentuser = useAppSelector(selectUser);

  const startDates = moment(currentUser?.employment_history?.start_date).format(
    "YYYY-MM-DD"
  );
  const endDates = moment(currentUser?.employment_history?.end_date).format(
    "YYYY-MM-DD"
  );
  const [startDate, setStartDate] = useState(startDates);
  const [endDate, setEndDate] = useState(endDates);

  const States = State.getStatesOfCountry("US").map((curElem) => {
    return curElem.name;
  });
  useEffect(() => {
    getProfileData();
    store.dispatch(getFiltersData({ filters: null }));
  }, []);

  const handleSubmit = async (formValues: any, { setSubmitting }: any) => {
    const result: any = await updateUserData(formValues);
    if (result.payload.status) {
      getProfileData();
      setSubmitting(false);
      toastBuilder("success", "profile update successfully");
    }
  };
  useEffect(() => {
    setstate(selectCurrentuser);
  }, [selectCurrentuser]);


  const initalValues = {
    first_name: "",
    last_name: "",
    phone: "",
    city: "",
    zip_code: "",
    companyLogo: "",
    company_name: "",
    conditions: true,
    state: "Alabama",
    ideal_company: "",
    experience: "",
    endorsements: [],
    haul_type: "",
    twic_card: "",
    what_you_haul: "",
    accidents: "",
    employment_history: {
      company_name: "",
      city: "",
      dot: "",
      phone: "",
      state: "",
      zip: "",
      start_date: "",
      end_date: "",
    },
  };

  const [inistate, setstate] = useState(initalValues);

  return (
    <>
      <div className="input-fields-area contact-form ml-2">
        <Formik
          validationSchema={BasicInfoForUpdate}
          onSubmit={handleSubmit}
          initialValues={inistate}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            errors,
          }) => {
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <div className="fields-wrapper">
                    <div className="form-group">
                      <h4>Profile Info</h4>
                    </div>
                  </div>
                  <div className="fields-wrapper">
                    <div className="form-group">
                      <label>First Name</label>
                      <div className="form-field">
                        <img src={userIcon} alt="" />
                        <Field
                          type="text"
                          name="first_name"
                          placeholder="John"
                        />
                      </div>
                      <span className="errorMsg">
                        {touched.first_name && errors.first_name}
                      </span>
                    </div>
                    <div className="form-group">
                      <label>Last Name</label>
                      <div className="form-field">
                        <img src={userIcon} alt="" />
                        <Field type="text" name="last_name" placeholder="Doe" />
                      </div>
                      <span className="errorMsg">
                        {touched.last_name && errors.last_name}
                      </span>
                    </div>
                  </div>

                  <div className="fields-wrapper">
                    <div className="form-group">
                      <label>Zip Code</label>
                      <div className="form-field">
                        <img src={pinIcon} alt="" />
                        <Field
                          type="text"
                          name="zip_code"
                          placeholder="99753"
                        />
                      </div>
                      <span className="errorMsg">
                        {touched.zip_code && errors.zip_code}
                      </span>
                    </div>
                    <div className="form-group">
                      <label>Phone</label>
                      <div className="form-field">
                        <img src={phoneIcon} alt="" />
                        <Field
                          type="text"
                          name="phone"
                          placeholder="123 456 7890"
                        />
                      </div>
                      <span className="errorMsg">
                        {touched.phone && errors.phone}
                      </span>
                    </div>
                  </div>

                  <div className="fields-wrapper">
                    <div className="form-group">
                      <label>State</label>
                      <div className="form-field">
                        <img src={stateIcon} alt="" />
                        <select
                          onChange={handleChange}
                          value={values.state}
                          onBlur={handleBlur}
                          name="state"
                        >
                          {States.map((curElem, index) => {
                            return (
                              <option value={curElem} key={index}>
                                {curElem}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <span className="errorMsg">
                        {touched.state && errors.state}
                      </span>
                    </div>
                    <div className="form-group">
                      <label>City</label>
                      <div className="form-field">
                        <img src={cityIcon} alt="" />
                        <Field type="text" name="city" placeholder="New York" />
                      </div>
                      <span className="errorMsg">
                        {touched.city && errors.city}
                      </span>
                    </div>
                  </div>

                  <>
                    <div className="fields-wrapper mt-4">
                      <div className="form-group">
                        <h4>Employment History</h4>
                      </div>
                    </div>
                    <div className="fields-wrapper">
                      <div className="form-group">
                        <label>Company Name</label>
                        <div className="form-field">
                          <img src={cityIcon} alt="" />
                          <Field
                            type="text"
                            name="employment_history.company_name"
                            placeholder="Company Name"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>City</label>
                        <div className="form-field">
                          <img src={cityIcon} alt="" />
                          <Field
                            type="text"
                            placeholder="City"
                            name="employment_history.city"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="fields-wrapper">
                      <div className="form-group">
                        <label>State</label>
                        <div className="form-field">
                          <img src={stateIcon} alt="" />
                          <select
                            name="employment_history.state"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            {States.map((curElem, index) => {
                              return (
                                <option value={curElem} key={index}>
                                  {curElem}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Zip Code</label>
                        <div className="form-field">
                          <img src={pinIcon} alt="" />
                          <Field
                            type="text"
                            placeholder="Zip"
                            name="employment_history.zip"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="fields-wrapper">
                      <div className="form-group">
                        <label>Start Date</label>
                        <div className="form-field">
                          <img src={calendarIcon} alt="" />
                          <Field
                            type="date"
                            placeholder="Start Date"
                            name="start_date"
                            value={startDate}
                            onChange={(event: any) =>
                              setStartDate(event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>End Date</label>
                        <div className="form-field">
                          <img src={calendarIcon} alt="" />
                          <Field
                            type="date"
                            placeholder="End Date"
                            name="end_date"
                            value={endDate}
                            onChange={(event: any) =>
                              setEndDate(event.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="fields-wrapper">
                      <div className="form-group">
                        <label>Dot#</label>
                        <div className="form-field">
                          <img src={stateIcon} alt="" />
                          <Field
                            type="text"
                            placeholder="Dot#"
                            name="employment_history.dot"
                          />
                        </div>
                       
                      </div>
                      <div className="form-group">
                        <label>Phone</label>
                        <div className="form-field">
                          <img src={phoneIcon} alt="" />
                          <Field
                            type="text"
                            placeholder="Phone"
                            name="employment_history.phone"
                          />
                        </div>
                      
                      </div>
                    </div>

                    <div className="input-fields-area driver-detail mt-0">
                      <div className="fields-wrapper mt-4">
                        <div className="form-group">
                          <h4>Driver Detail</h4>
                        </div>
                      </div>
                      <div className="detail-field-row driver-profile">
                        <label>Ideal Company for you: </label>
                        <div className="detail-info">
                          <span>
                            <label>
                              <Field
                                type="radio"
                                name="ideal_company"
                                value="Carrier"
                                id="Carrier"
                              />
                              <label htmlFor="Carrier">Carrier</label>
                            </label>
                          </span>
                          <span>
                            <label>
                              <Field
                                type="radio"
                                name="ideal_company"
                                value="Private fleet"
                                id="Private"
                              />
                              <label htmlFor="Private">Private Fleet</label>
                            </label>
                          </span>
                          <span>
                            <label>
                              <Field
                                type="radio"
                                name="ideal_company"
                                value="Both"
                                id="Both"
                              />
                              <label htmlFor="Both">Both</label>
                            </label>
                          </span>
                          
                        </div>
                      </div>

                      <div className="detail-field-row driver-profile">
                        <label>Years Experience: </label>
                        <select
                          name="experience"
                          onChange={handleChange}
                          value={values.experience}
                          className="exp-dropdown"
                        >
                          <option value="">Select</option>
                          <option value="1-3 Year">1-3 years</option>
                          <option value="3-5 Year">2-5 years</option>
                          <option value="5-10 Year">5-10 years</option>
                          <option value="10+ Years">10+ years</option>
                        </select>
                        <span className="errorMsg">
                          {touched.experience && errors.experience}
                        </span>
                      </div>

                      <div className="detail-field-row driver-profile">
                        <label>Endorsements: </label>
                        <div className="detail-info">
                          {SharedData.Endorsements.map(
                            (endorsement: any, index: number) => {
                              return (
                                <span key={endorsement.value}>
                                  <div>
                                    <Field
                                      id={`endoserments[${index}]`}
                                      name="endorsements"
                                      value={endorsement}
                                      type="checkbox"
                                      className="checkbox-custom"
                                    />
                                    <label
                                      htmlFor={`endoserments[${index}]`}
                                      className="checkbox-custom-label"
                                    >
                                      {endorsement}
                                    </label>
                                  </div>
                                </span>
                              );
                            }
                          )}
                          <span className="errorMsg">
                            {touched.endorsements && errors.endorsements}
                          </span>
                        </div>
                      </div>

                      <div className="detail-field-row driver-profile">
                        <label>TWIC Card: </label>
                        <div className="detail-info">
                          {[
                            { label: "Yes", value: true },
                            { label: "No", value: false },
                          ].map((data) => {
                            return (
                              <span key={data.label}>
                                <label>
                                  <Field
                                    type="radio"
                                    value={data.value}
                                    onChange={(event: any) => {
                                      const value = event.target.value;
                                      if (value === "true")
                                        setFieldValue("twic_card", true);
                                      if (value === "false")
                                        setFieldValue("twic_card", false);
                                    }}
                                    name="twic_card"
                                    id={data.label}
                                  />
                                  <label htmlFor={data.label}>
                                    {data.label}
                                  </label>
                                </label>
                              </span>
                            );
                          })}
                          <span className="errorMsg">
                            {touched.twic_card && errors.twic_card}
                          </span>
                        </div>
                      </div>

                      <div className="detail-field-row driver-profile">
                        <label>Haul Type: </label>
                        <div className="detail-info">
                          {SharedData.Haul_Type.map((hualType) => {
                            return (
                              <span key={hualType.value}>
                                <label>
                                  <Field
                                    type="radio"
                                    value={hualType.value}
                                    name="haul_type"
                                    id={hualType.value}
                                  />
                                  <label htmlFor={hualType.value}>
                                    {hualType.label}
                                  </label>
                                </label>
                              </span>
                            );
                          })}
                          
                        </div>
                      </div>

                      <div className="detail-field-row driver-profile">
                        <label>What do you Haul?</label>
                        <div className="detail-info">
                          {SharedData.whatHult.map((hault) => {
                            return (
                              <span key={hault.value}>
                                <label>
                                  <Field
                                    type="radio"
                                    value={hault.value}
                                    name="what_you_haul"
                                    id={hault.value}
                                  />
                                  <label htmlFor={hault.value}>
                                    {hault.label}
                                  </label>
                                </label>
                              </span>
                            );
                          })}
                       
                        </div>
                      </div>

                      <div className="detail-field-row driver-profile">
                        <label>Accident in 3 Years?</label>
                        <div className="detail-info">
                          {SharedData.Accident.map((accident: any) => {
                            return (
                              <span key={accident.value}>
                                <label>
                                  <Field
                                    type="radio"
                                    value={accident.value}
                                    name="accidents"
                                    id={accident.value}
                                  />
                                  <label htmlFor={accident.value}>
                                    {accident.label}
                                  </label>
                                </label>
                              </span>
                            );
                          })}
                          <span className="errorMsg">
                            {touched.accidents && errors.accidents}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>

                  <div className="text-right mt-3">
                    <button type="submit" className="btn btn-medium btn-purple">
                      Update
                    </button>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default DriverInfoForm;
