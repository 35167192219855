import React from "react";
import { useAppSelector } from "src/store";
import { UseQueryParams } from "src/utils/GetParams";
import { Route, useLocation, Routes, Navigate } from "react-router-dom";
import UserSteps from "../container/auth/Signup";
import ProviderHome from "../container/ProviderHome";
import Profile from "../container/ProviderHome/ProfilePage";
import AccountSetting from "../components/AccountSettings";
import JobsPage from "src/container/ProviderHome/JobsPage";
import FeedPage from "src/container/ProviderHome/FeedPage";
import TribePage from "src/container/ProviderHome/TribePage";
import DriverPage from "../container/ProviderHome/DriverPage";
import { selectToken } from "src/container/auth/auth.selector";
import JobsDetail from "src/container/ProviderHome/JobsDetail";
import EarningsPage from "../container/ProviderHome/EarningsPage";
import BankDetails from "../components/AccountSettings/BankDetail";
import ViewRequestPage from "src/container/ProviderHome/ViewRequest";
import HiredDriversPage from "../container/ProviderHome/HiredDrivers";
import PaymentMethod from "../components/AccountSettings/PaymentMethod";
import HiredRecruitersPage from "src/container/ProviderHome/HiredRecruiters";
import ViewDriverDetailsPage from "src/container/ProviderHome/ViewDriverDetails";

const AuthenticatedRoutes: React.FC = () => {
  const location = useLocation();
  const token = useAppSelector(selectToken);
  const [tab] = UseQueryParams("tab");

  return (
    <Routes location={location}>
      <Route path="/user" element={<UserSteps />} />
      <Route path="/home" element={<ProviderHome />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/talent" element={<DriverPage />} />
      <Route path="/earnings" element={<EarningsPage />} />
      <Route path="/tribe" element={<TribePage />} />
      <Route path="/jobs" element={<JobsPage />} />
      <Route path="/feed" element={<FeedPage />} />
      <Route path="/my-account" element={<AccountSetting />} />
      <Route path="/payment-methods" element={<PaymentMethod />} />
      <Route path="/bank-details" element={<BankDetails />} />
      <Route path="/engaged-applicants" element={<HiredDriversPage />} />
      <Route path="/engaged-recruiters" element={<HiredRecruitersPage />} />
      <Route path="/view-request/:jobId" element={<ViewRequestPage />} />
      <Route path="/jobDetail/:job_id" element={<JobsDetail />} />
      <Route
        path="/viewUserDetails/:driverId/:jobId"
        element={<ViewDriverDetailsPage />}
      />
      <Route
        path="/viewUserDetails/:driverId"
        element={<ViewDriverDetailsPage />}
      />
      <Route
        path="*"
        element={
          token && tab !== "basic-info" ? (
            <Navigate to="/my-account" />
          ) : (
            <Navigate to="/user" />
          )
        }
      />
    </Routes>
  );
};

export default AuthenticatedRoutes;
