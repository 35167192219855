import React, { useEffect } from "react";
import { useActions } from "src/actions";
import Header from "src/components/Header";
import TribeChatCard from "src/components/Shared/TribeChatCard";
import TribeMemberCard from "src/components/Shared/TribeMemberCard";
import TribeMsgDetails from "src/components/UserPost/TribeMsgDetails";

const TribePage: React.FC<{}> = (props) => {
  const { getJoinedTribes } = useActions();

  useEffect(() => {
    getJoinedTribes();
  }, []);

  return (
    <>
      <Header />
      <section className="home-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-5 offset-md-0 col-10 offset-1">
              <TribeChatCard />
            </div>
            <div className="col-lg-6 col-md-7 col-12">
              <TribeMsgDetails />
            </div>
            <div className="col-lg-3 col-md-5 offset-md-0 col-10 offset-1">
              <TribeMemberCard />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TribePage;
