import { createSlice } from "@reduxjs/toolkit";

type Imodel = {
  active_model: string | Boolean;
};

const initialState: Imodel = {
  active_model: false,
};

const modelstate = createSlice({
  name: "modelstate",
  initialState,
  reducers: {
    set_active_model: (state, action) => {
      state.active_model = action.payload;
    },
  },
});

export const { set_active_model } = modelstate.actions;

export default modelstate.reducer;
