import React, { useEffect } from "react";
import { useActions } from "src/actions";
import { useAppSelector } from "src/store";
import HMessageIcon from "src/assets/images/icons/header-message-icon.svg";
import { headerEmails } from "src/container/notification/notification.selector";

type Props = {
  on_notification_icon_click: () => void;
  on_message_icon_click: () => void;
};

const HeaderBadge: React.FC<Props> = (props) => {
  const { getHeader } = useActions();
  const { on_message_icon_click } = props;
  const emailCount = useAppSelector(headerEmails);

  useEffect(() => {
    getHeader();
  }, []);

  return (
    <div>
      <ul className="list-unstyled badge-icons">
        <li onClick={on_message_icon_click}>
          <img src={HMessageIcon} alt="" style={{ width: "25px" }} />
          <span
            className={emailCount === 0 ? "msg-notify d-none" : "msg-notify"}
          >
            {emailCount}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default HeaderBadge;
