import React, { useState } from "react";
import Select from "react-select";
import { models } from "src/enums";
import { store } from "../../store";
import { useActions } from "src/actions";
import { State } from "country-state-city";
import { Formik, Field, Form } from "formik";
import toastBuilder from "src/utils/toastBuilder";
import { getFiltersData } from "src/container/recruiter/recruiter.slice";
import SharedData from "../SharedData/SharedApi";

const SalesSearchFilter: React.FC<{}> = (props) => {
  const { set_active_model, getAllDriverJobs } = useActions();
  const [selectStateUser, setStateUserSelect] = useState(false);

  const States = State.getStatesOfCountry("US").map((curElem) => {
    return curElem.name;
  });

  const initialValues = {
    job_location: "",
    largest_deal_won: "",
    usual_sale_cycles: "",
    role: [],
    state: [],
    sales_methodologies: [],
  };

  const toggleStateClick = () => {
    setStateUserSelect(true);
  };

  const handleSubmit = async (formValues: any, { setSubmitting }: any) => {
    formValues.sales_methodologies = formValues.sales_methodologies.flat();
    formValues.role = formValues.role.flat();
    formValues.usual_sale_cycles = [formValues.usual_sale_cycles];

    const objToReturn: any = {};
    for (const prop in formValues) {
      if (
        formValues[prop] !== undefined &&
        formValues[prop] !== null &&
        formValues[prop] !== ""
      ) {
        const isAnArray = Array.isArray(formValues[prop]);
        if (isAnArray) {
          const newArray = formValues[prop].filter((passedElement: unknown) => {
            return (
              passedElement !== null &&
              passedElement !== undefined &&
              passedElement !== ""
            );
          });
          formValues[prop] = newArray;
        }
        objToReturn[prop] = formValues[prop];
        if (typeof (formValues[prop] === "object")) {
          if (Object.keys(formValues[prop]).length === 0) {
            delete objToReturn[prop];
          }
        }
      }
    }
    store.dispatch(getFiltersData({ filters: objToReturn }));
    //@ts-ignore
    const results: any = await getAllDriverJobs({ filters: objToReturn });
    if (results.payload.status) {
      toastBuilder("success", "Filters Applied");
      setSubmitting(false);
      set_active_model(models.CLEAR);
    }
  };

  return (
    <>
      <div className="mega-menu active">
        <div className="input-fields-area driver-detail">
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
          >
            {({
              setFieldValue,
              handleSubmit,
              handleChange,
              handleBlur,
              values,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="detail-field-row">
                    <h4>Job Location: </h4>
                    <div className="detail-info">
                      <span className="p-0">
                        <label>
                          <Field
                            type="radio"
                            name="job_location"
                            value="Remote"
                            id="remote"
                            onClick={() => setStateUserSelect(false)}
                          />
                          <label htmlFor="remote">Remote</label>
                        </label>
                      </span>
                      <span>
                        <label>
                          <Field
                            type="radio"
                            name="job_location"
                            value="State/City"
                            id="state/city"
                            onClick={toggleStateClick}
                          />
                          <label htmlFor="state/city">State/City</label>
                        </label>
                      </span>
                      <span>
                        <label>
                          <Field
                            type="radio"
                            name="job_location"
                            value="Hybrid"
                            id="hybrid"
                            onClick={toggleStateClick}
                          />
                          <label htmlFor="hybrid">Hybrid</label>
                        </label>
                      </span>
                    </div>
                  </div>

                  <div className="detail-field-row">
                    <h4>Average Deal Size:</h4>
                    <select
                      className="sales-selection"
                      onChange={handleChange}
                      value={values.largest_deal_won}
                      onBlur={handleBlur}
                      name="largest_deal_won"
                    >
                      {SharedData.DealWon.map((curElem) => {
                        return (
                          <option
                            className="w-100 m-0"
                            key={curElem.value}
                            value={curElem.value}
                          >
                            {curElem.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="detail-field-row ">
                    <h4>Usual Sales Cycles:</h4>

                    <select
                      className="sales-selection"
                      onChange={handleChange}
                      value={values.usual_sale_cycles}
                      onBlur={handleBlur}
                      name="usual_sale_cycles"
                    >
                      {SharedData.UsualSales.map((curElem) => {
                        return (
                          <option key={curElem.value} value={curElem.value}>
                            {curElem.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="detail-field-row ">
                    <h4>Sales Methodology: </h4>
                    <div className="detail-info">
                      {SharedData.Sales_Methodologies.map(
                        (Sales_Methodology: any, index: number) => {
                          return (
                            <span
                              className="p-0 mr-3"
                              key={Sales_Methodology.value}
                            >
                              <div>
                                <Field
                                  id={`sales_methodologies[${index}]`}
                                  name={`sales_methodologies[${index}]`}
                                  value={Sales_Methodology.value}
                                  type="checkbox"
                                  className="checkbox-custom"
                                />
                                <label
                                  htmlFor={`sales_methodologies[${index}]`}
                                  className="checkbox-custom-label"
                                >
                                  {Sales_Methodology.label}
                                </label>
                              </div>
                            </span>
                          );
                        }
                      )}
                    </div>
                  </div>

                  <div className="detail-field-row ">
                    <h4>Role: </h4>
                    <div className="detail-info ">
                      {SharedData.Roles.map(
                        (Sales_Role: any, index: number) => {
                          return (
                            <span className="p-0 mr-3" key={Sales_Role.value}>
                              <div>
                                <Field
                                  id={`role[${index}]`}
                                  name={`role[${index}]`}
                                  value={Sales_Role.value}
                                  type="checkbox"
                                  className="checkbox-custom"
                                />
                                <label
                                  htmlFor={`role[${index}]`}
                                  className="checkbox-custom-label"
                                >
                                  {Sales_Role.label}
                                </label>
                              </div>
                            </span>
                          );
                        }
                      )}
                    </div>
                  </div>

                  {selectStateUser ? (
                    <>
                      <div className="detail-field-row">
                        <h4>States: </h4>
                        <div className="detail-info states">
                          <Select
                            isMulti
                            closeMenuOnSelect={false}
                            onChange={(value) =>
                              setFieldValue(
                                "state",
                                value.map((curElem) => {
                                  return curElem.value;
                                })
                              )
                            }
                            className="multiSelect-dropdown"
                            options={States.map((state: any) => ({
                              value: state,
                              label: state,
                            }))}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="text-center mt-md-4">
                    <button type="submit" className="btn btn-medium btn-purple">
                      Search Jobs
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>

        <div className="close-menu-btn">
          <i
            className="fa-solid fa-x"
            onClick={() => set_active_model(false)}
          ></i>
        </div>
      </div>
      <div className="bg-overlay-fixed"></div>
    </>
  );
};

export default SalesSearchFilter;
